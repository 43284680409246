<template>
  <div id="Lines">
    <MyHeaderPage :title="$t('lines.title')" />
    <br /><br />
    <div id="LinesList" v-if="isAccess">
      <div class="listLines">
        <div class="mdl-grid" v-if="tab_index === 1">
          <div class="mdl-cell mdl-cell--4-col">
            <TextSearchInput
              :disabled="!viewLoader"
              @search="searchByString"
              @reset="resetSearchByString"
            />
          </div>
          <div class="mdl-cell mdl-cell--2-col"></div>
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone"
            v-show="
              ctrlPerms(['incident_lista', 'change_request_list']) &&
              tab_index !== 2
            "
          >
            <div class="helpText alignRight">
              <p class="textXL">{{ $t("help.generic.btnLabel") }}</p>
            </div>
          </div>
          <HelpRequestBtn
            v-if="ctrlPerms(['incident_lista']) && tab_index !== 2"
            :component-name="'ActiveLinesGenericIncidentForm'"
            :asset-type="'lines'"
            :icon="'warning'"
            :label="$t('help.incident.formTitle')"
            :element-class="'mdl-cell mdl-cell--1-col mdl-cell--4-col-phone'"
          />
          <HelpRequestBtn
            v-if="ctrlPerms(['change_request_list']) && tab_index !== 2"
            :component-name="'ActiveLinesGenericChangeRequestForm'"
            :asset-type="'lines'"            
            :icon="'person'"
            :label="$t('help.changeRequest.formTitle')"
            :element-class="'mdl-cell mdl-cell--1-col mdl-cell--4-col-phone'"
          />
          <HelpRequestBtn
            v-if="ctrlPerms(['help_ticket_list']) && tab_index !== 2 && false"
            :url="'/solutions/generic-help/lines'"
            :icon="'settings'"
            :label="$t('help.techSupport.formTitle')"
            :element-class="'mdl-cell mdl-cell--1-col mdl-cell--4-col-phone'"
          />
        </div>
        <div class="mdl-grid" v-else>
          <div class="mdl-cell mdl-cell--4-col">
            <TextSearchInput
              :disabled="!viewLoader"
              @search="searchByString"
              @reset="resetSearchByString"
            />
          </div>
        </div>
        <TabContainer>
          <template #tabs>
            <Tab
              id="tabOne"
              :label="$t('tabs.listLabel')"
              @click="setTab(1)"
              :class="{ 'is-active': tab_index === 1 }"
            ></Tab>
            <Tab
              v-if="ctrlPerms(['incident_lista', 'change_request_list'])"
              id="tabTwo"
              :label="$t('tabs.ticketListLabel')"
              @click="setTab(2)"
              :class="{ 'is-active': tab_index === 2 }"
            ></Tab>
          </template>
          <template #content>
            <div
              class="mdl-tabs__panel"
              :class="{ 'is-active': tab_index === 1 }"
              v-show="tab_index === 1"
            >
              <div class="mdl-grid">
                <div class="mdl-cell mdl-cell--4-col">
                  <span v-if="items" class="textInfo"
                    >{{ $t("lines.linesCount") }}: {{ totalItems }}</span
                  >
                </div>
              </div>
              <List
                details-url="listLinesUrl"
                :list="filtered"
                :view-loader="viewLoader"
                :view-error="viewError"
                :total="totalItems"
                :total-page="total_page"
              />
            </div>
            <div
              class="mdl-tabs__panel"
              :class="{ 'is-active': tab_index === 2 }"
              v-show="tab_index === 2"
            >
              <ColocationTicketList
                :list="filtered"
                :view-loader="viewLoader"
                :view-error="viewError"
                :total-page="total_page"
                :total-count="total_count"
                :component-name-incident="'IncidentDetail'"
                :component-name-change-request="'ChangeRequestDetail'"
                :component-name-activation="'ActivationDetails'"
                @refresh="getTicketList"
                @change-page="onChangePage"
              />
            </div>
            <div class="mdl-grid" v-show="!viewLoader">
              <div class="mdl-cell mdl-cell--12-col" style="text-align: center">
                <MyLoading :viewLoader="viewLoader" type="" />
              </div>
            </div>
          </template>
        </TabContainer>
      </div>
    </div>
    <div v-else>
      <CNoSolutionsService
        :text="'noSolutionsServices.access'"
        :viewBtn="false"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

const isAccess = computed(() => {
  let colo = false;
  let user = useUserStore().user;
  if (user.isAccess) {
    colo = user.isAccess;
  }
  return colo;
});
</script>

<script>
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyLoading from "@/components/MyLoading.vue";
import List from "@/components/ActiveLines/List.vue";
import TextSearchInput from "@/components/Search/TextSearch/TextSearchInput.vue";
import HelpRequestBtn from "@/components/Tables/HelpRequestBtn";
import TabContainer from "@/components/Utils/TabContainer.vue";
import Tab from "@/components/Utils/Tab.vue";
import ColocationTicketList from "@/components/Colocation/TicketList.vue";
import "@/assets/scss/LinesList.scss";
import "@/assets/scss/Typography.scss";
import { filterDataByColumns } from "@/services/SearchService";
import CNoSolutionsService from "@/components/NoSolutionsService.vue";

export default {
  name: "LineState",
  components: {
    MyLoading,
    MyHeaderPage,
    HelpRequestBtn,
    TextSearchInput,
    List,
    TabContainer,
    Tab,
    // TODO: change the component to make it reusable
    ColocationTicketList,
    CNoSolutionsService,
  },
  data() {
    return {
      items: [],
      filtered: [],
      search_columns: ["cliente", "intestatario", "solution", "serialnumber"],
      tab_index: 1,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      viewLoader: true,
      viewError: false,
      totalItems: 0,
      page_size: 10,
      total_page: 1,
      total_count: 0,
      current_page: 0,
      search_by_string_key: "",
      isMobile: this.$IsMobile,
    };
  },
  methods: {
    searchByString(keyword) {
      this.search_by_string_key = keyword;

      this.filtered = filterDataByColumns(
        this.items,
        this.search_columns,
        this.search_by_string_key
      );

      // console.log(`${this.filtered.length} items found for ${keyword}`);

      // if no results are found in cache refresh data with search params
      if (!this.filtered.length) {
        this.filtered = [];
      }

      this.total_count = this.items.length;
    },
    resetSearchByString() {
      this.search_by_string_key = "";
      if (this.tab_index === 1) {
        this.getLines();
      } else {
        this.getTicketList();
      }
    },
    setTab(index) {
      this.totalItems = 0;
      this.tab_index = index;
      this.search_by_string_key = "";
      if (index === 1) {
        this.search_columns = [
          "cliente",
          "intestatario",
          "solution",
          "serialnumber",
        ];
        this.getLines();
      } else {
        this.search_columns = ["subject", "cf_sold_solution"];
        this.getTicketList();
      }
    },
    getLines() {
      this.viewLoader = false;
      this.viewError = false;
      this.total_count = 0;
      const self = this;

      this.axios
        .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "assets/v1/lines", {
          headers: this.he,
        })
        .then((response) => {
          this.items = response.data;
          this.filtered = response.data;
          this.totalItems = response.data.length;
          this.viewLoader = true;
        })
        .catch(function (err) {
          if (err.request.status === 404) {
            console.log("NOT FOUND");
            self.viewLoader = true;
            self.items = [];
            self.filtered = [];
            return;
          }
          self.viewLoader = true;
          self.viewError = true;
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
    getTicketList() {
      const URL = "tickets/v1/ticket-list";
      const TICKET_TYPE = "Incident,ChangeRequest,TechAssist,TLCActivation";

      let params = {
        page_size: this.page_size,
        service: TICKET_TYPE,
        page: this.current_page,
        asset_type: "lines",
      };

      this.viewLoader = false;
      this.total_count = 0;
      const self = this;

      if (this.search_by_string_key.length > 3) {
        params = {
          ...params,
          search_by: this.search_by_string_key,
          filter_by: this.columns,
        };
      }

      this.axios
        .post(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + URL, params, {
          headers: this.he,
        })
        .then((response) => {
          self.items = response.data.data;
          self.filtered = response.data.data;
          self.total_count = response.data.count;
          self.viewLoader = true;
        })
        .catch(function (err) {
          //TODO: FIX THIS IN BE
          // manage 404 code if no tickets are found
          if (err.response.status === 404) {
            console.log("NOT FOUND");
            self.viewLoader = true;
            self.items = [];
            self.filtered = [];
            return;
          }
          self.viewLoader = true;
          self.viewError = true;
          self.messageError = err.message;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
    // pagination method
    onChangePage(page) {
      this.current_page = page;
      if (this.tab_index === 2) {
        this.getTicketList();
      }
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$IsLoggedIn();
    this.getLines();
  },
};
</script>
