<template>
  <div id="Shipping">
    <div class="thred">
      <MyHeaderPage
        :title="'Ticket ' + ticket.ticketNumber"
        :subtitle="$t('remoteHands.subTitleDetails')"
      />
      <TicketsBackLink />
      <div class="contentFormTwoCol">
        <DetailBox
          :ticket="ticket"
          :type="shipping"
          :theme="['boxShippingDetails', { contentPrimaryMobile: IsMobile }]"
        >
          <!-- <template #info>
            <div class="mdl-cell mdl-cell--2-col">
              <img src="@/assets/img/icon_info.png" alt="info" width="45" />
            </div>
            <div class="mdl-cell mdl-cell--10-col">
              <div>
                <span class="label">{{ $t("shipping.moreInfoInput") }}</span
                ><br /><span class="text" style="word-break: unset">{{
                  shippingData?.cf_altre_info
                    ? shippingData.cf_altre_info
                    : "n.a."
                }}</span>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--12-col">
              <hr />
            </div> </template
        > -->
      </DetailBox>
        <div class="contentSecondary noPadding minHeightThreads">
          <Threads
            :headers="headerThread"
            :ticket="ticket"
            :threads="threads"
            :attachs="attachs"
            @update="getThreads"
          />
        </div>
      </div>
    </div>

    <br /><br />
    <MyLoading :viewLoader="viewLoader" type="full" />
  </div>
</template>

<script>
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import Threads from "@/components/Threads.vue";
import TicketsBackLink from "@/components/Tickets/BackLink.vue";
import "@/assets/scss/RemoteHands.scss";
import MyLoading from "@/components/MyLoading.vue";
import DetailBox from "@/components/Tickets/Detail/DetailBox.vue";

export default {
  name: "RemoteHandsDetails",
  components: {
    MyHeaderPage,
    MyLoading,
    Threads,
    TicketsBackLink,
    DetailBox,
  },
  data() {
    return {
      ticket: {},
      threads: {},
      attachs: {},
      headerThread: {
        createdTime: "###",
        modifiedTime: "###",
      },
      viewLoader: false,
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      IsMobile: this.$IsMobile()
    };
  },
  methods: {
    getThreads() {
      this.viewLoader = false;
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/ticket-threads-by-ticket-number/?ticket_number=" +
            this.$route.params.id,
          { headers: this.he }
        )
        .then((response) => {
          this.threads = response.data;
          if (response.data.threadAttachments) {
            this.attachs = response.data.threadAttachments;
          }
          this.viewLoader = true;
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.ticket.ticket_id = this.$route.params.id;

    let getTicket = new Promise((resolve) => {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/ticket/?ticket_id=" +
            this.$route.params.id +
            "&ticket_type=Shipping",
          { headers: this.he }
        )
        .then((response) => {
        console.log(response);
          this.ticket = response.data.res;
          for (const [key, value] of Object.entries(this.ticket)) {
            if (Object.prototype.hasOwnProperty.call(this.headerThread, key)) {
              this.headerThread[key] = value;
            }
          }
          resolve(true);
        });
    });

    Promise.all([getTicket]).then((values) => {
      if (values[0]) {
        this.getThreads();
      }
    });
  },
};
</script>
