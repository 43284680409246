<template>
  <div id="PageTable">
    <MyBanner text="" />
    <MyHeaderPage :title="$t('orders.title')" subtitle="" />
    <br />
    <div class="contentPage" id="NetWorkCoverageFilter">
      <div class="mdl-grid" style="align-items: baseline">
        <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone">
          <div class="cdl-input">
            <input type="date" class="not-empty" v-model="dateFrom" style="background-color: white;" />
            <label>{{ $t("filter.from") }}</label>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone">
          <div class="cdl-input">
            <input type="date" class="not-empty" v-model="dateTo" style="background-color: white;" />
            <label>{{ $t("filter.to") }}</label>
          </div>
        </div>

        <div class="mdl-cell mdl-cell--2-col mdl-cell--12-col-phone">
            <div class="contentInputFilter" style="margin-bottom: 0;">
                <div class="multiselectFilter">{{ $t('detailOrder.state') }} <strong><span>
                    {{ (multiSelected.status.string.length > 10) ? multiSelected.status.string.slice(0, 11) + '&hellip;' : multiSelected.status.string }}</span></strong></div>
                <div class="contentIcon cursorPointer" @click="openMultiselect('status')">
                    <span class="material-icons" v-if="multiSelected.status.onOff">expand_less</span>
                    <span class="material-icons closeMultiselectFilter"
                        v-if="!multiSelected.status.onOff">expand_more</span>
                </div>
            </div>
            <div class="listMultiselect" v-if="multiSelected.status.onOff">
                <div><input type="checkbox" class="statusAll" value="-1"
                        @click="searchByParams('status', 'Tutto', $event)" />
                    {{ $t('checkCoverage.filter.all') }}</div>
                <div v-for="tech, key in this.multiSelected.status.defaults" :key="key"><input type="checkbox" class="status"
                        :value="tech" @click="searchByParams('status', tech, $event)" /> {{ $t('detailOrder.states.' + tech) }}</div>
            </div>
        </div>


        <div
          :class="IsMobile ? 'mdl-cell mdl-cell--12-col' : 'mdl-cell mdl-cell--2-col mdl-cell--2-offset'"
        >
          <div class="cdlButtonGreen" style="width: auto" @click="setFilter()">
            <a style="color: white">
              {{ $t("filter.filter") }}
            </a>
          </div>
        </div>
      </div>

      <div id="InvoiceList" class="tableList">
        <table>
          <thead v-if="!IsMobile">
            <tr>
              <th>{{ $t("orders.submission_date") }}</th>
              <th>{{ $t("orders.category") }}</th>
              <th>{{ $t("orders.kit_title") }}</th>
              <th>{{ $t("orders.order_number") }}</th>
              <th>{{ $t("orders.state") }}</th>
              <th colspan="2"></th>
            </tr>
          </thead>
          <tbody v-if="!IsMobile">
            <tr v-if="!viewLoader">
              <td colspan="7">
                <MyLoading :viewLoader="viewLoader" />
              </td>
            </tr>
            <tr>
              <td colspan="7" style="text-align: center" v-if="orders.length == 0 && viewLoader">
                <span>{{ $t("orders.noOrders") }}</span>
              </td>
            </tr>
            <tr v-for="(order, index) in orders" :key="index" :class="{ odd: index % 2 }">
              <td>{{ getDate(order.submission_date) }}</td>
              <td>
                {{ order.category }} 
                <small
                    v-if="order.category === 'XCONNECT'"
                    v-show="
                      order.category_specific_fields.is_redundant &&
                      order.category_specific_fields.master_id
                    "
                    style="
                      text-transform: uppercase;
                      background-color: bisque;
                      padding: 5px;
                      border-radius: 5px;
                    "
                    >{{ $t("crossConnects.ridondanza") }}</small
                  >
              </td>
              <td>{{ order.kit_title }}</td>
              <td>{{ order.order_number }}</td>
              <td>{{ $t('detailOrder.states.' + order.state) }}</td>
              <td>
                <div class="cdlButton" @click="getOrderDetail(order.id)">
                  {{ $t("orders.details") }}
              </div>
              </td>
              <td>
                <div class="contentSvg noDownload" v-tooltip:top.tooltip="order.state != 'EVASO' ? $t('orders.noPdf') : ''" v-if="order.state != 'EVASO'">
                  <DownloadPdf />              
                </div>
                <div class="contentSvg" @click="downloadPdf(order.id)" :class="'icon-loader-' + order.id" v-if="order.state == 'EVASO' && order.pdf_downloadable === true">
                  <DownloadPdf />          
                </div>    
                <div class="contentLoader" :class="'loader-' + order.id" style="display: none">
                  <MyLoading :viewLoader="false" :checkOrders="true" />
                </div>
                <div class="contentSvg noDownload" v-tooltip:top.tooltip="$t('orders.noAvaiblePdf')" v-if="order.state == 'EVASO' && order.pdf_downloadable === false">
                  <DownloadPdf /> 
                  <br /><br />               
                </div>  
              </td>
            </tr>
          </tbody>
          <tbody v-if="IsMobile">
            <tr v-if="!viewLoader">
              <td colspan="7">
                <MyLoading :viewLoader="viewLoader" />
              </td>
            </tr>
            <MobileCard
              class="philange"
              v-for="(order, index) in orders" :key="index"
              :item="order"
              translation-path="orders"
              type="orders"
              :lang="this.$i18n.locale"
              :titles="['submission_date', 'category', 'kit_title', 'order_number', 'state']"
            >
              <template #url-label>
                <div class="contentSvg noDownload" v-tooltip:top.tooltip="order.state != 'EVASO' ? $t('orders.noPdf') : ''" v-if="order.state != 'EVASO'">
                  <DownloadPdf /> 
                  <br /><br />               
                </div>
                <div class="contentSvg" @click="downloadPdf(order.id)" :class="'icon-loader-' + order.id" v-if="order.state == 'EVASO' && order.pdf_downloadable === true">
                  <DownloadPdf /> 
                  <br /><br />            
                </div>    
                <div class="contentLoader" :class="'loader-' + order.id" style="display: none">
                  <MyLoading :viewLoader="false" :checkOrders="true" />
                </div> 
                <div class="contentSvg noDownload" v-tooltip:top.tooltip="$t('orders.noAvaiblePdf')" v-if="order.state == 'EVASO' && order.pdf_downloadable === false">
                  <DownloadPdf /> 
                  <br /><br />               
                </div>
                <div class="cdlButton" @click="getOrderDetail(order.id)">
                  {{ $t("orders.details") }}
                </div></template
              >
            </MobileCard>
          </tbody>
        </table>
      </div>
      <div v-if="generalError != ''">
          <p class="error" style="text-align: center" v-html="generalError"></p>
        </div>
    </div>
  </div>
</template>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import moment from "moment";
// import { useFilterStore } from "@/stores/FilterStore";
import DownloadPdf from "@/components/svg/DownloadPdf.vue";
import MobileCard from "@/components/Cards/MobileCard.vue";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "OrdersPage",
  components: {
    MyBanner,
    MyHeaderPage,
    DownloadPdf,
    MobileCard,
    MyLoading
  },
  data() {
    return {
      IsMobile: this.$IsMobile(),
      dateFromFilter: moment().subtract(6, "months").format("YYYY-MM-DD") + "T00:00",
      dateToFilter: moment().format("YYYY-MM-DD") + "T23:59",
      dateFrom: moment().subtract(6, "months").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
      orders: [],
      viewLoader: false,
      viewError: false,
      generalError: "",
      multiSelected: {
                status: {
                    onOff: false,
                    string: this.$t('tickets.all'),
                    values: [],
                    defaults: []
                }
            },
    };
  },
  methods: {
    setFilter() {
      this.dateFromFilter = this.dateFrom + "T00:00";
      this.dateToFilter = this.dateTo + "T23:59";
      this.multiSelected.status.onOff = false;
      this.orders = [];
      this.getOrders();
    },
    getOrders() {

      let status = "-";
      if( this.multiSelected.status.values.length > 0 ) {
        status = this.multiSelected.status.values.toString();
      }

      this.viewLoader = false;
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      const self = this;

      this.axios
        .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "sales/v1/order?from=" + this.dateFromFilter + "&to=" + this.dateToFilter + "&status=" + status, {
          headers: he,
        })
        .then((response) => {
          this.orders = response.data;
          this.buildFilter();
          this.viewLoader = true;
        })
        .catch(function (err) {
          console.log(err)
          if (err.response?.data?.message == "invalid token") {
            return self.$router.push({ name: "MyLogin" });
          }
          self.viewLoader = true;
          self.generalError = err.response
            ? err.response.data.message
            : err.message;

        });
    },
    getDate(date) {
      return moment(date).format("DD/MM/YYYY", this.$i18n.locale, true);
    },
    getOrderDetail(orderId) {
      return this.$router.push({
        name: 'OrderDetails' + this.$i18n.locale,
        params: {
          order_id: orderId
        },
      });
    },
    downloadPdf(order_id) {
      document.getElementsByClassName('icon-loader-' + order_id)[0].style.display = "none";
      document.getElementsByClassName('loader-' + order_id)[0].style.display = "block";

      const self = this;

      this.axios
        .get(
          `${this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1)}sales/v1/pdf/${
            order_id
          }`,
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.$keycloak.idToken,
            }
          }
        )
        .then((rls) => {
          this.viewLoader = true;
          var a = document.createElement("a"); //Create <a>
          a.href = "data:application/pdf;base64," + rls.data.content; //Image Base64 Goes here
          a.download = rls.data.file_name; //File name Here
          a.click(); //Downloaded file
          document.getElementsByClassName('icon-loader-' + order_id)[0].style.display = "block";
          document.getElementsByClassName('loader-' + order_id)[0].style.display = "none";
        })
        .catch((err) => {
          document.getElementsByClassName('icon-loader-' + order_id)[0].style.display = "block";
          document.getElementsByClassName('loader-' + order_id)[0].style.display = "none";
          self.viewLoader = true;
          console.error(err);
        });

    },
    openMultiselect(type) {
        let ctrl = false;
        let selected = [];
        let pointer = this.multiSelected.status;

        (function (x) {
            x.onOff = !x.onOff;
            ctrl = x.onOff;
            selected = x.values;
        })(pointer);

        if (ctrl && selected.length > 0) {
            let checkBox = document.getElementsByClassName(type);
            setTimeout(() => {
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].checked = false;
                    selected.forEach(elm => {
                        if (checkBox[i].value == elm) {
                            checkBox[i].checked = true;
                        }
                    });
                }
            }, 100);
        }
        else if (ctrl && selected.length == 0) {
            let classAll = type + "All";
            setTimeout(() => {
                if( document.getElementsByClassName(classAll)[0] ) {
                    document.getElementsByClassName(classAll)[0].checked = true;
                }
            }, 100);
        }
    },   
    getLangFilter() {
        if( this.multiSelected.status.string == "Tutto" || this.multiSelected.status.string == "All" ) {
            this.multiSelected.status.string = this.$t('tickets.all');
        }
    },
    buildFilter() {
      this.orders.forEach(elm => {
        this.multiSelected.status.defaults.push(elm.state);
        this.multiSelected.status.defaults = this.multiSelected.status.defaults.filter((item, index) => this.multiSelected.status.defaults.indexOf(item) === index);
      });
    },
    searchByParams(type, params, ev) {

      this.searchString = "";

      let pointer = this.multiSelected.status;

      (function (x, type, params, ev, labelAll) {
          if (params == "Tutto") {
              x.values = [];
              x.string = labelAll;
              let checkBox = document.getElementsByClassName(type);
              for (let i = 0; i < checkBox.length; i++) {
                  checkBox[i].checked = false;
              }
          }
          else {
              let classAll = type + "All";
              document.getElementsByClassName(classAll)[0].checked = false;
              if (ev.target.checked) {
                  x.values.push(params);
              }
              else {
                  let index = x.values.indexOf(params);
                  if (index !== -1) {
                      x.values.splice(index, 1);
                  }
              }
              x.string = x.values.toString();
          }
      })(pointer, type, params, ev, this.$t('tickets.all'));

      if (this.multiSelected.status.values.length == 0) {
          this.multiSelected.status.string = this.$t('tickets.all');
          let checkBox = document.getElementsByClassName('status');
          for (let i = 0; i < checkBox.length; i++) {
              checkBox[i].checked = false;
          }
          if (type == 'status') {
              document.getElementsByClassName('statusAll')[0].checked = true;
          }
      }

    },

  },
  mounted() {
    window.scrollTo(0, 0);
    this.getOrders();
    this.getLangFilter();
  },
};
</script>
