export default {
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvenuto nella Vue.js App"])}
  },
  "keycloak": {
    "buttonLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi"])},
    "buttonLogout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "nav": {
    "homePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla home"])},
    "checkCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica la copertura"])},
    "checkCoverageUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verifica-copertura"])},
    "crossConnectNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Soluzione Cross Connect"])},
    "labelAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
    "settingUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione utenti"])},
    "usersUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestione-utenti"])},
    "companiesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestione-clienti-finali"])},
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Clienti Finali"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni"])},
    "servicesOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica Servizi"])},
    "networkConnectivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connettività"])},
    "accessLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessi"])},
    "remoteHandsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remote-hands"])},
    "listRemoteHands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista remote hands"])},
    "listRemoteHandsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista-remote-hands"])},
    "remoteHandsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
    "managementOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panoramica Management"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "listShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista shipping aperti"])},
    "shippingNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
    "listShippingUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista-shipping"])},
    "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dettaglio"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatture"])},
    "invoiceUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fatture"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni richieste"])},
    "ordersUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soluzioni-richieste"])},
    "accessDcUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ingresso"])},
    "colocationDetailsUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["colocation/", _interpolate(_named("item_id"))])},
    "incidentUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["incident/", _interpolate(_named("item_id")), "/", _interpolate(_named("asset_type"))])},
    "changeRequestUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["change-request/", _interpolate(_named("item_id")), "/", _interpolate(_named("asset_type"))])},
    "techSupportUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["help/", _interpolate(_named("item_id")), "/", _interpolate(_named("asset_type"))])},
    "accessDcList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista ingressi"])},
    "accessDcListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista-richieste-ingresso"])},
    "accessDcHistorical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico ingressi in DC"])},
    "accessDcHistoricalUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["storico-ingressi-dc"])},
    "listLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linee Attive"])},
    "listLinesUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["linee-attive"])},
    "linesIncidentUrl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["incident/", _interpolate(_named("item_id")), "/lines"])},
    "incidentList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Incident"])},
    "incidentListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista-incident"])},
    "crList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Change Request"])},
    "crListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista-change-request"])},
    "techSupportList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Richieste assistenza"])},
    "techSupportListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista-richieste"])},
    "accessDc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingressi"])},
    "accessDcNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza"])},
    "virtualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualizzazione"])},
    "virtualizationUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["virtualizzazione"])},
    "requestSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenze richieste"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report soluzioni"])},
    "maintenanceNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche manutenzioni"])},
    "maintenanceNotificationsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notifiche-manutenzioni"])},
    "historicalUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["storico"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico dei tickets"])},
    "currentUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corrente"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumi energetici"])},
    "paidInterventionsUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interventi-a-pagamento"])},
    "paidInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventi a Pagamento"])},
    "crossConnectsActiveUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cross-connects-attive"])},
    "crossConnectsActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects attive"])},
    "reportCloudUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cloud-report"])},
    "reportCloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "crossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connect"])},
    "crossConnectUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cross-connects"])},
    "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
    "colocationUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["colocation"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "accessDcAddressBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rubrica"])},
    "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "crossConnectsActiveNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
    "partner_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Partner"])},
    "reportIncidentListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report/lista-incident"])},
    "reportCrListUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["report/lista-change-request"])}
  },
  "breadcrumbs": {
    "cross-connects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cross-connect"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi"])},
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni"])},
    "crossConnectNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta Cross Connect"])},
    "crossConnectList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connect attive"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza"])},
    "networkConnectivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connettività"])},
    "accessLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessi"])},
    "checkCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica la copertura"])},
    "newOrderLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo ordine linea"])},
    "summaryOrderLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo soluzione accesso"])},
    "summaryOrderCrossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo soluzione Cross Connect"])},
    "summaryOrderXC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo soluzione"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrello"])},
    "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "remote_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
    "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
    "colocation_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio"])},
    "rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
    "new_remote_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Remote Hand"])},
    "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
    "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Clienti Finali"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "shippingDeliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricezione merci"])},
    "shippingPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna merci"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione utenti"])},
    "shippingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di Shipping"])},
    "shippingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping aperti"])},
    "RemoteHandsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands aperte"])},
    "detail": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket ", _interpolate(_named("ticket_id"))])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatture"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni richieste"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingressi"])},
    "entry_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieste"])},
    "entry_request_historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico ingressi in DC"])},
    "lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linee attive"])},
    "linesDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio"])},
    "incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
    "changeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
    "techSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza Tecnica"])},
    "Richieste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta"])},
    "crossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects"])},
    "Change Request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
    "requestSupport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenze richieste"])},
    "reporting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report soluzioni"])},
    "virtualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualizzazione"])},
    "maintenanceNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche di manutenzioni"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico dei tickets"])},
    "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumi energetici"])},
    "paidInterventions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventi a Pagamento"])},
    "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "address_book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrica"])},
    "incidentList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Incident"])},
    "changeRequestList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Change Request"])},
    "activation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione"])}
  },
  "banner": {
    "homePage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ciao ", _interpolate(_named("name")), ".<br />ti diamo il benvenuto nel Customer Portal di CDLAN.<br />Qui puoi tenere sotto controllo le tue soluzioni attive, accedere ai servizi, effettuare nuovi ordini e richiedere assistenza."])},
    "checkCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri quale soluzione di connettività puoi attivare"])},
    "cartPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risparmia tempo: puoi inserire più soluzioni nello stesso ordine cliccando su Nuova Richiesta. Ricordati che ogni soluzione rimarrà nel carrello 48 ore, dopodiché sarà automaticamente cancellata. Assicurati di confermare il tuo ordine in tempo."])},
    "RemoteHandsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione puoi visualizzare le richieste inoltrate oppure richiedere nuovi interventi di Remote hands “on site” condotti da personale CDLAN. Tali interventi sono suddivisi in Basic e Advanced in base alla tipologia di attività richiesta."])},
    "shippingPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai necessità di stoccare i tuoi apparati in attesa di installazione o ritiro? Richiedi il nostro servizio di ricevimento e consegna merci."])},
    "usersPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione puoi aggiungere, gestire e modificare gli utenti con facilità, assegnando loro uno dei ruoli a disposizione o creandone uno su misura per una gestione ottimale del portale."])},
    "customersEndsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione puoi gestire i tuoi clienti finali: aggiungere, modificare oppure eliminare le società clienti e associare ad esse le persone di riferimento."])},
    "accessDcPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "accessDcInitTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione puoi prenotare l’ingresso a uno dei due nostri data center. Scegli la data e l'orario desiderati per garantire un ingresso rapido e sicuro."])},
    "crossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi l’attivazione di una Cross Connect. Scegli tra le due opzioni disponibili: Local (punto-a-punto all’interno di C21 o E100) e Infracampus (da C21 a MIX, Irideos, Seeweb o muffola)."])},
    "invoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa sezione puoi consultare le tue fatture e note di credito filtrandole per periodo di interesse"])},
    "accessDcHistoricalPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "accessDcAddressBookPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "crossConnects": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ridondanza attiva"])},
    "InfoReqLOA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni e Requisiti LOA di seguito"])},
    "loaUploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nel caricamento del file"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SI"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["qui"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supporto"])},
    "updateItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna fornitura"])},
    "supportTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket di supporto"])},
    "redundancyActivateQs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi attivare la ridondanza?"])},
    "otherCostsActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saranno addebitati costi aggiuntivi."])},
    "backToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla lista"])},
    "aendbend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A - End / B - end"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento ordine"])},
    "OpticCdlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cassetto Ottico CDLAN. Riceverai la LOA da fornire."])},
    "noItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento non sono ordinabili  soluzioni di questa tipologia"])},
    "requiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["campi obbligatori"])},
    "uploadLOA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega LOA (Letter Of Authorization)"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connect"])},
    "uploadLOANoExp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega LOA"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xconnect Informazioni introduttive e modalità di richiesta"])},
    "cardOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOCAL"])},
    "formSubtitleCorrispettives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio prezzi"])},
    "deliveriesSubTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila il form sottostante con tutti i dettagli richiesti"])},
    "cardTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFRACAMPUS"])},
    "subOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Cross Connect LOCAL <b>sono realizzate entro 1 giorno lavorativo dall'invio dell'ordine</b> e possono essere richieste in modalità ridondata.\n<br/>Tutte le Cross Connect in C21 sono “bifibra monomodale”. \n<br/>Per ordinare una Cross Connect LOCAL che si trova nella disponibilità di un'altra società è necessario <strong>allegare la LOA in formato PDF.</strong>"])},
    "subTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Cross Connect INFRACAMPUS verso MIX, Irideos e Seeweb sono <strong>realizzate entro 7 giorni lavorativi dall'ordine</strong> e possono essere richieste in modalità ridondata.\n<br/>Tutte le Cross Connect in E100 sono “bifibra multimodale”.\n<br/>Per ordinare una Cross Connect INFRACAMPUS che si trova nella disponibilità di un'altra società è necessario allegare la <strong> allegare la LOA in formato PDF.</strong>"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi"])},
    "dataCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "locationLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il Data Center in cui vuoi richiedere l'attivazione della CROSS CONNECT LOCAL"])},
    "locationInfra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il Data Center in cui vuoi richiedere l'attivazione della CROSS CONNECT INFRACAMPUS"])},
    "mandatoryFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*campi obbligatori"])},
    "ridondanza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ridondanza"])},
    "compileForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila il form sottostante con tutti i dettagli richiesti"])},
    "redundancyNotEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stata aggiunta ridondanza per questa Cross Connect"])},
    "redundancyPossibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per questa Cross Connect è possibile richiedere la ridondanza con differenziazione di percorso, in modalità Full redundancy."])},
    "isRedundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La soluzione che stai modificando è la ridondanza di una Cross Connect principale"])},
    "isRedundancyContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per modificare i contatti del referente è necessario visitare la pagina della linea principale. Puoi farlo da "])},
    "isRedundancyMainLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per modificare i dati della ridondanza è necessario visitare la pagina della linea principale. Puoi farlo da "])},
    "isRedundancyMainLineReadOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per vedere i dati della linea di ridondanza."])},
    "isMainLineReadOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per visualizzare i dati della principale è necessario visitare la pagina della linea principale. Puoi farlo da "])},
    "isRedundancyMainLineReadOnlyPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per le informazioni sulla soluzione richiesta per la ridondanza, consultare i dettagli della stessa"])},
    "Click": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca"])},
    "redundancyActivationCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispettivi"])},
    "stepHalf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "step1Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center e Kit"])},
    "step2Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linea Principale"])},
    "step3Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ridondanza"])},
    "step4Heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info Aggiuntive"])},
    "threemester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3"])},
    "sixmonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei mesi"])},
    "12months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 mesi"])},
    "anticipatedThrimester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestrale"])},
    "anticipated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anticipata"])},
    "purchaseOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento"])},
    "rackOfYourOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il rack è sotto il diretto utilizzo della tua società?"])},
    "muffolaOfYourOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il cassetto nella muffola è sotto il diretto utilizzo della tua società?"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "step4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "confirmValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convalida soluzione"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "activeCrossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connect In Attivazione:"])},
    "localC1C2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coppia di fibre ottiche"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via Caldera, 21, 20153, Milano, Italia"])},
    "activationPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione"])},
    "monthlyPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone Mensile"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sala"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
    "patchPannelMMR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta verso MMR"])},
    "isle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isola"])},
    "notesAndTechDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note e Dettagli Tecnici"])},
    "infoLOAReq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I requisiti e le informazioni relative alla LOA sono riportate di seguito"])},
    "infoLOAFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni file LOA:"])},
    "authLetter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" La lettera di autorizzazione è un documento che include informazioni su dove e quando un circuito deve essere terminato o riassegnato ad un altro operatore di telecomunicazioni. Nel documento è inclusa l’indicazione e la collocazione dell’Apparato nel B-END, con il dettaglio della Porta in cui la connessione deve essere terminata.\n                Sei tenuto ad ottenere la LOA se ordini una Cross Connect che si collega ad un Apparato posizionato nel B-END e che si trova sotto la gestione di un'altra società."])},
    "reqLOAFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisiti file LOA:"])},
    "LOAExplenation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Lettera di Autorizzazione (LOA) è un documento necessario per procedere con la richiesta di attivazione di una cross connect. La LOA è un chiaro consenso da parte del cliente che autorizza il Provider di servizi a effettuare la connessione richiesta."])},
    "reqLOAFiles2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Che cosa è la LOA (Letter of Authorization)?"])},
    "wrongLoaFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La LOA deve essere in formato PDF"])},
    "30daysValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dal momento che viene rilasciata, la LOA ha validità di 30 giorni."])},
    "reqLOAtext1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La LOA deve essere prodotta su carta intestata oppure riportare  nome, cognome e firma della persona fisica che sottoscrive il timbro della società che lo rilascia."])},
    "reqLOAtext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deve contenere obbligatoriamente:"])},
    "reqLOAtext3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dal momento che viene rilasciata, la LOA ha validità di 30 giorni."])},
    "nameof": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome della "])},
    "society": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["società "])},
    "thatLOADeclares": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["che rilascia la LOA;"])},
    "indicationAndPlacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Indicazione e la collocazione dell’Apparato nel"])},
    "andOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e della "])},
    "port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porta"])},
    "inWhichConnectionTerminated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in cui la cross connect deve essere terminata;"])},
    "crossConnectInActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione richiesta"])},
    "additionalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni Aggiuntive"])},
    "otherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui puoi inserire il codice dell'ordine d'acquisto emesso dalla tua azienda."])},
    "crossConnectReferrer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente per questa Cross Connect"])},
    "crossConnectReferrerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "crossConnectReferrerSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "crossConnectReferrerPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "crossConnectReferrerMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "redundancyOrderRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento Ordine Ridondanza"])},
    "redundancyOrderItemRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento Prodotto Ridondanza"])},
    "requestActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi l'attivazione della Cross Connect sotto indicata, nei termini contrattuali previsti"])},
    "costAndPrices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispettivi"])},
    "contractDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "serviceDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata del servizio (mesi)"])},
    "renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnovi successivi (mesi)"])},
    "billingOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequenza di fatturazione"])},
    "releaseTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempi di rilascio"])},
    "workingDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni lavorativi"])},
    "firstThreeMester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestrale anticipata"])},
    "carefullyReadBothData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla con attenzione i dati di entrambe le schede"])},
    "carefullyRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla con attenzione"])},
    "deviceAndPort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparato e Porta"])},
    "ifActiveRedundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hai attivato la ridondanza, controlla dati di entrambe le schede"])},
    "activateRedundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivare ridondanza?"])},
    "controlWithAttention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla con attenzione tutti i dati inseriti"])},
    "totalMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone mensile Ordine"])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli Ordine"])},
    "crossConnectReferral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente Cross connect"])},
    "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e Cognome"])},
    "referral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["di riferimento"])},
    "priceMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone mensile"])},
    "validateCrossConnectLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo Nuova Soluzione"])},
    "validateCrossConnectInfra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo Nuova Soluzione"])},
    "readWithAttention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggi con attenzione i dettagli della tua fornitura"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione Ordine"])},
    "labelConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermo di aver letto e accettato le condizioni dei documenti"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi al carrello"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente"])},
    "tipologia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia"])},
    "island": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isola"])},
    "subtitleTipologia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la tipologia della CROSS CONNECT INFRACAMPUS che vuoi richiedere"])},
    "subtitleTipologiaLocal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la tipologia della CROSS CONNECT LOCAL che vuoi richiedere"])},
    "wing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ala"])},
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "requiredRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sala e' obbligatoria"])},
    "requiredRack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack e' obbligatorio"])},
    "requiredIsle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isola e' obbligatoria"])},
    "requiredLOA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA e' obbligatoria"])},
    "requiredWing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ala e' obbligatoria"])},
    "requiredBuilding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio e' obbligatorio"])},
    "TBD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lista delle Cross Connect attive sarà disponibile a breve"])},
    "btnNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi informazioni"])},
    "modal": {
      "requestConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta confermata!"])},
      "requestSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta Confermata"])},
      "requestConfirmedSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "requestSentSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "requestSentSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi monitorare lo stato del ticket"])},
      "requestSentAreaAssistence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nell'area Assistenza -> Lista Richieste Supporto Cross Connect"])},
      "ticketId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'id del tuo ticket è "])},
      "monitorRequestStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora puoi monitorare lo stato della tua richiesta"])},
      "cartArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nell'area carrello"])},
      "emailsToSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per questa Cross Connect invieremo le seguenti mail"])},
      "requestSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["riepilogo richiesta"])},
      "activationReferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al referente attivazione"])},
      "confirmActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conferma attivazione"])},
      "adminReferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al referente amministrativo"])},
      "newRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai una nuova richiesta"])},
      "areaTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai all’area ticket"])},
      "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai al carrello"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione"])},
      "confirmCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai annullando l'operazione: scegli \"Continua\" per proseguire nell’inserimento dei dati oppure clicca \"Annulla richiesta\" per eliminare tutti i dati inseriti.\n\n\n\n\n\n"])},
      "confirmCancelSub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se confermi l’annullamento della richiesta tutti i dati inseriti fino ad ora verranno persi"])},
      "confirmCancelSub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli Annulla Richiesta, per eliminare tutti i dati inseriti."])},
      "backToRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
      "cancelRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina richiesta cross connect"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])}
    },
    "infoTitle_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prima di richiedere una cross connect leggi attentamente le informazioni introduttive:"])},
    "infoTitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le <strong><u>Cross Connect in E100</u></strong> sono “bifibra multimodale”"])},
    "infoTitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Cross Connect LOCAL possono essere richieste in modalità ridondata."])},
    "infoTitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per ordinare una Cross Connect LOCAL o INFRACAMPUS fino a Rack Cliente è indispensabile allegare la <strong><u>LOA in formato PDF.</u></strong>"])},
    "infoTitle_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le <strong><u>Cross Connect LOCAL</u></strong> sono realizzate entro 1 giorno lavorativo dalla richiesta."])},
    "infoTitle_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le <strong><u>Cross Connect INFRACAMPUS</u></strong> verso MIX, Irideos e Seeweb sono realizzate entro 7 giorni lavorativi dalla richiesta."])},
    "infoTitle_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutte le <strong><u>Cross Connect in C21</u></strong> sono “bifibra monomodale”."])}
  },
  "fields": {
    "maxlength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["max ", _interpolate(_named("len")), " caratteri"])}
  },
  "homePage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua dashboard"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "cardOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni richieste"])},
    "subOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "noSolutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun ordine inserito"])},
    "cardTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo profilo utente"])},
    "subTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "cardThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni in scadenza"])},
    "subThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "labelOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
    "labelTwoMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail principale"])},
    "labelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail secondaria"])},
    "labelThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica il tuo profilo"])},
    "cardMagazine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazine CDLAN"])},
    "subMagazine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggi i nostri ultimi articoli"])},
    "noMagazine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun articolo."])},
    "readAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggi tutto"])},
    "gotoMagazine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai al Magazine"])},
    "textCrm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai bisogno di assistenza sul portale?"])},
    "ctaCrm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattaci"])},
    "ctaCrmSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza Customer Portal"])},
    "ctaCrmNoLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi a "])},
    "ctaCrmNoLinkCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia indirizzo negli appunti"])},
    "ctaCrmLinkCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email copiato negli appunti."])},
    "sendSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio inviato"])},
    "requestCrm": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza sul Customer Portal"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci la tua richiesta"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INVIA"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nell'invio email"])}
    },
    "infoHubSpot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazione"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentile Cliente,<br />in ottica di miglioramento continuo, stiamo migrando il nostro sistema di ticketing interno su una nuova piattaforma. In fase di migrazione, potrebbe non essere disponibile lo storico dei ticket sul Customer Portal. Vi informeremo al termine delle operazioni.<br /><br />Le altre funzionalità del Customer Portal, in particolare l'apertura di nuovi ticket, non sono impattate dalla migrazione.<br /><br />A presto dal Team Applications di CDLAN"])}
    }
  },
  "checkCoverage": {
    "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verifica-copertura"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica la copertura"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l’indirizzo completo per conoscere la disponibilità"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci qui l'indirizzo completo"])},
    "buttonOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica la copertura"])},
    "resultLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo inserito"])},
    "buttonTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Soluzione"])},
    "detailLabelTech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnologia"])},
    "detailLabelCircuit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circuito"])},
    "detailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifiche della soluzione all’indirizzo inserito"])},
    "titleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni aggiuntive"])},
    "titleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti cliente finale"])},
    "titleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Radius"])},
    "titleStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info Aggiuntive"])},
    "titleStep5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrazione Olo2Olo"])},
    "statusStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Completamento"])},
    "statusStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato!"])},
    "statusStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloccato"])},
    "detailLabelOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione in attivazione"])},
    "detailLabelAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità banda"])},
    "guaranteedMinimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banda minima garantita"])},
    "cabinDistance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanza stimata dalla cabina"])},
    "speedUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità in upload"])},
    "speedDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità in download"])},
    "pricesKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio prezzi"])},
    "priceMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone mensile"])},
    "priceTantum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione"])},
    "forMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al mese"])},
    "tantum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una Tantum"])},
    "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fino a"])},
    "meters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metri"])},
    "titleRelatedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opzioni aggiuntive"])},
    "subTitleRelatedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona la tipologia di servizio in funzione della tecnologia scelta"])},
    "titleReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti cliente finale"])},
    "subTitleReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli il tuo cliente finale, se già presente, o inseriscine uno nuovo"])},
    "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è un campo obbligatorio."])},
    "isNotNumeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non è un valore numerico."])},
    "titlePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispettivi"])},
    "technicalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note informative"])},
    "noteTechnicalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note o dettagli tecnici"])},
    "stepsButtonOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "stepsButtonTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "stepsButtonThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "stepsButtonFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma e vai alla convalida"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campi obbligatori"])},
    "typeRouter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Router"])},
    "typeIPV4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subnet IPv4"])},
    "customerEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
    "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non in lista - aggiungi nuovo"])},
    "customerEndPIva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita IVA"])},
    "customerEndPIvaRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non inserisci il Codice Fiscale, questo campo obbligatorio."])},
    "customerEndFiscalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice fiscale"])},
    "customerEndFiscalCodeRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non inserisci la Partita IVA, questo campo obbligatorio."])},
    "techRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente tecnico"])},
    "nameSurnameTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e cognome del tecnico è un campo obbligatorio."])},
    "mobileTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telefono del tecnico è un campo obbligatorio."])},
    "emailTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email del tecnico è un campo obbligatorio."])},
    "administratorRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente amministrativo"])},
    "nameSurnameAdministratorRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e cognome del referente amministrativo è un campo obbligatorio."])},
    "mobileAdministratorRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telefono del referente amministrativo è un campo obbligatorio."])},
    "emailAdministratorRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email del referente amministrativo è un campo obbligatorio."])},
    "onSiteRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente onsite"])},
    "nameSurnameOnSiteRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e cognome dell'on site è un campo obbligatorio."])},
    "mobileOnSiteRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telefono dell'on site è un campo obbligatorio."])},
    "emailOnSiteRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email dell'on site è un campo obbligatorio."])},
    "nameSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e cognome"])},
    "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "orderRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento ordine"])},
    "clientPo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID alfanumerico"])},
    "modalCustomerEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo Cliente"])},
    "modalSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi"])},
    "subTitleRadiusAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci l’account radius (opzionale)"])},
    "radiusAccountUsernameRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username è un campo obbligatorio se hai inserito la Password."])},
    "radiusAccountPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password è un campo obbligatorio se hai inserito la Username."])},
    "titleAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aggiuntive"])},
    "buildingFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano dell'edificio"])},
    "buildingFloorRequiredOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano dell'edificio è un campo obbligatorio se hai inserito la Fascia oraria."])},
    "buildingFloorRequiredTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano dell'edificio è un campo numerico."])},
    "timeSlot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fascia oraria"])},
    "timeSlotRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano dell'edificio"])},
    "timeSlotMorning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mattino"])},
    "timeSlotAfternoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pomeriggio"])},
    "timeSlotEvening": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sera"])},
    "timeSlotAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrambe"])},
    "timeSlotError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fascia oraria è un campo obbligatorio se hai inserito il Piano dell'edificio."])},
    "titleMigration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migrazione Olo2Olo"])},
    "subTitleMigration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se vuoi richiedere la migrazione di un accesso esistente, inserisci il codice di Migrazione dell’Operatore Donante"])},
    "migrationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di migrazione"])},
    "migrationNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "filter": {
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
      "technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tecnologia"])},
      "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operatore"])},
      "speed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripristina"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica"])},
      "placeholderSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca la soluzione"])}
    },
    "filterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca il tuo kit..."])},
    "errorMessageProvince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La provincia è un campo obbligatorio"])},
    "errorMessageCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La città è un campo obbligatorio"])},
    "errorMessageStreetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il civico è un campo obbligatorio"])},
    "errorMessageAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il nome della via è un campo obbligatorio"])},
    "errorNoNetworkCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per l’indirizzo selezionato, non ci sono soluzioni disponibili"])},
    "goEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convalida nuova soluzione"])},
    "nAnPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di telefono deve essere composto solo da numeri"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione totale"])},
    "totalMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone mensile totale"])},
    "noteInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "draftSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bozza salvata"])},
    "noteRadius": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "helperSearchGoogle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un indirizzo tra quelli proposti"])},
    "saveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi salvare il cliente in rubrica?"])},
    "updateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi aggiornare il cliente in rubrica?"])},
    "searchKit": {
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provincia"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Città"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
      "civic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civico"])}
    },
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna"])}
  },
  "checkCoverageSummary": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo Nuova Soluzione"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica i dati inseriti e conferma la tua richiesta"])},
    "titleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo soluzione in attivazione"])},
    "titleReadOnly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo soluzione richiesta"])},
    "supplyActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione in attivazione"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "offerInActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerta in attivazione"])},
    "feesAndCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canoni e costi"])},
    "minDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata del servizio"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesi"])},
    "renewalDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnovi successivi"])},
    "releaseTimes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempi di rilascio"])},
    "workingDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giorni lavorativi"])},
    "billingOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequenza di fatturazione"])},
    "subTitleRelatedServicesSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il dettaglio prezzi si riferisce alla singola opzione"])},
    "subTitleReferencesSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla con attenzione i dati inseriti"])},
    "customerDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli cliente"])},
    "subtitleAdditionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aggiuntive indicate"])},
    "subTitleMigration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi al carrello"])},
    "subtitleEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermando l’ordine accetto che allo stesso vengano applicate le condizioni contrattuali in essere"])},
    "conditionsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campo obbligatorio"])},
    "errorGeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si &egrave; verificato un errore durante il salvataggio. Riprova o contatta crm&#64;cdlan.it"])}
  },
  "detailOrder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo soluzione"])},
    "boxTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti ordine"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "nOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ordine"])},
    "dateRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data della richiesta"])},
    "dateAttivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data attivazione"])},
    "clientPo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PO Cliente"])},
    "serviceDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata servizio (mesi)"])},
    "renewals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rinnovi successivi (mesi)"])},
    "activationDys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempi di rilascio (giorni lavorativi)"])},
    "billingPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequenza di fatturazione (mesi)"])},
    "titlePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispettivi totali"])},
    "priceNrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione"])},
    "priceMrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone"])},
    "rowsOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Righe ordine"])},
    "articleCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice servizio"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "priceRow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispettivi"])},
    "rowPriceMrc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone mensile"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantità"])},
    "states": {
      "INVIATO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INVIATO"])},
      "IN ATTIVAZIONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IN ATTIVAZIONE"])},
      "EVASO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVASO"])},
      "ANNULLATO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNULLATO"])},
      "VERIFICA TECNICA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERIFICA TECNICA"])},
      "INTERVENTO UTENTE RICHIESTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTERVENTO UTENTE RICHIESTO"])}
    },
    "errorGeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si &egrave; verificato un errore durante il caricamento."])},
    "serialnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice articolo"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "speedUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità in upload"])},
    "speedDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità in download"])},
    "sendToCdlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma e invia i dati a CDLAN"])},
    "errorInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valore non accettato"])},
    "infoBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermando e inviando i dati, porterà l'ordine nello stato \"Verifica tecnica\". Potrai apportare eventuali ulteriori modifiche solo dopo la valutazione dei nostri esperti."])},
    "modal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica inviata con successo"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordine è nello stato \"Verifica tecnica\". Potrai apportare eventuali ulteriori modifiche solo dopo la valutazione dei nostri esperti."])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])}
    }
  },
  "lines": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linee attive"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa pagina sono indicate le linee attive"])},
    "detailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio Linea attiva"])},
    "detailSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrier"])},
    "backToLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alle linee attive"])},
    "additionalDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli aggiuntivi"])},
    "linesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale linee attive"])},
    "list": {
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Linea"])},
      "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente Finale"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])}
    },
    "detail": {
      "summaryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio linea attiva"])},
      "sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])},
      "device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparato"])},
      "orderInformationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli Ordine"])},
      "orderCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Ordine"])},
      "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Ordine"])},
      "cdLanActivationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione CdLan"])},
      "telecomActivationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione fornitore"])},
      "titleReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti"])}
    },
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna linea attiva trovata"])}
  },
  "cart": {
    "clientPoLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine Riferimento Cliente"])},
    "clientPoPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci il tuo PO cliente"])},
    "clientPoRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il PO cliente è richiesto."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo Carrello"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma ordine"])},
    "order": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Ordine"]), _normalize(["Ordini"])])},
    "newSupply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova soluzione"])},
    "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai al carrello"])},
    "backToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al carrello"])},
    "orderName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine in attivazione"])},
    "expireDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi modificare o confermare questa Richiesta entro"])},
    "expireDateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alle ore"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli soluzione"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica richiesta"])},
    "cartEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrello vuoto!"])},
    "cartEmptyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciao Il tuo carrello è vuoto, Comincia verificando la copertura per l’indirizzo di tuo interesse"])},
    "addOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova soluzione"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla ordine"])},
    "forniture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso"])},
    "priceActivation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione"])},
    "forMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone mensile"])},
    "titlePriceSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispettivi Soluzione"])},
    "titlePriceOther": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrispettivi Opzioni aggiuntive"])},
    "titlePriceXC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio prezzi"])},
    "deleteSolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina richiesta"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione Ordine"])},
    "totalMonths": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone mensile Ordine"])},
    "qtyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Q.tà"])},
    "newRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Richiesta"])}
  },
  "removeItemCart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli Torna al Carrello per tornare all’ordine"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli Elimina Richiesta, per cancellare definitivamente la Soluzione: tutti i dati verranno cancellati."])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al Carrello"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina Richiesta"])}
  },
  "deleteOrderWizardNetwork": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai annullando l'operazione: scegli \"Continua\" per proseguire nell’inserimento dei dati oppure clicca \"Annulla richiesta\" per eliminare tutti i dati inseriti.\n\n\n\n\n\n"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli Annulla Richiesta, per eliminare tutti i dati inseriti."])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])}
  },
  "removeAllItems": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli Torna al Carrello per tornare all’ordine"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli Elimina Ordine, per cancellare definitivamente l’Ordine: tutti i dati verranno cancellati"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al Carrello"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina Ordine"])}
  },
  "deleteOrderWizardNetworkTmp": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C'è un ordine in attesa di essere confermato. Se confermi l’eliminazione dell’ordine tutti i dati di tutte le forniture contenute nell’ordine verranno persi"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler cancellare l’ordine perdendo tutti i dati inseriti?"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al Carrello"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metti in bozze l'ordine<br />e apri quello richiesto"])}
  },
  "confirmOrder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordine confermato"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nella pagina \"Soluzioni richieste\""])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai un nuovo ordine"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza il tuo ordine"])}
  },
  "KitNotFound": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit non più disponibile!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il kit non è più disponibile pertanto non puoi più modificare questa linea"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi eliminare questo fare una nuova ricerca?"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai un nuovo ordine"])},
    "deleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al carrello"])}
  },
  "shipping": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“Consegne” se devi far arrivare un tuo apparato nel deposito di CDLAN. “Ritiri” se devi richiedere a CDLAN di consegnare un tuo apparato al corriere."])},
    "cardOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RICEZIONE MERCI"])},
    "cardOneText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota il servizio se vuoi che CDLAN si occupi di ricevere la tua merce presso i nostri data center C21 ed E100. Il servizio è operativo per urgenze in orario extra-lavorativo solo in C21."])},
    "cardTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSEGNA MERCI"])},
    "cardTwoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota il servizio se vuoi che CDLAN si occupi di consegnare la tua merce al corriere."])},
    "deliveries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota"])},
    "deliveriesTitleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Data Center"])},
    "deliveriesTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio Merce"])},
    "deliveriesTitleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
    "deliveriesSubTitleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il Data Center in cui inviare la tua merce"])},
    "pickupSubTitleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il Data Center da cui il corriere ritirerà i tuoi apparati"])},
    "deliveriesSubTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compila il form sottostante con le informazioni richieste"])},
    "titleLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "titleDcDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "textFiveShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni Ricezione Merci"])},
    "textFivePickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni Ritiro Merci"])},
    "dateAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di consegna prevista"])},
    "dateAccessPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di ritiro prevista"])},
    "carrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corriere"])},
    "shippingCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice spedizione"])},
    "packagesShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni colli"])},
    "pallets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° di bancali"])},
    "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt x Lar x Lun (cm)"])},
    "packages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° di colli"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso totale (kg)"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione della merce"])},
    "descriptionInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "moreInfoInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni aggiuntive"])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "uploadAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allegati"])},
    "uploadAttachSubTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puoi caricare fino ad un massimo di ", _interpolate(_named("total")), " allegati che non superino i ", _interpolate(_named("size")), "Mb totali"])},
    "deliveriesTitleStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di contatto"])},
    "deliveriesSubTitleStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica i dati di contatto. Verrai contattato in caso di necessità."])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center Selezionato"])},
    "pickUpTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritiro"])},
    "pickUpTitleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
    "deliveriesTitleStep5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritiro"])},
    "waybillTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega lettera di vettura"])},
    "waybillOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermi di aver applicato al pacco la lettera di vettura?"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SI"])},
    "deleteTicketPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "openTicketShippingPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di arrivo"])},
    "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di spedizione"])},
    "infoShippingPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni ritiro"])},
    "n_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ticket"])},
    "uploadPickUpAttachSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega la distinta e la lettera di vettura*"])},
    "waybill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega lettera di vettura"])},
    "distinct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega distinta"])},
    "onlyInteger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il valore inserito non è un intero"])},
    "alphaNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono ammessi solo valori alfanumerici"])},
    "minValueValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il valore minimo dev'essere ", _interpolate(_named("n"))])},
    "attachFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E' accettato il formato ", _interpolate(_named("format"))])},
    "attachSize": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["E' stato superato il limite massimo di ", _interpolate(_named("size")), "Mb"])},
    "uploadAttachPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lettera di vettura è obbligatoria."])},
    "stepsButtonOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "location": {
      "shipping": {
        "sectionOneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari ricezione merci"])},
        "sectionOneC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.00 - 18.00 da lunedì a venerdì"])},
        "sectionOneE100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9.00 - 12.00 &#124; 14.00 - 18.00 da lunedì a venerdì"])},
        "sectionThreeTitleC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricezione Merci Urgenti fuori orario"])},
        "sectionThreeTextC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accettiamo merci in urgenza. E’ obbligatorio aprire un ticket e fare richiesta. E’ necessario fornire al corriere il seguente numero di telefono 351.551.0966"])},
        "sectionTwoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condizioni Ricezione Merci"])},
        "sectionTwoLabelOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso fino a"])},
        "sectionTwoLabelOneWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25,0 kg"])},
        "sectionTwoLabelOneValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STANDARD"])},
        "sectionTwoLabelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso maggiore di"])},
        "sectionTwoLabelTwoWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25,0 kg"])},
        "sectionTwoLabelTwoValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BANCALE OBBLIGATORIO"])},
        "sectionTwoLabelThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La merce che non rispetta i requisiti non verrà ritirata"])},
        "sectionFourTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preavviso minimo"])},
        "sectionFourLabelOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso fino a 25,0 Kg"])},
        "sectionFourLabelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso maggiore di 25,0 Kg"])},
        "sectionFourValueOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 ora"])},
        "sectionFourValueTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 giorni solari"])},
        "sectionFiveTextC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito C21 disponibile per stoccaggio fino a installazione max 10 gg"])},
        "sectionFiveTextE100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposito E100 disponibile per stoccaggio fino a installazione max 10 gg"])}
      },
      "pickup": {
        "sectionOneTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preavviso minimo"])},
        "sectionOneValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 giorni solari"])},
        "sectionTwoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario consegna merci"])},
        "sectionTwoC21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9:00-18:00 da lunedì a venerdì"])},
        "sectionTwoE100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9:00-12:00 &#124; 14:00-18:00 da lunedì a venerdì"])},
        "sectionTwoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per le spedizioni superiori a 25 kg, la merce deve essere predisposta su bancale. Il bancale (non fornito da CDLAN) deve avere dimensioni euro pallet cm80x120"])},
        "sectionFourText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per la consegna al corriere di merci superiori a 25,0 kg, la merce deve essere predisposta su bancale (non fornito da CDLAN) con dimensioni euro pallet cm 80x120"])},
        "sectionFiveText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La merce che non rispetta i requisiti non verrà consegnata al corriere"])}
      }
    },
    "labelTicketShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegna"])},
    "labelTicketPickUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritiro"])},
    "informative": {
      "shipping": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Richiedente accetta quanto di seguito:"])},
        "lists": {
          "point1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Ricevente è responsabile esclusivamente della verifica relativa alla corrispondenza del numero di colli ricevuti e dell’integrità degli stessi."])},
          "point2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Ricevente ha l’obbligo di contattare il Referente indicato nel presente documento in modo da ricevere le opportune istruzioni nel caso in cui:<ul><li>non ci fosse corrispondenza tra il numero di colli attesi e il numero di colli in consegna</li><li>i colli presentassero dei danni visibili</li></ul>"])},
          "point3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Ricevente potrà rifiutare la ricezione di colli danneggiati o in numero diverso da quelli attesi qualora non ricevesse pronte istruzioni dal Referente. In caso di colli danneggiati  fornirà prova fotografica da allegare al Ticket."])},
          "point4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Ricevente si impegna a custodire la merce in luogo idoneo allo stoccaggio di materiali."])},
          "point5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Ricevente non potrà essere considerato in alcun caso responsabile per lo stato del contenuto dei colli ricevuti qualora gli stessi si presentassero perfettamente integri."])}
        },
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermo di averne preso visione."])}
      },
      "pickup": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Richiedente conosce ed accetta quanto di seguito riportato:"])},
        "lists": {
          "point1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Mittente non è in alcun modo responsabile nei confronti del Richiedente né del destinatario della merce del contenuto della spedizione."])},
          "point2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Mittente è responsabile esclusivamente della verifica relativa alla corrispondenza del numero di colli da spedire indicati nel presente form e dell’integrità dell’imballaggio degli stessi."])},
          "point3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Mittente ha l’obbligo di contattare il Referente riportato nel presente documento in modo da ricevere le opportune istruzioni nel caso in cui:<ul><li>non ci fosse corrispondenza tra il numero di colli indicati nel presente Form e il numero di colli in spedizione</li><li>i colli presentassero dei danni visibili</li></ul>"])},
          "point4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Richiedente sarà il solo responsabile della scelta del vettore e del corretto imballaggio del materiale da spedire che dovrà essere fatto nel rispetto delle normative vigenti."])},
          "point5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il Richiedente fornirà al Mittente la lettera di vettura, resta inteso che i costi di spedizione saranno a carico esclusivo del Richiedente."])}
        },
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermo di averne preso visione."])}
      }
    }
  },
  "deleteShippingTicket": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai annullando l'operazione: scegli \"Continua\" per proseguire nell’inserimento dei dati oppure clicca \"Annulla richiesta\" per eliminare tutti i dati inseriti.\n\n\n\n\n\n"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i dati inseriti fino ad adesso verranno perduti."])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])}
  },
  "confirmShippingDeliveries": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta inoltrata con successo!"])},
    "sub": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ci occuperemo di ricevere la tua merce presso il ", _interpolate(_named("destination")), "."])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta sarà disponibile a breve nell'area ticket."])},
    "newTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova prenotazione"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alle tue richieste"])}
  },
  "deleteShippingPickUpTicket": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi davvero annullare la richiesta di ritiro? Se confermi l’annullamento"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i dati inseriti fino ad adesso verranno perduti."])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna al form"])}
  },
  "confirmShippingPickUpDeliveries": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta inoltrata con successo!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ci occuperemo di consegnare la tua merce al corriere."])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta sarà disponibile a breve nell'area ticket."])},
    "newTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova prenotazione"])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alle tue richieste"])}
  },
  "users": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione utenti"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui puoi gestire utenti e ruoli assegnati ad essi"])},
    "tabOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UTENTI"])},
    "tabTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RUOLI"])},
    "searchUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca utente"])},
    "downLoadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
    "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi utente"])},
    "tableNameSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome e Nome"])},
    "tableEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo email"])},
    "tablePhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
    "tableRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo utente"])},
    "titleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo utente"])},
    "subTitleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "titleEditUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica "])},
    "subTitleEditUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna le informazioni"])},
    "sectionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di contatto"])},
    "nameUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "surNameUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "emailUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "emailTwoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail secondaria"])},
    "maintenanceCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;Voglio ricevere le notifiche di manutenzione sulla mail principale"])},
    "emailTwoCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;Voglio ricevere le notifiche di manutenzione sulla mail secondaria"])},
    "phoneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
    "sectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il ruolo da assegnare"])},
    "subSectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi assegnare un solo ruolo ad utente"])},
    "newRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi creare un nuovo ruolo personalizzato?"])},
    "btnNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo ruolo"])},
    "saveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva nuovo utente"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "searchRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca ruolo"])},
    "btnNewRoleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi ruolo"])},
    "areaRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aree associate:"])},
    "usersRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti associati:"])},
    "titleDetailsUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli utente"])},
    "subTitleDetailsUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "detailsRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina utente"])},
    "disableUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilita utente"])},
    "abilityUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilita utente"])},
    "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceve le manutenzioni all'indirizzo"])},
    "associateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associa utente"])},
    "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina ruolo"])},
    "deleteGroupTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non puoi eliminare questo ruolo finché ci sono degli utenti associati"])},
    "titleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca un utente da associare"])},
    "subTitleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un utente da associare al ruolo che stai creando."])},
    "confirmUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "badEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato email non corretto"])},
    "titleDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina utente"])},
    "subTitleDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vuoi davvero eliminare ", _interpolate(_named("name")), "?"])},
    "textDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i dati ad esso associati verranno cancellati"])},
    "btnDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
    "revertDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "notFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha un formato non valido"])},
    "errorEmailSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email già esistente"])},
    "exPhoneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esempio: 39123456789"])}
  },
  "companies": {
    "nameSurnameTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e cognome è un campo obbligatorio."])},
    "emailTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email è un campo obbligatorio."])},
    "mobileTechRefRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il telefono è un campo obbligatorio."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione clienti finali"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui puoi gestire i clienti finali"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "searchUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca cliente finale"])},
    "downLoadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
    "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi cliente finale"])},
    "tableNameSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ragione Sociale"])},
    "tableVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partita Iva"])},
    "tableCf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Fiscale"])},
    "titleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo utente"])},
    "subTitleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi i dati per consentire al nuovo utente di accedere e operare"])},
    "editCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica "])},
    "subTitleEditUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna le informazioni"])},
    "sectionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di contatto"])},
    "nameUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "surNameUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "emailUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "emailTwoUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email secondaria"])},
    "emailTwoCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizza questa mail secondaria per ricevere le notifiche delle manutenzioni"])},
    "phoneUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di telefono"])},
    "sectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di contatto"])},
    "subSectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informzaioni di contatto per tipo technical"])},
    "subSectionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informzaioni di contatto per tipo administrative"])},
    "subSectionFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informzaioni di contatto per tipo on-site"])},
    "newRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuoi creare un nuovo ruolo personalizzato?"])},
    "btnNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo ruolo"])},
    "saveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva nuovo cliente finale"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "searchRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca ruolo"])},
    "btnNewRoleTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi ruolo"])},
    "areaRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aree associate:"])},
    "usersRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti associati:"])},
    "textUsersRole": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Utente associato"]), _normalize(["Utenti associati"])])},
    "titleDetailsUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli utente"])},
    "subTitleDetailsUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui puoi vedere le informazioni dell’utente"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruolo"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina cliente Finale"])},
    "disableUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabilita cliente finale"])},
    "abilityUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abilita utente"])},
    "maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceve le manutenzioni all'indirizzo"])},
    "associateUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associa utente"])},
    "deleteGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina ruolo"])},
    "titleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca un utente da associare"])},
    "subTitleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un utente da associare al ruolo che stai creando."])},
    "confirmUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "badEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato email non corretto"])},
    "titleDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina cliente finale"])},
    "subTitleDelete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vuoi davvero eliminare ", _interpolate(_named("name")), "?"])},
    "textDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutti i dati ad esso associati verranno cancellati"])},
    "btnDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
    "revertDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "notFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha un formato non valido"])},
    "errorEmailSame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email già esistente"])},
    "technical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente tecnico"])},
    "administrative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente amministrativo"])},
    "on-site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente onsite"])}
  },
  "group": {
    "titleNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuovo ruolo"])},
    "subTitleNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "nameRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del ruolo"])},
    "sectionOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona le aree associate al ruolo"])},
    "sectionOneError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi selezionare almeno un area"])},
    "sectionTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un colore da associare al ruolo"])},
    "sectionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona utenti da associare al ruolo"])},
    "subSectionThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In questa fase puoi associare al ruolo utenti già presenti nel sistema che non abbiano un ruolo già associato. Una volta salvato il nuovo ruolo potrai associarvi altri utenti"])},
    "searchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca utente"])},
    "saveNewRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva nuovo ruolo"])},
    "titleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca utente da associare"])},
    "subTitleSearchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un utente da associare al ruolo che stai creando."])},
    "confirmUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "colorGroupRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il colore da associare ad un ruolo"])},
    "titleEditGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica il ruolo"])},
    "subTitleEditGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna le informazioni"])},
    "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permessi"])},
    "noAssign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non puoi assegnare quest'area"])},
    "default": {
      "soluzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni"])},
      "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connettività"])},
      "accessi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessi"])},
      "verifica_copertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica copertura"])},
      "stato_linee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linee attive"])},
      "cross_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects"])},
      "cross_connect_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
      "cross_connect_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects attive"])},
      "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "remote_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
      "remote_hands_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
      "remote_hands_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista remote hands"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
      "shipping_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
      "shipping_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista shipping"])},
      "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "gestione_utenti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione utenti"])},
      "utenti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
      "ruoli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruoli"])},
      "ordini": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni richieste"])},
      "fatture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatture"])},
      "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
      "ingressi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingressi"])},
      "ingressi_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
      "ingressi_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista ingressi"])},
      "incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
      "incident_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Incident"])},
      "change_request_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Change Request"])},
      "help_ticket_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista richieste assistenza"])},
      "servizi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi"])},
      "assistenza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza richieste"])},
      "report_soluzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Soluzioni"])},
      "virtualizzazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualizzazione"])},
      "notifiche_manutenzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche manutenzioni"])},
      "storico_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico dei tickets"])},
      "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])},
      "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAX"])},
      "assorbimenti_colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumi energetici"])},
      "interventi_pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventi a Pagamento"])},
      "gestione_clienti_finali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione Clienti Finali"])},
      "report_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "storico_ingressi_dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico ingressi in DC"])},
      "cloud_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "voip_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voip"])},
      "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
      "impostazioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
      "area_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Partner"])},
      "first": {
        "soluzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connettività"])},
        "accessi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessi"])},
        "verifica_copertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica copertura"])},
        "stato_linee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linee attive"])},
        "cross_connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects"])},
        "cross_connect_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects Nuovo"])},
        "cross_connect_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects attive"])},
        "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
        "remote_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
        "remote_hands_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands Nuovo"])},
        "remote_hands_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista remote hands"])},
        "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping"])},
        "shipping_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping Nuovo"])},
        "shipping_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista shipping"])},
        "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
        "gestione_utenti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestione utenti"])},
        "utenti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
        "ruoli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruoli"])},
        "ordini": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni richieste"])},
        "fatture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatture"])},
        "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
        "ingressi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingressi"])},
        "ingressi_nuovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingressi Nuovo"])},
        "ingressi_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista ingressi"])},
        "incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
        "incident_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Incident"])},
        "change_request_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista Change Request"])},
        "help_ticket_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista richieste assistenza"])},
        "servizi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi"])},
        "assistenza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza richieste"])},
        "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
        "report_soluzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Soluzioni"])},
        "virtualizzazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualizzazione"])},
        "notifiche_manutenzioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche manutenzioni"])},
        "storico_ticket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico dei tickets"])},
        "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report VoIP"])},
        "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAX"])},
        "assorbimenti_colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumi energetici"])},
        "interventi_pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventi a Pagamento"])},
        "impostazioni": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
        "report_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Cloud"])},
        "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
        "storico_ingressi_dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico ingressi in DC"])},
        "cloud_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
        "voip_lista": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voip"])},
        "area_partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area Partner"])}
      }
    }
  },
  "help": {
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puoi vederla qui"])},
    "detailTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Richiesta ", _interpolate(_named("ticket_id"))])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatto"])},
    "secondContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri riferimenti"])},
    "secondContactLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email referente"])},
    "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email non valida"])},
    "submitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "confirmedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta confermata"])},
    "confirmedSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "confirmedTicketId": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'id del tuo ticket è ", _interpolate(_named("confirmedRequestId"))])},
    "mainInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni principali"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "solutionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione interessata"])},
    "emailRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Referente"])},
    "duplicatedTicketAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una richiesta per questo asset risulta già aperta"])},
    "openTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per aprire delle richieste di supporto vai alle aree delle soluzioni: "])},
    "incident": {
      "severityLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello di gravità"])},
      "severityLevelDescTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livelli di gravità disponibili:"])},
      "severityLevel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L1 - Disservizio"])},
      "severityLevel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L2 - Degrado"])},
      "severityLevel3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L3 - Segnalazione"])},
      "severityLevel4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S1 - Grave"])},
      "severityLevel5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S2 - Moderato"])},
      "severityLevel6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S3 - Non grave"])},
      "severityLevel1Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perdita totale o parziale della fruibilità del servizio"])},
      "severityLevel2Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il servizio funziona ma non performa correttamente"])},
      "severityLevel3Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento senza impatto sul servizio "])},
      "goToListSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi monitorare lo stato della tua richiesta cliccando sul pulsante qui sotto"])},
      "goToListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai alla lista"])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
      "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])}
    },
    "generic": {
      "incident": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident - Generico"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident Generico - Nessuna soluzione collegata"])},
        "cross_connects": {
          "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident Generico - Cross Connect - Nessuna soluzione collegata"])}
        }
      },
      "changeRequest": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request - Generica"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request Generica - Nessuna soluzione collegata"])},
        "cross_connects": {
          "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request Generico - Cross Connect - Nessuna soluzione collegata"])}
        }
      },
      "techSupport": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza Tecnica - Generica"])},
        "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza Tecnica Generica - Nessuna soluzione collegata"])}
      },
      "btnLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non trovi la soluzione clicca su queste opzioni per aprire una segnalazione generica"])},
      "solutionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La segnalazione che stai aprendo non è collegata ad una specifica soluzione"])}
    },
    "changeRequest": {
      "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
      "confirmedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta confermata"])},
      "confirmedSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "confirmedTicketId": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'id del tuo ticket è ", _interpolate(_named("changeRequestId"))])}
    },
    "techSupport": {
      "formTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza Tecnica"])}
    }
  },
  "colocation": {
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SI"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun servizio attivo"])},
    "titleDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio Soluzione"])},
    "subTitleDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui trovi il dettaglio della soluzione selezionata"])},
    "itemCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Totale soluzioni: ", _interpolate(_named("total_count"))])},
    "genericInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni Generali"])},
    "list": {
      "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sala"])},
      "rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])}
    },
    "detail": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
      "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano"])},
      "isle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isola"])},
      "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condiviso"])},
      "reserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riservato"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])}
    },
    "backToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla lista"])},
    "solutions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle colocation attive associate all'azienda"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
      "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio"])},
      "data_attivazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data attivazione"])},
      "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datacenter"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])}
    }
  },
  "remoteHands": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elenco delle tue ultime remote hands"])},
    "titleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "titleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
    "titleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "subTitleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulteriori informazioni"])},
    "subTitleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggi con attenzione i dettagli della Remote hand"])},
    "subTitleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggi con attenzione i dettagli della Remote hand"])},
    "infoRh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "labelDatacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "valueDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])},
    "labelRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sala"])},
    "labelRack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
    "infoOperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervento"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
    "typeOperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di Intervento"])},
    "otherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altre informazioni:"])},
    "infoOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui puoi inserire il codice dell'ordine d'acquisto emesso dalla tua azienda."])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento Ordine"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])},
    "typeOperationOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipi di intervento disponibili"])},
    "typeOperationTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapido"])},
    "typeOperationThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eseguito entro due ore dalla richiesta"])},
    "typeOperationFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "typeOperationFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eseguito senza tempi minimi garantiti in modalità best effort"])},
    "infoOperationRh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni Location e intervento:"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
    "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizzo che l’addebito dell’intervento (ove previsto) sia inserito nel prossimo ciclo di fatturazione."])},
    "labelConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermo di aver letto e accettato le condizioni dei documenti"])},
    "what": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio"])},
    "typeActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "detailLabelLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "Rapid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapido"])},
    "Standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "titleDetails": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket ID ", _interpolate(_named("ticket_id")), " - Thread"])},
    "subTitleDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui trovi le tue comunicazioni con i nostri operatori"])},
    "uploadAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allegati"])},
    "tipo_di_richiesta": {
      "riavvio_server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riavvio server"])},
      "sostituzione_ats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sostituzione ATS"])},
      "etichettatura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichettatura"])},
      "altro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
      "altro_no_sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro (no SLA)"])}
    },
    "livello_tecnico": {
      "normale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B - Normale"])},
      "avanzato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A - Avanzato"])}
    },
    "urgenza": {
      "pianificato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P - Pre-scheduled"])},
      "urgente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U - Urgent"])}
    },
    "finestra_temporale": {
      "bt_orario_ufficio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BT - Business Time"])},
      "etb_fuori_orario_ufficio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBT - Extra Business Time"])}
    },
    "request": {
      "typeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di richiesta"])},
      "selectTypeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Tipo di richiesta"])},
      "typeRequestRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di richiesta è richiesto"])},
      "techLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello tecnico"])},
      "selectTechLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Livello tecnico"])},
      "techLevelRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello tecnico è richiesto"])},
      "urgency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgenza"])},
      "selectUrgency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Urgenza"])},
      "urgencyRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgenza è richiesta"])},
      "timeWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finestra temporale"])},
      "selectTimeWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona Finestra temporale"])},
      "timeWindowRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finestra temporale è richiesta"])},
      "activityStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio attività"])},
      "activityStartRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio attività è richiesto"])}
    },
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommario"])},
    "summaryRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sommario è richiesto"])}
  },
  "deleteRemoteHandTicket": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai annullando l'operazione: scegli \"Continua\" per proseguire nell’inserimento dei dati oppure clicca \"Annulla richiesta\" per eliminare tutti i dati inseriti.\n\n\n\n\n\n"])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli Annulla richiesta per eliminare tutti i dati inseriti."])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "deleteTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])}
  },
  "confirmRemoteHand": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta inoltrata con successo!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta sarà disponibile a breve nell'area ticket."])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta sarà disponibile a breve nell'area ticket."])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai all’area ticket"])},
    "newTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova richiesta"])}
  },
  "confirmIncident": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta inoltrata con successo!"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta sarà disponibile a breve nell'area ticket."])},
    "subStrong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta sarà disponibile a breve nell'area ticket."])},
    "cdlButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai all’area ticket"])},
    "newTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova richiesta"])}
  },
  "refTicket": {
    "nameRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del referente"])},
    "surnameRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome del referente"])},
    "phoneRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
    "emailRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "listUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista utenti"])}
  },
  "tickets": {
    "Open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presa in carico"])},
    "OnHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa"])},
    "Closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato"])},
    "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Richiesta"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore generico"])},
    "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando"])},
    "lastDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ultimi ", _interpolate(_named("days")), " giorni"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca..."])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "noTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun ticket trovato"])},
    "noBookeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna prenotazione trovata"])},
    "new": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["C'è un nuovo ticket"]), _normalize(["Ci sono ", _interpolate(_named("n")), " nuovi ticket"])])},
    "backToList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla lista"])},
    "referent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presa in carico"])},
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completato"])},
    "btnNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova Richiesta"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
    "defaultDetailTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta"])},
    "noUserBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente non disponibile"])},
    "attivazioni_tlc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione"])}
  },
  "ticketsList": {
    "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se hai creato un ticket e non lo vedi nella lista, attendi qualche minuto e aggiornala."])},
    "RemoteHand": {
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ticket"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Richiesta"])},
      "cf_datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "cf_sala": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sala"])},
      "cf_rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
      "cf_tipo_attivita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
      "cf_nome_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente"])},
      "cf_email_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperto da"])},
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])}
    },
    "ActiveLines": {
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ticket"])},
      "modifiedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Modifica"])},
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Creazione"])},
      "closedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Chiusura"])},
      "contactId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Contatto"])},
      "threadCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero Thread"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "cf_short_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolo Breve"])},
      "cf_datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "cf_email_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Referente"])},
      "cf_severity_level_picklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello di Gravità"])},
      "cf_nome_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Referente"])},
      "cf_sold_solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
      "cf_asset_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di Asset"])},
      "cf_servizi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta"])}
    },
    "lines": {
      "cliente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente"])},
      "intestatario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliente Finale"])},
      "serialnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Linea"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])}
    },
    "CrossConnect": {
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero biglietto"])},
      "modifiedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo modifica"])},
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo ctato"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo creazione"])},
      "closedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo chiusura"])},
      "contactId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID contatto"])},
      "threadCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conteggio Thread"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "cf_email_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente"])}
    },
    "Cloud": {
      "ram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ram"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensioni"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
      "solution_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di soluzione"])},
      "vcpu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VCPU"])}
    },
    "Voip": {
      "solution_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di soluzione"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])}
    },
    "Shipping": {
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ticket"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Richiesta"])},
      "cf_datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "cf_data_arrivo_pacco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Consegna/Ritiro"])},
      "cf_nome_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente"])},
      "cf_email_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperto da"])},
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "cf_servizi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizio"])}
    },
    "Ingresso": {
      "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ticket"])},
      "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Richiesta"])},
      "cf_datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
      "cf_from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresso"])},
      "cf_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uscita"])},
      "modifiedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Modifica"])},
      "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
      "closedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Chiusura"])},
      "contactId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Contatto"])},
      "threadCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero Thread"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
      "cf_nome_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Referente"])},
      "cf_email_referente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperto da"])}
    },
    "Incident": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "ChangeRequest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Request"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "TechSupport": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza tecnica"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "default": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta"])},
      "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperto da"])},
      "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data apertura"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
      "asset_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia Soluzione"])},
      "lines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessi"])},
      "accesso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessi"])},
      "cross-connects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects"])},
      "CrossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects"])},
      "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocation"])},
      "severity_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gravità"])},
      "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])}
    }
  },
  "detailsTicket": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread con operatori"])},
    "ticketNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket ID"])},
    "createdTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data creazione"])},
    "modifiedTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiornato il"])},
    "statusType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "attach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allegati"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agente"])},
    "answersBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispondi al ticket"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
    "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cc"])},
    "bcc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bcc"])},
    "attachMail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allegati a questa mail"])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rispondi al ticket"])},
    "modalSubTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ticket ID ", _interpolate(_named("ticket_id"))])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risposta"])},
    "uploadAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allegati"])},
    "uploadAttachSub": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puoi caricare fino a un massimo di 5 allegati che non superino ", _interpolate(_named("size")), "Mb totali"])},
    "answersSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "removeAnswerTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
    "IncidentDetailSolutionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
    "noReply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento, puoi aggiungere informazioni o rispondere al ticket esclusivamente tramite il tuo client di posta. Stiamo lavorando per rendere presto disponibile questa funzionalità direttamente nel Customer Portal."])}
  },
  "page404": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagina non trovata"])}
  },
  "invoices": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fatture"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "nDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N. documento"])},
    "typeDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo documento"])},
    "dateDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data emissione"])},
    "payDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagata"])},
    "noDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna fattura presente."])},
    "downDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica"])}
  },
  "orders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzioni richieste"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "backToOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alle soluzioni richieste"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoria"])},
    "kit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "nOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero soluzione"])},
    "submission_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data richiesta"])},
    "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli"])},
    "noOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna soluzione richiesta."])},
    "additionalRefs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti Ordine"])},
    "orderRowRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serialnumber"])},
    "bundleCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice bundle"])},
    "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero ordine"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "noPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi scaricare il PDF solo se l'ordine risulta EVASO"])},
    "noAvaiblePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF non disponibile"])}
  },
  "filter": {
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inizio"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data fine"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FILTRA"])}
  },
  "tour": {
    "solutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta le soluzioni attive e apri ticket per incident o change request"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedi ai servizi di colocation e gestisci richieste di ingresso al Data center, Remote Hands e Shipping"])},
    "management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza le fatture e le richieste d'acquisto"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza i report sullo stato delle soluzioni e dell'assistenza."])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci gli utenti che possono accedere e assegna ruoli per limitare la visualizzazione delle aree del portale"])},
    "partner_area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestisci i dati dei tuoi clienti finali"])}
  },
  "buttons": {
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avanti"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salta"])}
  },
  "accessDc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingressi"])},
    "sharedRack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack condiviso"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "requestBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi ora"])},
    "detailLabelDc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "detailLabelRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli ingresso richiesto"])},
    "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
    "titleStep1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di ingresso"])},
    "titleStep2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando"])},
    "titleStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresso Data Center"])},
    "titleStep4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi deve entrare"])},
    "titleStep5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimenti"])},
    "titleStep6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altri Ingressi"])},
    "titleStep7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desk e Orari"])},
    "titleStep8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazione posto auto"])},
    "titleAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa devi prenotare"])},
    "titleWhen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando"])},
    "titleWhereAndWhat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresso in Data Center"])},
    "titleWhoShouldEnter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi deve entrare"])},
    "titleAccessDcReferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di contatto"])},
    "accessTypeOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INGRESSO SALE DATA CENTER"])},
    "accessTypeOneDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi l’ingresso a sale/isole/rack del data center."])},
    "accessTypeOneDescriptionIsBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi l'ingresso alle sale del data center per conto terzi"])},
    "accessTypeTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INGRESSO COWORKING ROOM"])},
    "accessTypeTwoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenota una o più postazioni o l’intera CoWorking Room"])},
    "accessTypeThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRENOTAZIONE POSTO AUTO"])},
    "accessTypeThreeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva uno o più posti auto"])},
    "accessTypeThreeDescriptionIsBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva uno o più posti auto."])},
    "accessTypeThreeDescriptionComplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva uno o più posti auto"])},
    "typeAccesslabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di ingresso"])},
    "dateAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di ingresso"])},
    "fromTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di ingresso"])},
    "toTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di uscita"])},
    "accessWhenTime": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dalle ore ", _interpolate(_named("from")), " alle ore ", _interpolate(_named("to"))])},
    "apparati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi installare apparati?"])},
    "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai bisogno delle chiavi per accedere alla Sala?"])},
    "roomsAndRacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale e Rack"])},
    "selectRoomsKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona le tue sale"])},
    "noteRoomsKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motiva la richiesta"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sala"])},
    "selectRoomsKeysRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi selezionare almeno una sala"])},
    "infoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi entrare nel Data Center C21 di Milano utilizzando il riconoscimento biometrico facciale."])},
    "infoContentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri un ticket di ingresso solo nei seguenti casi:"])},
    "infoContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<ul class=\"text-list\"><li>Se le persone che devono entrare con te non hanno l'accesso tramite riconoscimento facciale</li><li>Per prenotare desk o l'intera CoWorking Room</li><li>Per riservare un posto auto</li></ul>"])},
    "selectAllRacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona tutti"])},
    "deselectAllRacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deseleziona tutti"])},
    "chooseUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona gli utenti che devono accedere"])},
    "listUsersBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista utenti"])},
    "modalAccessUsersTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrica utenti"])},
    "modalAccessUsersSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non trovi l’utente che cerchi, chiudi la finestra e aggiungilo tramite l'apposito bottone 'Nuovo Utente'"])},
    "modalAccessUsersChooseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia il tipo di ingresso"])},
    "modalAccessUsersChooseSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se devi far entrare delle persone che non hanno il libero accesso come te, devi cambiare il tipo di prenotazione."])},
    "searchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca utente"])},
    "modalAccessNewUserTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi nuovo utente alla rubrica"])},
    "modalAccessNewUserSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi i dati per consentire al nuovo utente di accedere al Data Center e operare"])},
    "modalAccessNewUserLabelOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni di contatto"])},
    "modalAccessNewUserLabelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documento"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "doc_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di documento"])},
    "identityCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta di identità"])},
    "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporto"])},
    "licenseDrive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patente"])},
    "doc_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di documento"])},
    "doc_expiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza documento"])},
    "newUserBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo utente"])},
    "titleDeskAndHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desk e Orari"])},
    "labelCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il numero di postazioni che vuoi prenotare"])},
    "infoCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi prenotare i desk singoli per un massimo di 4 ore e l’intera CoWorking Room per un massimo di 2 ore."])},
    "allRoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(intera sala)"])},
    "labelTimeCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario"])},
    "nDesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di desk"])},
    "tooHours": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non puoi prenotare i desk per più di ", _interpolate(_named("hours")), " ore."])},
    "nDeskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° Desk"])},
    "whenDeskLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quando"])},
    "errorDesk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di desk selezionati non corrisponde al numero di persone inserite "])},
    "noSeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun posto disponibile negli orari selezionati"])},
    "errorNoUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi selezionare almeno una persona "])},
    "errorMoreUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di persone selezionate è superiore al numero di desk "])},
    "errorNoType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi selezionare almeno un tipo di ingresso"])},
    "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
    "listCarBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista auto"])},
    "newCarBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi auto"])},
    "modalAccessNewCarTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi una nuova vettura alla rubrica"])},
    "modalAccessNewCarSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizza il campo Identificativo auto per inserire un'etichetta di fantasia per facilitare la ricerca nelle prossime richieste."])},
    "modalAccessCarsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrica delle auto"])},
    "modalAccessCarsSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se non trovi l’auto che cerchi, chiudi la finestra e aggiungila tramite l'apposito bottone 'Aggiungi auto'"])},
    "searchCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca auto"])},
    "errorCars": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Non puoi prenotare più di ", _interpolate(_named("n")), " auto"])},
    "addNewCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiunti una nuova auto"])},
    "addNewUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi un nuovo utente"])},
    "nameCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificativo auto"])},
    "licenseCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targa auto"])},
    "labelDateTimeCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e ora"])},
    "errorNoUsersBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il numero di posti prenotati non corrisponde al numero di persone selezionate"])},
    "detailLabelRequestCars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettaglio ingresso auto"])},
    "uploadMop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E' obbligatorio allegare il MOP"])},
    "coworkingAccessDisabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prenotazione della CoWorking Room è temporaneamente disabilitata a causa di interventi nella sala."])},
    "timeConfirmationModal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apertura automatica Remote Hands 24/7"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In associazione alla richiesta di ingresso corrente sarà aperta la Remote Hands  “accompagnamento verso e da spazi Cliente di visitatori/consulenti con eventuale osservazione durante le attività” per il rack condiviso selezionato"])},
      "checkboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confermando accetto che vengano applicate le condizioni contrattuali in essere."])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
      "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
    },
    "cars": {
      "col": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targa"])}
      },
      "deleteItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina"])},
      "noCars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun veicolo trovato."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si è verificato un errore durante il recupero dei veicoli."])}
    },
    "modalAccessDcRemove": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stai annullando l'operazione: scegli \"Continua\" per proseguire nell’inserimento dei dati oppure clicca \"Annulla richiesta\" per eliminare tutti i dati inseriti.\n\n\n\n\n\n"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli Annulla Richiesta, per eliminare tutti i dati inseriti."])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
      "btnRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla richiesta"])}
    },
    "modalAccessDcInfo": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione!"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti informiamo che hai selezionato utenti che possono accedere in Data Center senza l'apertura del ticket di ingresso."])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosegui nella compilazione del form se hai necessità di prenotare postazioni in sala CoWorking o riservare posti auto, altrimenti puoi annullare la richiesta."])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
      "btnRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi altri utenti"])}
    },
    "summary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convalida Ticket di ingresso"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leggi con attenzione i dettagli del ticket che stai aprendo"])},
      "titleRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riepilogo ingresso"])},
      "subTitleRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "datacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center selezionato"])},
      "typeAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingressi richiesti"])},
      "dateAndHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e ora dell’ingresso"])},
      "dateAndHourSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data, orario di ingresso e orario di uscita"])},
      "whatSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa devi fare e dove"])},
      "apparatisi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
      "apparatino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "warehousesi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
      "warehouseno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "apparatifalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
      "keysForRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiavi per accedere alla sala"])},
      "rackList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista sale e rack selezionati"])},
      "labelRackList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero Rack Selezionati"])},
      "whoSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista utenti per cui hai richiesto l’ingresso"])},
      "coworkingDeskTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sala CoWorking"])},
      "coworkingDeskSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "coworkingDeskBooked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di desk prenotati"])},
      "coworkingWhoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi deve entrare in sala CoWorking"])},
      "coworkingWhoSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista utenti per cui hai richiesto l’ingresso"])},
      "referenceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona di riferimento"])},
      "referenceSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla con attenzione i dati di riferimento inseriti"])},
      "refName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nominativo"])},
      "refEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "refPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma"])},
      "noteRoomsKeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo della richiesta"])},
      "RoomRacks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni su questo ingresso"])},
      "mopError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOP non trovato: Ti invitiamo a ricaricarlo."])}
    },
    "goEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai al riepilogo"])},
    "uploadAttach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allega il MOP"])},
    "uploadAttachSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ingresso alle Meet Me Room è consentito esclusivamente a seguito di specifica autorizzazione da parte di CDLAN. Per procedere allega il Method of Procedure debitamente compilato."])},
    "mop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOP"])},
    "downloadMop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il Template del MOP"])},
    "modalAccessDcSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta inoltrata con successo!"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua richiesta sarà disponibile a breve nell'area ticket."])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai all'area ticket"])},
      "btnRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova richiesta"])}
    },
    "modalAccessBioSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta inoltrata con successo!"])},
      "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceverai una email di conferma della tua richiesta."])},
      "btnContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai all'area ticket"])},
      "btnRemove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova richiesta"])}
    },
    "UsersTitleBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti per i quali si sta facendo la prenotazione auto"])},
    "UsersParaphBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli ingressi al DC sono controllati, se ci sono altri utenti con te assicurati che possano accedere al DC o alla sala di coworking."])},
    "removeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca per rimuovere"])},
    "usersWithBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti con ingresso biometrico"])},
    "errorCarsExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di targa già esistente."])},
    "errorNoCars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi selezionare almeno una vettura"])},
    "cardOneText7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi l’ingresso in C21, prenota il tuo spazio nella CoWorking Room o riserva un posto auto"])},
    "cardOneText4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi l’ingresso in E100"])},
    "subTitleAccessType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "ticketList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKETS"])},
    "accessDcList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INGRESSI LIBERI"])},
    "InfoAccessCarBio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KKSe nella lista non trovi gli utenti è necessario aprire un ticket di ingresso per conto terzi accedendo alla sezione \"INGRESSO SALA COWORKING\" o \"INGRESSO SALE DATA CENTER\" proseguendo nella compilazione del form"])},
    "apparatusLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparati"])},
    "keysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiavi sala"])},
    "keysLabelMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente trovato. Chiudi la finestra e aggiungilo tramite l'apposito bottone 'Nuovo Utente'"])},
    "modalUsersSubmit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi i selezionati"])},
    "carSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto selezionate"])},
    "titleUsersCoWorking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenotazione CoWorking Room"])},
    "accessInDC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INGRESSO IN DC"])},
    "accessInCoworking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INGRESSO IN COWORKING"])},
    "btnAddressBook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrica"])},
    "addressBook": {
      "tab1Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti"])},
      "tab2Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veicoli"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubrica"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azienda"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])}
      },
      "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente in rubrica"])},
      "deleteItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Clicca qui per eliminare ", _interpolate(_named("name")), ". Ricorda che potrai re-inserirlo in fase di apertura ticket di ingresso."])},
      "deleteCarItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Clicca qui per eliminare ", _interpolate(_named("name")), ". Ricorda che potrai re-inserirla in fase di apertura ticket di ingresso."])}
    },
    "historicalAccessDc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico ingressi in DC"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e orario"])},
        "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora di ingresso"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora di uscita"])}
      },
      "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun ingresso registrato"])},
      "filterInOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "filter": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto"])},
        "in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INGRESSO"])},
        "out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USCITA"])}
      }
    },
    "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devi accedere al deposito?"])},
    "warehouseLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso al deposito"])},
    "warehouseYes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SI"])},
    "warehouseNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
    "modalWarehouse": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso al deposito"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per accedere al deposito, compila il modulo per aprire il ticket per Remote Hands, al quale verrai reindirizzato una volta inoltrata la richiesta. Se la tua operazione in deposito dura meno di 15 minuti, sarà gratuita."])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho letto"])}
    },
    "modalDeleteUserCar": {
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELIMINA"])},
      "user": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina contatto"])},
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vuoi eliminare il contatto ", _interpolate(_named("name")), "?<br /><br />Ricorda che potrai sempre reinserirlo in fase di creazione del ticket di accesso."])}
      },
      "car": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elimina vettura"])},
        "body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vuoi eliminare la vettura ", _interpolate(_named("name")), " con targa ", _interpolate(_named("license")), "?<br /><br />Ricorda che potrai sempre reinserirla in fase di creazione del ticket di accesso."])}
      }
    }
  },
  "modalUserDisabled": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente disabilitato"])},
    "sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account risulta disabilitato."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gentile Cliente,\n\nL'accesso al portale è temporaneamente sospeso per motivi amministrativi.\n\nPer ripristinare l'accesso, contatta il referente amministrativo della tua società."])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok, ho capito"])}
  },
  "modalSolutions": {
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi"])}
  },
  "tabs": {
    "ticketListLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue richieste"])},
    "listLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le tue soluzioni"])}
  },
  "noPermission": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesso Negato"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai i permessi per accedere ai contenuti di questa pagina. Contatta l'amministratore del portale."])},
    "returnHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla Home"])}
  },
  "reports": {
    "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun risultato trovato."])},
    "filter": {
      "dateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data inizio"])},
      "dateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data fine"])},
      "isRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" è un campo obbligatorio"])}
    },
    "virtualization": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtualizzazione"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Dominio"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero VM"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vCPU"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MB RAM"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GB Storage Primario"])},
        "colSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GB Storage Secondario"])}
      },
      "list": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello"])},
        "parentdomainid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID dominio padre"])},
        "parentdomainname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome dominio padre"])},
        "haschild": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha figli"])},
        "path": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percorso"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creato"])},
        "vmlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite VM"])},
        "vmtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale VM"])},
        "vmavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VM disponibili"])},
        "iplimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite IP"])},
        "iptotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale IP"])},
        "ipavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP disponibili"])},
        "volumelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite volume"])},
        "volumetotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale volume"])},
        "volumeavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume disponibile"])},
        "snapshotlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite snapshot"])},
        "snapshottotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale snapshot"])},
        "snapshotavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot disponibili"])},
        "templatelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite template"])},
        "templatetotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale template"])},
        "templateavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template disponibili"])},
        "projectlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite progetto"])},
        "projecttotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale progetto"])},
        "projectavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progetti disponibili"])},
        "networklimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite rete"])},
        "networktotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale rete"])},
        "networkavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rete disponibile"])},
        "vpclimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite VPC"])},
        "vpctotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale VPC"])},
        "vpcavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VPC disponibili"])},
        "cpulimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite CPU"])},
        "cputotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale CPU"])},
        "cpuavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU disponibili"])},
        "memorylimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite memoria"])},
        "memorytotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale memoria"])},
        "memoryavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memoria disponibile"])},
        "primarystoragelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite storage primario"])},
        "primarystoragetotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale storage primario"])},
        "primarystorageavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage primario disponibile"])},
        "secondarystoragelimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite storage secondario"])},
        "secondarystoragetotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale storage secondario"])},
        "secondarystorageavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage secondario disponibile"])},
        "hasannotations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha annotazioni"])}
      }
    },
    "maintenanceNotifications": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche di manutenzione"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Notifica"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Inizio Manutenzione"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Fine Manutenzione"])}
      }
    },
    "historical": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storico tickets"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di ticket"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data apertura"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data ultimo aggiornamento"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente"])}
      },
      "type": {
        "Access request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresso DC"])},
        "Incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
        "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consegne"])},
        "Remote hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote hands"])},
        "Cross connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross connect"])},
        "Change request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change request"])},
        "Feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])}
      },
      "closeModal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
      "modal": {
        "access": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket di ingresso al DC"])},
          "data_arrivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data arrivo"])},
          "data_uscita": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data uscita"])},
          "meeting_room_pax_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orari CoWorking"])},
          "meeting_room_paxtime_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dalle ore ", _interpolate(_named("from")), " alle ore ", _interpolate(_named("to"))])},
          "meeting_room_pax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di desk in sala CoWorking prenotati"])},
          "traka_rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiavi traka"])},
          "traka_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo delle chiavi"])},
          "data_richiesta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data apertura ticket"])},
          "dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
          "apparati": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparati"])},
          "messaggio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio"])}
        },
        "incident": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket di incident"])},
          "area": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Area"])},
          "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sala"])},
          "serialnumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serialnumber"])},
          "transito_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transito IP"])},
          "id_apparato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparato"])},
          "tipologia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia"])}
        },
        "rh": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket di remote hands"])},
          "rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
          "liv_tecnico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livello tecnico"])},
          "liv_urgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo intervento richiesto"])},
          "po_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento Ordine"])},
          "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
          "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])}
        },
        "delivery": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket di Consegna"])},
          "dc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Center"])},
          "data_arrivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data arrivo"])},
          "spedita_da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spedito da"])},
          "bancali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bancali"])},
          "numero_pacchi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero pacchi"])},
          "peso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso (kg)"])},
          "descrizione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
          "maggiori_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori info"])}
        },
        "xc": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket di cross connect"])},
          "attivazione": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attivazione (€)"])},
          "canone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canone (€)"])},
          "panel_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack unit"])},
          "port": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patch panel verso MMR"])},
          "po_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento Ordine"])},
          "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sala"])},
          "rack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rack"])},
          "redundancy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ridondanza"])},
          "redundancyCta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca per visualizzare"])},
          "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio"])},
          "wing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ala"])},
          "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piano"])},
          "muffola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muffola"])},
          "loa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA"])},
          "noLoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riceverai la LOA alla chiusura del ticket"])}
        },
        "cr": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticket di change request"])}
        },
        "ticket_esteso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di ticket"])},
        "messaggio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio"])},
        "data_apertura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data apertura"])},
        "customer_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome e cognome referente"])},
        "email_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email referente"])},
        "telefono_ref": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefono referente"])}
      },
      "moreTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta lo storico completo <a href='https://portal.cdlan.it/' target='_blank'>cliccando qui <span class='material-icons'>open_in_new</span></a>"])}
    },
    "voip": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiamante"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiamato"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo ingresso"])},
        "colSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e ora"])},
        "colSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinazione"])}
      },
      "bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conto"])},
      "billDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona"])}
    },
    "fax": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fax"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittente"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagine"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durata"])},
        "colSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo pagina (€)"])},
        "colSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale (€)"])}
      }
    },
    "current": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumi energetici"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica"])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mese di riferimento"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "month": {
        "01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennaio"])},
        "02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Febbraio"])},
        "03": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marzo"])},
        "04": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprile"])},
        "05": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggio"])},
        "06": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giugno"])},
        "07": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luglio"])},
        "08": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agosto"])},
        "09": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settembre"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottobre"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dicembre"])}
      },
      "titleLegend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrente utilizzata colocation"])},
      "textLegend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al Canone mensile del servizio di Colocation vengono aggiunte, per ciascun rack, le seguenti voci mensili in Euro: <ul><li><u>Corrente Utilizzata</u> (UEC) = <strong>Nr. A</strong> * (<strong>PUN medio mensile</strong> * 0,368 + 29,256) dove<ul><li><strong>Nr. A</strong> è il numero degli Ampere calcolato sul 97° percentile delle rilevazioni orarie dell’utilizzo di corrente arrotondato all’intero superiore;</li><li><strong>il PUN medio mensile</strong> (Prezzo Unico Nazionale) relativo al mese di riferimento viene rilevato sul sito del Gestore dei Mercati Energetici (GME).</li></ul></li><li><u>Ampere Eccedenti:</u> qualora la Corrente Utilizzata (UEC) superi quella relativa alla Potenza Allocata contrattualizzata, verrà addebitato l’importo di 50€ per ogni A eccedente.</li></ul>"])},
      "titleLegendExel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legenda"])},
      "typeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di file"])},
      "ConsumiEnergiaColocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumi energetici"])},
      "ConsumiEnergiaLegend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legenda"])},
      "ConsumiEnergiaDatacenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Data Center"])},
      "ConsumiEnergiaIsland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Isola"])},
      "ConsumiEnergiaRackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice Rack"])},
      "ConsumiEnergiaRackName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Rack"])},
      "ConsumiEnergiaAmpere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ampere fatturati nel mese"])},
      "ConsumiEnergiaSocketId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice della presa"])},
      "ConsumiEnergiaMagnetotermico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia"])},
      "ConsumiEnergiaDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e ora rilevazione"])}
    },
    "paidInterventions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventi a Pagamento"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di ticket"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referente"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data apertura"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riferimento Ordine"])},
        "colSix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di documento"])},
        "colSeven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero documento"])},
        "colEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data fattura"])},
        "colNine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo (€)"])}
      },
      "type": {
        "dc-remote-hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Hands"])},
        "dc-cross-connect-new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects"])},
        "dc-cross-connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Connects"])},
        "dc-incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incident"])},
        "incident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incident"])},
        "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistenza sistemistica"])}
      },
      "ATTIVAZIONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATTIVAZIONE"])},
      "CANONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANONE"])},
      "RATEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RATEO"])}
    },
    "cloud": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "col": {
        "colOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
        "colTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
        "colThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ore di utilizzo"])},
        "colFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo ora (€)"])},
        "colFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo totale (€)"])}
      }
    }
  },
  "actions": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca..."])},
    "text_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digita almeno 3 caratteri per la tua ricerca"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oppure"])},
    "searchTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])}
  },
  "noSolutionsServices": {
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contattaci"])},
    "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>Questo servizio è collegato ad una soluzione che non è attiva, non hai quindi accesso al contenuto della pagina.</span>"])},
    "colocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>Questa soluzione non è attiva e non hai accesso al contenuto di questa pagina.</span><br /><br /><span style='font-size: 20px'>Il nostro servizio di Colocation è basato su una infrastruttura solida e adatta alle tue esigenze. Proponiamo opzioni flessibili, dalle soluzioni Rack alle Private Suite, offrendo un servizio che è sinonimo di qualità, scalabilità e prestazioni all'avanguardia.</span>"])},
    "cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>Questa soluzione non è attiva e non hai accesso al contenuto di questa pagina.</span><br /><br /><span style='font-size: 20px'>La nostra infrastruttura Cloud è progettata per garantire flessibilità, scalabilità e sicurezza. Perfetta per virtualizzare le risorse, gestire i dati con soluzioni avanzate di BaaS e storage, offre protezione e continuità operativa per le aziende.</span>"])},
    "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>Questa soluzione non è attiva e non hai accesso al contenuto di questa pagina.</span><br /><br /><span style='font-size: 20px'>Disponiamo di soluzioni VoIP avanzate per comunicazioni in Cloud. Il nostro IP PBX si adatta alle esigenze delle aziende e consente la gestione dei servizi di telefonia e collaborazione attraverso una piattaforma Cloud facile da utilizzare.</span>"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>Questa soluzione non è attiva e non hai accesso al contenuto di questa pagina.</span><br /><br /><span style='font-size: 18px'>Offriamo tecnologie di connessione di ultima generazione, studiate per elevare la produttività e garantire connessioni veloci, stabili e con latenza ridotta.</span>"])},
    "crossConnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='font-size: 20px'>Questa soluzione può essere richiesta solo se hai soluzioni di Colocation attive.</span>"])}
  },
  "cloud": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud"])},
    "TBD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni disponibili a breve"])},
    "assetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo Soluzione"])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
    "tbyte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacità TByte"])},
    "types": {
      "hosting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting"])},
      "iaas_storage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - Quota storage"])},
      "iaas_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - Backup"])},
      "iaas_cloud_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - Account cloud"])},
      "iaas_resource_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - Resource pool"])},
      "iaas_vm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IaaS - VM"])}
    }
  },
  "voip": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VoIP"])},
    "TBD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni disponibili a breve"])},
    "assetType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo Soluzione"])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soluzione"])},
    "types": {
      "voip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voip"])}
    }
  },
  "billingPeriod": {
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensile <small class='detailTwo'>anticipata</small>"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bimestrale <small class='detailTwo'>anticipata</small>"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestrale <small class='detailTwo'>anticipata</small>"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadrimestrale <small class='detailTwo'>anticipata</small>"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semestrale <small class='detailTwo'>anticipata</small>"])},
    "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuale <small class='detailTwo'>anticipata</small>"])}
  }
}