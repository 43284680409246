<template>
  <div class="contentFormTicket">
    <div class="contentForm">
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--8-col">
          <h2 class="title">
            {{ $t("shipping.deliveriesTitleStep2") }}
          </h2>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="locationSelected">
            <p class="labelDestination">{{ $t("shipping.destination") }}</p>
            <p class="textDestination">
              {{ this.wizardData.target_datacenter }}
            </p>
            <p class="addressDestination">{{ this.wizardData.dc_address }}</p>
          </div>
        </div>
      </div>

      <div class="space"></div>

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input
              type="date"
              name="dateAccess"
              v-model="dateAccess"
              @change="submit"
              class="not-empty"
              :min="getDate()"
            />
            <label>{{ $t("shipping.dateAccess") }}*</label>
            <span
              class="error"
              v-if="this.v$.dateAccess.$invalid && this.v$.dateAccess.$dirty"
            >
              {{ $t("shipping.dateAccess") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input
              type="text"
              name="carrier"
              v-model="carrier"
              @change="submit"
              @input="label($event)"
              maxlength="100"
            />
            <label>{{ $t("shipping.carrier") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.carrier.$invalid &&
                this.v$.carrier.$dirty &&
                this.v$.carrier.required.$invalid
              "
            >
              {{ $t("shipping.carrier") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input
              type="text"
              name="shippingCode"
              v-model="shippingCode"
              @change="submit"
              @input="label($event)"
              maxlength="100"
            />
            <label>{{ $t("shipping.shippingCode") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.shippingCode.$invalid &&
                this.v$.shippingCode.$dirty &&
                this.v$.shippingCode.required.$invalid
              "
            >
              {{ $t("shipping.shippingCode") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
      </div>

      <hr />

      <!-- <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col"><span class="labelTwo">{{ $t('shipping.packagesShipping') }}</span></div>
        <div class="mdl-cell mdl-cell--4-col">
          <p class="labelTwo" style="text-align: center; margin-bottom: 0;">{{ $t('shipping.pallets') }}</p>
          <div class="setQty">
            <div class="icon"><span class="material-icons" @click="ServiceQty('pallets', 'remove')" id="removePallets">remove</span></div>
            <div class="valueQty">{{ this.pallets }}</div>
            <div class="icon"><span class="material-icons" @click="ServiceQty('pallets', 'add')" id="addPallets">add</span></div>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <p class="labelTwo" style="text-align: center; margin-bottom: 0;">{{ $t('shipping.packages') }}*</p>
          <div class="setQty">
            <div class="icon"><span class="material-icons" @click="ServiceQty('packages', 'remove')" id="removePackages">remove</span></div>
            <div class="valueQty">{{ this.packages }}</div>
            <div class="icon"><span class="material-icons" @click="ServiceQty('packages', 'add')" id="addPackages">add</span></div>
          </div>
          <p class="error" v-if="(this.v$.packages.$invalid && this.v$.packages.$dirty)" style="text-align: center">
            {{ $t('shipping.packages') }} {{ $t('checkCoverage.isRequired') }}
          </p>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <p class="labelTwo" style="text-align: center; margin-bottom: 0;">{{ $t('shipping.weight') }}*</p>
          <div class="setQty">
            <div class="icon"><span class="material-icons" @click="ServiceQty('weight', 'remove')" id="removeWeight">remove</span></div>
            <div class="valueQty">{{ this.weight }}</div>
            <div class="icon"><span class="material-icons" @click="ServiceQty('weight', 'add')" id="addWeight">add</span></div>
          </div>
          <p class="error" v-if="(this.v$.weight.$invalid && this.v$.weight.$dirty)" style="text-align: center">
            {{ $t('shipping.weight') }} {{ $t('checkCoverage.isRequired') }}
          </p>
        </div>
      </div> -->

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("shipping.packagesShipping") }}</span>
        </div>
        <div class="mdl-cell mdl-cell--3-col">
          <div class="cdl-input">
            <input
              type="text"
              name="pallets"
              v-model="pallets"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("shipping.pallets") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.pallets.$invalid &&
                this.v$.pallets.$dirty &&
                this.v$.pallets.minValueValue.$invalid
              "
            >
              {{ $t("shipping.minValueValue", { n: 0 }) }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.pallets.$invalid &&
                this.v$.pallets.$dirty &&
                this.v$.pallets.integer.$invalid
              "
            >
              {{ $t("shipping.onlyInteger") }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.pallets.$invalid &&
                this.v$.pallets.$dirty &&
                this.v$.pallets.required.$invalid
              "
            >
              {{ $t("shipping.pallets") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--3-col">
          <div class="cdl-input">
            <input
              type="text"
              name="packages"
              v-model="packages"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("shipping.packages") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.packages.$invalid &&
                this.v$.packages.$dirty &&
                this.v$.packages.minValueValue.$invalid
              "
            >
              {{ $t("shipping.minValueValue", { n: 1 }) }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.packages.$invalid &&
                this.v$.packages.$dirty &&
                this.v$.packages.integer.$invalid
              "
            >
              {{ $t("shipping.onlyInteger") }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.packages.$invalid &&
                this.v$.packages.$dirty &&
                this.v$.packages.required.$invalid
              "
            >
              {{ $t("shipping.packages") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--3-col">
          <div class="cdl-input">
            <input
              type="text"
              name="weight"
              v-model="weight"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("shipping.weight") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.weight.$invalid &&
                this.v$.weight.$dirty &&
                this.v$.weight.minValueValue.$invalid
              "
            >
              {{ $t("shipping.minValueValue", { n: 1 }) }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.weight.$invalid &&
                this.v$.weight.$dirty &&
                this.v$.weight.integer.$invalid
              "
            >
              {{ $t("shipping.onlyInteger") }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.weight.$invalid &&
                this.v$.weight.$dirty &&
                this.v$.weight.required.$invalid
              "
            >
              {{ $t("shipping.weight") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--3-col">
          <div class="cdl-input">
            <input
              type="text"
              name="volume"
              v-model="volume"
              @change="submit"
              @input="label($event)"
            />
            <label v-html="$t('shipping.volume')"></label>
          </div>
        </div>
      </div>

      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <p class="labelTwo" style="margin-bottom: 0">
            {{ $t("shipping.description") }}
          </p>
          <br /><br />
          <div class="cdl-textarea">
            <textarea
              maxlength="255"
              name="description"
              id="textareaOne"
              v-model="description"
              @input="label($event)"
              @change="submit"
            ></textarea>
            <label>{{ $t("shipping.descriptionInput") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.description.$invalid &&
                this.v$.description.$dirty &&
                this.v$.description.required.$invalid
              "
            >
              {{ $t("shipping.description") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <br /><br />
          <div class="cdl-textarea">
            <textarea
              maxlength="255"
              name="description"
              id="textareaTwo"
              v-model="moreInfo"
              @input="label($event)"
              @change="submit"
            ></textarea>
            <label>{{ $t("shipping.moreInfoInput") }}</label>
          </div>
        </div>
      </div>

      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <p class="labelTwo" style="margin-bottom: 0">
            {{ $t("shipping.uploadAttach") }}
          </p>
          <span class="subTitleAttach">{{
            $t("shipping.uploadAttachSubTitle", {
              total: this.nAttach,
              size: getLimitSizeAttach(),
            })
          }}</span>
          <br />
          <span class="error marginLeft8" v-if="errorAttach">
            {{ $t("shipping.attachFormat", { format: getFormatNameAttach() }) }}
          </span>
          <span class="error marginLeft8" v-if="errorAttachSize">
            {{ $t("shipping.attachSize", { size: getLimitSizeAttach() }) }}
          </span>
          <br />
          <br />
        </div>
        <div class="mdl-cell mdl-cell--12-col listAttach">
          <div
            :class="{ hover: this.attach.length == 0 || this.attach[0] }"
            v-if="this.nAttach >= 1"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.attach.length == 0"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.attach[0]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.attach[0]"
              @click="removeAttach(0)"
              >cancel</span
            >
            <p v-if="this.attach[0]" class="fileName">
              {{ this.attach[0].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 0)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
          <div
            :class="{ hover: this.attach[0] || this.attach[1] }"
            v-if="this.nAttach >= 2"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.attach[0] && !this.attach[1]"
            />
            <img
              src="@/assets/img/shipping/no_attach.png"
              alt="attach"
              v-if="!this.attach[0] && !this.attach[1]"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.attach[1]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.attach[1]"
              @click="removeAttach(1)"
              >cancel</span
            >
            <p v-if="this.attach[1]" class="fileName">
              {{ this.attach[1].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 1)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
          <div
            :class="{ hover: this.attach[1] || this.attach[2] }"
            v-if="this.nAttach >= 3"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.attach[1] && !this.attach[2]"
            />
            <img
              src="@/assets/img/shipping/no_attach.png"
              alt="attach"
              v-if="!this.attach[1] && !this.attach[2]"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.attach[2]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.attach[2]"
              @click="removeAttach(2)"
              >cancel</span
            >
            <p v-if="this.attach[2]" class="fileName">
              {{ this.attach[2].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 2)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
          <div
            :class="{ hover: this.attach[2] || this.attach[3] }"
            v-if="this.nAttach >= 4"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.attach[2] && !this.attach[3]"
            />
            <img
              src="@/assets/img/shipping/no_attach.png"
              alt="attach"
              v-if="!this.attach[2] && !this.attach[3]"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.attach[3]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.attach[3]"
              @click="removeAttach(3)"
              >cancel</span
            >
            <p v-if="this.attach[3]" class="fileName">
              {{ this.attach[3].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 3)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
          <div
            :class="{ hover: this.attach[3] || this.attach[4] }"
            v-if="this.nAttach >= 5"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.attach[3] && !this.attach[4]"
            />
            <img
              src="@/assets/img/shipping/no_attach.png"
              alt="attach"
              v-if="!this.attach[3] && !this.attach[4]"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.attach[4]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.attach[4]"
              @click="removeAttach(4)"
              >cancel</span
            >
            <p v-if="this.attach[4]" class="fileName">
              {{ this.attach[4].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 4)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
        </div>
      </div>
      <br /><br />

      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col numericList">
          <p>{{ $t("shipping.informative.shipping.title") }}</p>
          <ul>
            <ol>
              <li v-for="point in 5" :key="point" v-html="$t('shipping.informative.shipping.lists.point' + point)"></li>
            </ol>
          </ul>
        </div>
      </div>

      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <div class="contentConditions">
            <p class="centered-content">
              <!-- Add a new class for centering -->
              <input
                type="checkbox"
                @change="check()"
                class="check"
                style="width: 20px; height: 20px"
              />
              &nbsp;
              <span class="textConditions" style="position: relative; top: -5px;">{{
                $t("shipping.informative.shipping.confirm")
              }}</span>
            </p>
            <p
              class="error"
              v-if="this.v$.conditions.$invalid && this.v$.conditions.$dirty"
            >
              {{ $t("checkCoverage.isRequired") }}
            </p>
          </div>
        </div>
      </div>

      <div class="cdlContentButtonDouble">
        <div class="cdlButtonSingle green width170" @click="goBack()">
          {{ $t("checkCoverage.stepsButtonTwo") }}
        </div>
        <div
          class="cdlButtonSingle green width170"
          id="nextStep"
          @click="goNext()"
        >
          {{ $t("shipping.stepsButtonOne") }}
        </div>
      </div>

      <p class="deleteOrder" @click="cancelOrder()">
        {{ $t("shipping.deleteTicket") }}
      </p>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, integer, minValue } from "@vuelidate/validators";

export default {
  name: "MyInfoShipping",
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      dateAccess: { required },
      carrier: { required },
      shippingCode: { required },
      pallets: { required, integer, minValueValue: minValue(0) },
      packages: { required, integer, minValueValue: minValue(1) },
      weight: { required, integer, minValueValue: minValue(1) },
      description: { required },
      conditions: { required }
    };
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateAccess: null,
      carrier: null,
      shippingCode: null,
      pallets: null,
      packages: null,
      weight: null,
      volume: null,
      description: null,
      moreInfo: null,
      attach: [],
      nAttach: 5,
      errorAttach: false,
      sizeAttach: 0,
      errorAttachSize: false,
      conditions: null
    };
  },
  methods: {
    getStep(type) {
      let step = this.stepData;
      if (type == "before") {
        return step - 1;
      }
      return step + 1;
    },
    async goNext() {
      const result = await this.v$.$validate();
      window.scrollTo(0, 0);
      if (result) {
        this.v$.$reset();
        this.$emit("goNext");
      }
      return;
    },
    goBack() {
      this.$emit("goBack");
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    submit() {
      this.$emit("update", {
        arrival_date: this.dateAccess,
        transporter: this.carrier,
        shipping_code: this.shippingCode,
        pallet_number: this.pallets,
        packages_number: this.packages,
        weight: this.weight,
        volume: this.volume,
        description: this.description
          ? this.description.replace(/<[^>]*>?/gm, "")
          : null,
        additional_information: this.moreInfo,
        attachments: this.attach,
      });
    },
    ServiceQty(type, what) {
      if (what == "add") {
        switch (type) {
          case "pallets":
            this.pallets++;
            break;
          case "packages":
            this.packages++;
            break;
          case "weight":
            this.weight++;
            break;
        }
      } else {
        switch (type) {
          case "pallets":
            if (this.pallets == 0) {
              return;
            }
            this.pallets--;
            break;
          case "packages":
            if (this.packages == 0) {
              return;
            }
            this.packages--;
            break;
          case "weight":
            if (this.weight == 0) {
              return;
            }
            this.weight--;
            break;
        }
      }
    },
    getDate() {
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth() + parseInt(1);
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      return date.getFullYear() + "-" + mm + "-" + dd;
    },
    cancelOrder() {
      this.$emit("close");
    },
    onFileChanged($event, index) {
      const target = $event.target;
      if (target && target.files) {
        if (
          this.getFormatAttach().search(target.files[0].type) < 0 ||
          target.files[0].type == ""
        ) {
          this.errorAttach = true;
          return;
        }
        this.errorAttach = false;
        this.errorAttachSize = false;

        this.sizeAttach += parseFloat(this.getSize(target.files[0].size));

        if (this.sizeAttach > this.getLimitSizeAttach()) {
          this.errorAttachSize = true;
          return;
        }

        this.attach[index] = target.files[0];
        this.submit();
      }
    },
    removeAttach(index) {
      this.attach.splice(index, 1);
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    getLimitSizeAttach() {
      return window.VUE_APP_LIMIT_SIZE_ATTACH;
    },
    getFormatAttach() {
      return window.VUE_APP_TYPE_ATTACH;
    },
    getFormatNameAttach() {
      return window.VUE_APP_TYPE_ATTACH_NAME.replaceAll("&nbsp;", " ");
    },
    check() {
      this.conditions = this.conditions == null ? true : null;
    },
  },
  activated() {
    window.scrollTo(0, 0);
    let he = {
      Accept: "application/json",
      Authorization: "Bearer " + this.$keycloak.idToken,
    };

    this.axios
      .get(
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "tickets/v1/max-uploads?ticket_type=Shipping",
        { headers: he }
      )
      .then((response) => {
        this.nAttach = response.data.max;
      });
  },
};
</script>
