import { computed, ref } from "vue";

// Create a function that takes dependencies as arguments
export const generateModalConfig = (
  props,
  closeModal,
  deleteOrderWizardNetwork,
  searchNewKit,
  deleteTicket,
  deleteTicketRh,
  isTypeModalForButton,
  goToOrdersPage,
  newTicketIncident
) => {
  const isDeleteOrderWizardNetwork = computed(
    () => props.typeModal === "deleteOrderWizardNetwork"
  );
  const isDeleteRemoteHandTicket = computed(
    () => props.typeModal === "deleteRemoteHandTicket"
  );
  const isConfirmOrder = computed(() => props.typeModal === "confirmOrder");
  const isKitNotFound = computed(() => props.typeModal === "KitNotFound");
  const isGeneric = computed(() => props.typeModal === "generic");
  const isConfirmShipping = computed(
    () =>
      props.typeModal === "confirmShipping" ||
      props.typeModal === "confirmShippingDeliveries" ||
      props.typeModal === "confirmShippingPickUpDeliveries"
  );
  const isConfirmShippingDeliveries = computed(
    () => props.typeModal === "confirmShippingDeliveries"
  );
  const isConfirmShippingPickUpDeliveries = computed(
    () => props.typeModal === "confirmShippingPickUpDeliveries"
  );
  const isConfirmRemoteHand = computed(
    () => props.typeModal === "confirmRemoteHand"
  );
  // const isDeleteIncidentTicket = computed(
  //   () => props.typeModal === "deleteIncidentTicket"
  // );
  const isConfirmIncident = computed(
    () => props.typeModal === "confirmIncident"
  );

  const greenButtonCondition = computed(() => {
    // This ensures only one condition can be true at a time
    if (isConfirmOrder.value) return "confirmOrder";
    if (isDeleteOrderWizardNetwork.value) return "deleteOrderWizardNetwork";
    if (isDeleteRemoteHandTicket.value) return "deleteRemoteHandTicket";
    if (isKitNotFound.value) return "KitNotFound";
    if (isGeneric.value) return "generic";
    if (isConfirmShipping.value) return "confirmShipping";
    if (isConfirmShippingDeliveries.value) return "confirmShippingDeliveries";
    if (isConfirmShippingPickUpDeliveries.value)
      return "confirmShippingPickUpDeliveries";
    if (isConfirmRemoteHand.value) return "confirmRemoteHand";
    if (isConfirmIncident.value) return "confirmIncident";
    return null; // Default case when no condition is met
  });

  return {
    generic: {
      btnClass: "cdlButton",
      clickHandler: closeModal,
      condition: isTypeModalForButton,
      labelKey: "cdlButton",
    },
    deleteOrderWizardNetwork: {
      btnClass: "cdlButton",
      clickHandler: closeModal,
      condition: computed(
        () => greenButtonCondition.value === "deleteOrderWizardNetwork"
      ),
      labelKey: "cdlButton",
    },
    confirmOrder: {
      btnClass: "cdlButtonGreen",
      clickHandler: goToOrdersPage,
      condition: computed(() => greenButtonCondition.value === "confirmOrder"),
      labelKey: "deleteOrder",
    },
    KitNotFound: {
      btnClass: "cdlButtonGreen",
      clickHandler: searchNewKit,
      condition: computed(() => greenButtonCondition.value === "KitNotFound"),
      labelKey: "cdlButton",
    },
    confirmShipping: {
      btnClass: "cdlButtonGreen",
      clickHandler: deleteTicket,
      condition: computed(
        () => greenButtonCondition.value === "confirmShipping"
      ),
      labelKey: "cdlButton",
    },
    deleteRemoteHandTicket: {
      btnClass: "cdlButton",
      clickHandler: closeModal,
      condition: computed(
        () => greenButtonCondition.value === "deleteRemoteHandTicket"
      ),
      labelKey: "cdlButton",
    },
    confirmRemoteHand: {
      btnClass: "cdlButtonGreen",
      clickHandler: deleteTicketRh,
      condition: computed(
        () => greenButtonCondition.value === "confirmRemoteHand"
      ),
      labelKey: "cdlButton",
    },
    confirmIncident: {
      btnClass: "cdlButtonGreen",
      clickHandler: newTicketIncident,
      condition: computed(
        () => greenButtonCondition.value === "confirmIncident"
      ),
      labelKey: "cdlButton",
    },
  };
};

export const generateModalParagraphsArray = (
  typeModal,
  deleteItemCart,
  deleteOrderWizardNetwork,
  deleteAllItemsCart,
  deleteOrderWizardNetworkTmp,
  closeModal,
  deleteTicket,
  deleteTicketRh,
  newTicketRh,
  newTicketIncident
) => {
  return ref([
    // All the config goes here
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: deleteItemCart, // You'll bind this to your actual function later
      condition: 'typeModal === "removeItemCart"',
      labelKey: "deleteOrder",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: deleteOrderWizardNetwork,
      condition: 'typeModal === "deleteOrderWizardNetwork"',
      labelKey: "deleteOrder",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: deleteAllItemsCart,
      condition: 'typeModal === "removeAllItems"',
      labelKey: "deleteOrder",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: deleteOrderWizardNetworkTmp,
      condition: 'typeModal === "deleteOrderWizardNetworkTmp"',
      labelKey: "deleteOrder",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      condition: 'props.typeModal === "confirmOrder" && false',
      labelKey: "deleteOrder",
    },
    {
      type: "SpecialDiv",
      condition: 'props.typeModal === "confirmOrder"',
      specialContent: "<br /><br /><br /><br /><br /><br />",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: closeModal,
      condition: 'typeModal === "KitNotFound"',
      labelKey: "deleteOrder",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: deleteTicket,
      condition:
        'typeModal === "deleteShippingTicket" || typeModal === "deleteShippingPickUpTicket"',
      labelKey: "deleteTicket",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: deleteTicket,
      condition: 'typeModal === "confirmShipping"',
      labelKey: "newTicket",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: deleteTicketRh,
      condition: 'typeModal === "deleteRemoteHandTicket"',
      labelKey: "deleteTicket",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: newTicketRh,
      condition: 'typeModal === "confirmRemoteHand"',
      labelKey: "newTicket",
    },
    {
      type: "ModalParagraph",
      paraClass: "deleteOrder",
      clickHandler: newTicketIncident,
      condition: 'typeModal === "confirmIncident"',
      labelKey: "newTicket",
    }
  ]);
};
