<template>
  <div class="contentFormTicket">
    <div class="contentForm">
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--8-col">
          <h2 class="title">{{ $t("shipping.deliveriesTitleStep5") }}</h2>
          <p class="subTitle" v-if="false">
            {{ $t("shipping.deliveriesSubTitleStep2") }}
          </p>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="locationSelected">
            <p class="labelDestination">{{ $t("shipping.destination") }}</p>
            <p class="textDestination">
              {{ this.wizardData.target_datacenter }}
            </p>
            <p class="addressDestination">{{ this.wizardData.dc_address }}</p>
          </div>
        </div>
      </div>

      <div class="space"></div>

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("shipping.infoShippingPickUp") }}</span>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input
              type="date"
              name="dateAccess"
              v-model="dateAccess"
              @change="submit"
              class="not-empty"
              :min="getDate()"
            />
            <label>{{ $t("shipping.dateAccessPickUp") }}*</label>
            <span
              class="error"
              v-if="this.v$.dateAccess.$invalid && this.v$.dateAccess.$dirty"
            >
              {{ $t("shipping.dateAccess") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input
              type="text"
              name="carrier"
              v-model="carrier"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("shipping.carrier") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.carrier.$invalid &&
                this.v$.carrier.$dirty &&
                this.v$.carrier.required.$invalid
              "
            >
              {{ $t("shipping.carrier") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input
              type="text"
              name="shippingCode"
              v-model="shippingCode"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("shipping.shippingCode") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.shippingCode.$invalid &&
                this.v$.shippingCode.$dirty &&
                this.v$.shippingCode.required.$invalid
              "
            >
              {{ $t("shipping.shippingCode") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
      </div>

      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("shipping.packagesShipping") }}</span>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input
              type="text"
              name="pallets"
              v-model="pallets"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("shipping.pallets") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.pallets.$invalid &&
                this.v$.pallets.$dirty &&
                this.v$.pallets.minValueValue.$invalid
              "
            >
              {{ $t("shipping.minValueValue", { n: 0 }) }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.pallets.$invalid &&
                this.v$.pallets.$dirty &&
                this.v$.pallets.integer.$invalid
              "
            >
              {{ $t("shipping.onlyInteger") }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.pallets.$invalid &&
                this.v$.pallets.$dirty &&
                this.v$.pallets.required.$invalid
              "
            >
              {{ $t("shipping.pallets") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input
              type="text"
              name="packages"
              v-model="packages"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("shipping.packages") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.packages.$invalid &&
                this.v$.packages.$dirty &&
                this.v$.packages.minValueValue.$invalid
              "
            >
              {{ $t("shipping.minValueValue", { n: 1 }) }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.packages.$invalid &&
                this.v$.packages.$dirty &&
                this.v$.packages.integer.$invalid
              "
            >
              {{ $t("shipping.onlyInteger") }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.packages.$invalid &&
                this.v$.packages.$dirty &&
                this.v$.packages.required.$invalid
              "
            >
              {{ $t("shipping.packages") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <div class="cdl-input">
            <input
              type="text"
              name="weight"
              v-model="weight"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("shipping.weight") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.weight.$invalid &&
                this.v$.weight.$dirty &&
                this.v$.weight.minValueValue.$invalid
              "
            >
              {{ $t("shipping.minValueValue", { n: 1 }) }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.weight.$invalid &&
                this.v$.weight.$dirty &&
                this.v$.weight.integer.$invalid
              "
            >
              {{ $t("shipping.onlyInteger") }}
            </span>
            <span
              class="error"
              v-else-if="
                this.v$.weight.$invalid &&
                this.v$.weight.$dirty &&
                this.v$.weight.required.$invalid
              "
            >
              {{ $t("shipping.weight") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
      </div>

      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <p class="labelTwo margin0">{{ $t("shipping.description") }}</p>
          <br /><br />
          <div class="cdl-textarea">
            <textarea
              maxlength="255"
              name="description"
              id="textareaOne"
              v-model="description"
              @input="label($event)"
              @change="submit"
            ></textarea>
            <label>{{ $t("shipping.descriptionInput") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.description.$invalid &&
                this.v$.description.$dirty &&
                this.v$.description.required.$invalid
              "
            >
              {{ $t("shipping.description") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
      </div>

      <hr />

      <div class="mdl-grid" style="align-items: baseline">
        <div class="mdl-cell mdl-cell--8-col">
          <p class="waybill">
            {{ $t("shipping.waybillOne") }}
          </p>
        </div>
        <div
          class="mdl-cell mdl-cell--4-col mdl-cell--1-col-tablet mdl-cell--6-col-phone"
          style="display: inline-flex; justify-content: left"
        >
          <div class="mdl-cell mdl-cell--1-col p-1">
            <p class="labelSwitch" :class="{ onNo: !this.switch }">
              {{ $t("shipping.no") }}
            </p>
          </div>
          <div
            class="mdl-cell mdl-cell--2-col mdl-cell--1-col-tablet mdl-cell--1-col-phone"
            v-if="sizeScreen"
            style="margin: 5px 15px"
          >
            <label class="switch" @change="changeSwitch()">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--1-col-tablet mdl-cell--1-col-phone"
            v-else
            style="margin: 5px 15px"
          >
            <label class="switch" @change="changeSwitch()">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div
            class="mdl-cell mdl-cell--1-col mdl-cell--1-col-tablet mdl-cell--1-col-phone"
          >
            <p class="labelSwitch" :class="{ onYes: this.switch }">
              {{ $t("shipping.yes") }}
            </p>
          </div>
        </div>

        <div
          class="mdl-cell mdl-cell--3-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          v-if="false"
        >
          <div class="cdl-input">
            <input
              type="text"
              name="n_ticket"
              v-model="n_ticket"
              @change="submit"
              @input="label($event)"
            />
            <label
              >{{ $t("shipping.n_ticket") }}
              {{ !this.switch ? "*" : "" }}</label
            >
            <span class="error" v-if="this.n_ticketRequired">
              {{ $t("shipping.n_ticket") }} {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <span class="subTitleAttach"
            >{{ $t("shipping.waybillTwo")
            }}<span v-if="!this.switch">*</span></span
          >
          <p class="error" v-if="errorWaybill">
            {{ $t("shipping.uploadAttachPickUp") }}
          </p>
          <p class="error" v-if="errorAttach[0]">
            {{ $t("shipping.attachFormat", { format: getFormatNameAttach() }) }}
          </p>
          <p class="error" v-if="errorAttachSize[0]">
            {{ $t("shipping.attachSize", { size: getLimitSizeAttach() }) }}
          </p>
        </div>
        <div class="mdl-cell mdl-cell--3-col listAttach listAttachPickUp" style="width: 100px; margin: 0;">
          <div
            :class="{ hover: this.attach.length == 0 || this.attach[0] }"
            v-if="this.nAttach >= 1"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.attach.length == 0"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.attach[0]"
            />
            <input
              type="file"
              @change="onFileChanged($event, 0)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
        </div>
        <div class="mdl-cell mdl-cell--9-col">
          <p v-if="this.attach[0]" class="fileName">
            {{ this.attach[0].name }}
          </p>
        </div>
      </div>

      <hr />

      <div class="mdl-grid" style="margin-top: 30px; align-items: center;">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="subTitleAttach">{{ $t("shipping.distinct") }}</span>
          <p class="error" v-if="errorAttachSize[1]">
            {{ $t("shipping.attachSize", { size: getLimitSizeAttach() }) }}
          </p>
          <p class="error" v-if="errorAttach[1]">
            {{ $t("shipping.attachFormat", { format: getFormatNameAttach() }) }}
          </p>
        </div>
        <div class="mdl-cell mdl-cell--3-col listAttach listAttachPickUp" style="width: 100px; margin: 0;">
          <div
            :class="{ hover: this.attach[0] || this.attach[1] }"
            v-if="this.nAttach >= 2"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.attach.length == 0 || this.attach.length == 1"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.attach[1]"
            />
            <input
              type="file"
              @change="onFileChanged($event, 1)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
        </div>
        <div class="mdl-cell mdl-cell--9-col">
          <p v-if="this.attach[1]" class="fileName">
            {{ this.attach[1].name }}
          </p>
        </div>
      </div>

      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col numericList">
          <p>{{ $t("shipping.informative.pickup.title") }}</p>
          <ul>
            <ol>
              <li v-for="point in 5" :key="point" v-html="$t('shipping.informative.pickup.lists.point' + point)"></li>
            </ol>
          </ul>
        </div>
      </div>

      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <div class="contentConditions">
            <p class="centered-content">
              <!-- Add a new class for centering -->
              <input
                type="checkbox"
                @change="check()"
                class="check"
                style="width: 20px; height: 20px"
              />
              &nbsp;
              <span class="textConditions" style="position: relative; top: -5px;">{{
                $t("shipping.informative.pickup.confirm")
              }}</span>
            </p>
            <p
              class="error"
              v-if="this.v$.conditions.$invalid && this.v$.conditions.$dirty"
            >
              {{ $t("checkCoverage.isRequired") }}
            </p>
          </div>
        </div>
      </div>

      <div class="cdlContentButtonDouble" style="margin-top: 60px">
        <div class="cdlButtonSingle green width170" @click="goBack()">
          {{ $t("checkCoverage.stepsButtonTwo") }}
        </div>
        <div class="cdlButtonSingle green width170" @click="goNext()">
          {{ $t("shipping.stepsButtonOne") }}
        </div>
      </div>

      <p class="deleteOrder" @click="cancelOrder()">
        {{ $t("shipping.deleteTicketPickUp") }}
      </p>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, integer, minValue } from "@vuelidate/validators";

export default {
  name: "MyInfoPickUp",
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      dateAccess: { required },
      carrier: { required },
      shippingCode: { required },
      pallets: { required, integer, minValueValue: minValue(0) },
      packages: { required, integer, minValueValue: minValue(1) },
      weight: { required, integer, minValueValue: minValue(1) },
      description: { required },
      conditions: { required }
    };
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateAccess: null,
      carrier: null,
      shippingCode: null,
      pallets: null,
      packages: null,
      weight: null,
      description: null,
      moreInfo: null,
      // n_ticket: null,
      // n_ticketRequired: false,
      attach: [],
      nAttach: 2,
      switch: false,
      sizeScreen: true,
      sizeAttach: 0,
      errorAttachSize: [false, false],
      errorWaybill: false,
      errorAttach: [false, false],
      conditions: null,
      waybill: "", // Lettera vettura
      distinct: "" // Distinta
    };
  },
  methods: {
    getStep(type) {
      let step = this.stepData;
      if (type == "before") {
        return step - 1;
      }
      return step + 1;
    },
    async goNext() {
      const result = await this.v$.$validate();
      // this.n_ticketRequired = false;
      window.scrollTo(0, 0);

      this.errorWaybill = false;

      if (!this.switch && !this.attach[0]) {
        this.errorWaybill = true;
      }

      if (result && !this.errorWaybill) {
        this.v$.$reset();
        this.submit();
        this.$emit("goNext");
      }
      return;
    },
    goBack() {
      this.$emit("goBack");
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    submit() {
      this.$emit("update", {
        arrival_date: this.dateAccess,
        transporter: this.carrier,
        shipping_code: this.shippingCode,
        description: this.description
          ? this.description.replace(/<[^>]*>?/gm, "")
          : null,
        additional_information: this.moreInfo,
        attachments: this.attach,
        pallet_number: this.pallets,
        packages_number: this.packages,
        weight: this.weight,
        waybill: this.waybill,
        distinct: this.distinct,
        switchWaybill: this.switch
      });
    },
    ServiceQty(type, what) {
      if (what == "add") {
        switch (type) {
          case "pallets":
            this.pallets++;
            break;
          case "packages":
            this.packages++;
            break;
          case "weight":
            this.weight++;
            break;
        }
      } else {
        switch (type) {
          case "pallets":
            if (this.pallets == 0) {
              return;
            }
            this.pallets--;
            break;
          case "packages":
            if (this.packages == 0) {
              return;
            }
            this.packages--;
            break;
          case "weight":
            if (this.weight == 0) {
              return;
            }
            this.weight--;
            break;
        }
      }
    },
    getDate() {
      let date = new Date();
      let dd = date.getDate();
      let mm = date.getMonth() + parseInt(1);
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      return date.getFullYear() + "-" + mm + "-" + dd;
    },
    cancelOrder() {
      this.$emit("close");
    },
    onFileChanged($event, index) {
      const target = $event.target;
      if (target && target.files) {
        this.errorAttachSize[index] = false;
        this.errorAttach[index] = false;

        this.sizeAttach += parseFloat(this.getSize(target.files[0].size));

        if (
          this.getFormatAttach().search(target.files[0].type) < 0 ||
          target.files[0].type == ""
        ) {
          this.errorAttach[index] = true;
          return;
        }

        if (this.sizeAttach > this.getLimitSizeAttach()) {
          this.errorAttachSize[index] = true;
          return;
        }

        if( index == 0 ) {
          this.waybill = target.files[0].name;
        }
        else {
          this.distinct = target.files[0].name;
        }

        this.attach[index] = target.files[0];
        this.submit();
      }
    },
    changeSwitch() {
      this.switch = !this.switch;
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    getLimitSizeAttach() {
      return window.VUE_APP_LIMIT_SIZE_ATTACH;
    },
    getFormatAttach() {
      return window.VUE_APP_TYPE_ATTACH;
    },
    getFormatNameAttach() {
      return window.VUE_APP_TYPE_ATTACH_NAME.replaceAll("&nbsp;", " ");
    },
    check() {
      this.conditions = this.conditions == null ? true : null;
    },
  },
  activated() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", () => {
      var w = window.innerWidth;
      this.sizeScreen = true;
      if (w < 1600) {
        this.sizeScreen = false;
      }
    });
  },
};
</script>
