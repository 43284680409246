import OrderCrossConnect from "@/pages/OrderCrossConnect.vue";
import CrossConnectValitation from "@/pages/CrossConnectValitation.vue";
import OrderCrossConnectIntra from "@/pages/OrderCrossConnectIntra.vue";
import CrossConnectValidationInfra from "@/pages/CrossConnectValidationInfra.vue";
import CrossConnectOrderSummary from "@/pages/CrossConnect/CrossConnectOrderSummary";
import CrossConnect from "@/pages/CrossConnect/CrossConnect.vue";
import CrossConnectR from "@/pages/CrossConnectRemove.vue";
import HelpRequestDetails from "@/pages/Help/HelpRequestDetails.vue";
import CartItemSummary from "@/pages/CrossConnect/CartItemSummary.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";
import GenericIncidentForm from "@/pages/Incident/GenericIncidentForm.vue";
import GenericChangeRequestForm from "@/pages/ChangeRequest/GenericChangeRequestForm.vue";

export function routesCrossConnects() {
  return [
    {
      path: "/crea-ordine-cross-connect",
      name: "OrderCrossConnect",
      component: OrderCrossConnect,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "cross-connects",
          },
          {
            name: "crossConnectNew",
            url: "",
          },
        ],
        indexRoute: "crea-ordine-cross-connect",
        permissions: ["cross_connect_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/crea-ordine-cross-connect-intra",
      name: "OrderCrossConnectIntra",
      component: OrderCrossConnectIntra,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "cross-connects",
          },
          {
            name: "crossConnectNew",
            url: "",
          },
        ],
        indexRoute: "crea-ordine-cross-connect-intra",
        permissions: ["cross_connect_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connects-attive/incident-generico/:asset_type",
      name: "CrossConnectGenericIncidentFormit",
      component: GenericIncidentForm,
      meta: {
        team_link: "incident_XC",
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "/cross-connects",
          },
          {
            name: "crossConnectList",
            url: "/cross-connects-attive",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "cross-connects",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connects/generic-incident/:asset_type",
      name: "CrossConnectGenericIncidentFormen",
      component: GenericIncidentForm,
      meta: {
        team_link: "incident_XC",
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "/cross-connects",
          },
          {
            name: "crossConnectList",
            url: "/cross-connects-attive",
          },
          {
            name: "incident",
            url: "",
          },
        ],
        indexRoute: "cross-connects",
        permissions: ["incident_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connects/change-request-generica/:asset_type",
      name: "CrossConnectGenericChangeRequestFormit",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "/cross-connects",
          },
          {
            name: "crossConnectList",
            url: "/cross-connects-attive",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "cross-connects-attive",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connects/generic-change-request/:asset_type",
      name: "CrossConnectGenericChangeRequestFormen",
      component: GenericChangeRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "/cross-connects",
          },
          {
            name: "crossConnectList",
            url: "/cross-connects-attive",
          },
          {
            name: "changeRequest",
            url: "",
          },
        ],
        indexRoute: "cross-connects",
        permissions: ["change_request_list"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/riepilogo-cross-connect-richiesta/:order_id/:item_id",
      name: "CrossConnectOrderSummaryit",
      component: CrossConnectOrderSummary,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "orders",
            url: "/soluzioni-richieste",
          },
          {
            name: "summaryOrderCrossConnect",
            url: "",
          },
        ],
        indexRoute: "",
        permissions: ["ordini", "cross_connect_nuovo", "cross_connect_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connect-request-summary/:order_id/:item_id",
      name: "CrossConnectOrderSummaryen",
      component: CrossConnectOrderSummary,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "orders",
            url: "/solutions-requested",
          },
          {
            name: "summaryOrderCrossConnect",
            url: "",
          },
        ],
        indexRoute: "",
        permissions: ["ordini", "cross_connect_nuovo", "cross_connect_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connects",
      name: "CrossConnect",
      component: CrossConnectR,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "",
          },
        ],
        indexRoute: "cross-connects",
        toTop: true,
        smoothScroll: true,
        permissions: ["cross_connect_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connects-attive",
      name: "CrossConnectListit",
      component: CrossConnect,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "/cross-connects",
          },
          {
            name: "crossConnectList",
            url: "",
          },
        ],
        indexRoute: "cross-connects-attive",
        toTop: true,
        smoothScroll: true,
        permissions: ["cross_connect_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-cross-connects",
      name: "CrossConnectListen",
      component: CrossConnect,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "/cross-connects",
          },
          {
            name: "crossConnectList",
            url: "",
          },
        ],
        indexRoute: "active-cross-connects",
        toTop: true,
        smoothScroll: true,
        permissions: ["cross_connect_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connects/validation",
      name: "CrossConnectValidation",
      component: CrossConnectValitation,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnectNew",
            url: "",
          },
        ],
        indexRoute: "cross-connects/validation",
        toTop: true,
        smoothScroll: true,
        permissions: ["cross_connect_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connects/validation-infra",
      name: "CrossConnectValidationInfra",
      component: CrossConnectValidationInfra,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnectNew",
            url: "",
          },
        ],
        indexRoute: "cross-connects",
        toTop: true,
        smoothScroll: true,
        permissions: ["cross_connect_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/active-cross-connects/detail/:id",
      name: "XConnSupportRequestDetailen",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "",
          },
          {
            name: "crossConnectList",
            url: "/active-cross-connects",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "active-cross-connects",
        permissions: ["cross_connect_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connects-attive/dettaglio/:id",
      name: "XConnSupportRequestDetailit",
      component: HelpRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "datacenter",
            url: "",
          },
          {
            name: "crossConnect",
            url: "/cross-connects",
          },
          {
            name: "crossConnectList",
            url: "/cross-connects-attive",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "cross-connects-attive",
        permissions: ["cross_connect_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/riepilogo-ordine-xconn/:cart_id",
      name: "CartItemSummaryit",
      component: CartItemSummary,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "crossConnect",
            url: "",
          },
          {
            name: "summaryOrderXC",
            url: "",
          },
        ],
        indexRoute: "",
        permissions: ["cross_connect_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/cross-connect-summary/:cart_id",
      name: "CartItemSummaryen",
      component: CartItemSummary,
      meta: {
        breadcrumbs: [
          {
            name: "solutions",
            url: "",
          },
          {
            name: "crossConnect",
            url: "",
          },
          {
            name: "summaryOrderXC",
            url: "",
          },
        ],
        indexRoute: "",
        permissions: ["cross_connect_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ];
}
