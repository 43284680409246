<template>
  <div id="OrderNetworkCoverageSummary">
    <div class="contentSummary">
      <span
        class="material-icons closeOrder"
        @click="cancelOrder()"
        v-if="false"
        >close</span
      >
      <div class="padding60">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <MyHeaderPage
              :title="$t(getTitle('title', kit))"
              :subtitle="$t(getTitle('subtitle', kit))"
              :calendar="true"
            />
          </div>
        </div>
        <SummaryBox
          style="margin-top: 3rem"
          :technology="
            kit?.category_specific_fields?.network_access_details.technology
          "
        >
          <template #itemDescription>
            {{ title }}
          </template>
          <template #itemDetails>
            <span class="labelTwo"
              >{{ $t("checkCoverage.detailLabelTech") }}

              <span class="textTwo"
                >{{ kit.technology }}
                {{
                  getDownUp(
                    kit.commercial_profile.downstream_bandwidth,
                    kit.commercial_profile.upstream_bandwidth
                  )
                }}</span
              ></span
            >
            <p>
              <span class="labelTwo"
                >Carrier
                <span class="textTwo">{{ kit.carrier }}</span>
              </span>
            </p>
          </template>
          <template #address>
            {{ getAddress(address) }}
          </template>
          <template #nrc
            ><span class="detailThree">{{
              getPriceKit(kit.base_price.nrc)
            }}</span></template
          >
          <template #mrc
            ><span class="detailThree">{{
              getPriceKit(kit.base_price.mrc)
            }}</span></template
          >
          <template #download
            ><small class="detailTwo">Mbps</small><br />
            <span class="detailThree">{{
              kit.commercial_profile.downstream_bandwidth
            }}</span></template
          >
          <template #upload>
            <small class="detailTwo">Mbps</small><br />
            <span class="detailThree">{{
              kit.commercial_profile.upstream_bandwidth
            }}</span></template
          >
          <template #min_duration>{{ kit.starter_subscription_time }}</template>
          <template #renewal>{{ kit.regular_subscription_time }}</template>
          <template #release_time>{{ kit.activation_time }}</template>
            <template #billing_period>
              <span class="detailThree" v-html='kit?.billing_period ? $t("billingPeriod." + kit.billing_period ) : $t("billingPeriod.3" )'></span>
            </template>
        </SummaryBox>
        <div class="mdl-grid">
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            style="width: auto"
          >
            <h2>{{ $t("checkCoverage.titleRelatedServices") }}</h2>
            <p class="subTitle">
              {{ $t("checkCoverageSummary.subTitleRelatedServicesSummary") }}
            </p>
          </div>
          <div class="mdl-cell mdl-cell--2-col" v-if="!kit.read">
            <p class="editInfo" @click="editInfo(1)">
              <span id="go-step-1" class="material-icons"> edit </span>
            </p>
          </div>
        </div>

        <div v-if="areTheyelatedServices()">
          <div
            class="mdl-grid contentRelatedServices"
            v-for="(product, keyProduct) in getAllRelatedServices()"
            :key="keyProduct"
          >
            <div class="mdl-cell mdl-cell--12-col">
              <h3>{{ getRelatedService(product, "name") }}</h3>
              <hr />
            </div>
            <div class="mdl-cell mdl-cell--4-col" style="padding-left: 15px">
              <div class="singleElm">
                <span class="title"
                  >{{ $t("checkCoverage.priceTantum") }}

                  <span class="value">
                    €{{ getRelatedService(product.products, "price", "nrc") }}
                  </span>
                  <p class="renewal" v-if="false">
                    {{ $t("checkCoverage.tantum") }}
                  </p></span
                >
              </div>
            </div>
            <div class="mdl-cell mdl-cell--8-col">
              <div class="singleElm">
                <span class="title"
                  >{{ $t("checkCoverage.priceMonth") }}
                  <span class="value">
                    €{{ getRelatedService(product.products, "price", "mrc") }}
                  </span></span
                >
                <p class="renewal" v-if="false">
                  {{ $t("checkCoverage.forMonth") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />

        <div class="mdl-grid">
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            style="width: auto"
          >
            <h2>{{ $t("checkCoverage.titleReferences") }}</h2>
            <p class="subTitle" v-if="!kit.read">
              {{ $t("checkCoverageSummary.subTitleReferencesSummary") }}
            </p>
          </div>
          <div class="mdl-cell mdl-cell--2-col">
            <p class="editInfo" @click="editInfo(2)" v-if="!kit.read">
              <span id="go-step-2" class="material-icons"> edit </span>
            </p>
          </div>
        </div>

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelOne">{{
              $t("checkCoverageSummary.customerDetails")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.customer") }}</span>
            <span class="value brMobile">{{ getEndCustomer() }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.customerEndPIva") }}</span>
            <span class="value brMobile">{{ getEndCustomerVat() }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{
              $t("checkCoverage.customerEndFiscalCode")
            }}</span>
            <span class="value brMobile">{{ getEndCustomerCf() }}</span>
          </div>
        </div>

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelOne">{{ $t("checkCoverage.techRef") }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.nameSurname") }}</span>
            <span class="value brMobile">{{
              getContact("technical", "name")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.mobile") }}</span>
            <span class="value brMobile">{{
              getContact("technical", "phone")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.email") }}</span>
            <span class="value brMobile">{{
              getContact("technical", "email")
            }}</span>
          </div>
        </div>

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelOne">{{
              $t("checkCoverage.administratorRef")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.nameSurname") }}</span>
            <span class="value brMobile">{{
              getContact("administrative", "name")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.mobile") }}</span>
            <span class="value brMobile">{{
              getContact("administrative", "phone")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.email") }}</span>
            <span class="value brMobile">{{
              getContact("administrative", "email")
            }}</span>
          </div>
        </div>

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelOne">{{ $t("checkCoverage.onSiteRef") }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.nameSurname") }}</span>
            <span class="value brMobile">{{
              getContact("onSite", "name")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.mobile") }}</span>
            <span class="value brMobile">{{
              getContact("onSite", "phone")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.email") }}</span>
            <span class="value brMobile">{{
              getContact("onSite", "email")
            }}</span>
          </div>
        </div>

        <div v-if="form?.client_po" class="mdl-grid">
          <div class="mdl-cell mdl-cell--4-col">
            <br /><br />
          </div>
          <div class="mdl-cell mdl-cell--12-col">
            <span class="title">{{ $t("checkCoverage.orderRef") }}</span>
            <span class="value brMobile">{{ getClientPo() }}</span>
          </div>
        </div>

        <hr />

        <div class="mdl-grid">
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            style="width: auto"
          >
            <h2>{{ $t("checkCoverage.titleAdditionalInformation") }}</h2>
            <p class="subTitle" v-if="false">
              {{ $t("checkCoverageSummary.subtitleAdditionalInformation") }}
            </p>
          </div>
          <div class="mdl-cell mdl-cell--2-col">
            <p class="editInfo" @click="editInfo(3)" v-if="!kit.read">
              <span id="go-step-4" class="material-icons"> edit </span>
            </p>
          </div>
        </div>

        <div class="contentRelatedServices additionalInfo">
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col">
              <h3>Account radius</h3>
            </div>
          </div>

          <div class="contentTableFlex">
            <div class="singleElm">
              <div><span class="title">Username</span></div>
              <div>
                <p class="value">{{ getUsername() }}</p>
              </div>
            </div>
            <div class="singleElm">
              <div><span class="title">Password</span></div>
              <div>
                <p class="value">{{ getPassword() }}</p>
              </div>
            </div>
            <div class="singleElm">
              <div>
                <span class="title">{{ $t("checkCoverage.noteRadius") }}</span>
              </div>
              <div>
                <p class="value">{{ getNoteRadius() }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="contentRelatedServices additionalInfo">
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col">
              <h3>{{ $t("checkCoverage.delivery") }}</h3>
            </div>
          </div>

          <div class="contentTableFlex">
            <div class="singleElm">
              <div>
                <span class="title">{{
                  $t("checkCoverage.buildingFloor")
                }}</span>
              </div>
              <div>
                <p class="value">{{ getFloor() }}</p>
              </div>
            </div>
            <div class="singleElm">
              <div>
                <span class="title">{{ $t("checkCoverage.timeSlot") }}</span>
              </div>
              <div>
                <p class="value">{{ getHours() }}</p>
              </div>
            </div>
            <div class="singleElm">
              <div>
                <span class="title">{{ $t("checkCoverage.noteInfo") }}</span>
              </div>
              <div>
                <p class="value">{{ getNoteInfo() }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="contentRelatedServices additionalInfo">
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col">
              <h3>{{ $t("checkCoverage.titleMigration") }}</h3>
            </div>
          </div>

          <div class="contentTableFlex">
            <div class="singleElm">
              <div>
                <span class="title">{{
                  $t("checkCoverage.migrationCode")
                }}</span>
              </div>
              <div>
                <p class="value">{{ getMigration() }}</p>
              </div>
            </div>
            <div class="singleElm">
              <div>
                <span class="title">{{
                  $t("checkCoverage.migrationNote")
                }}</span>
              </div>
              <div>
                <p class="value">{{ getNoteOrDetails() }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="contentRelatedServices additionalInfo">
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col">
              <h3>{{ $t("checkCoverage.technicalDetails") }}</h3>
            </div>
          </div>

          <div class="contentTableFlex">
            <div class="singleElm">
              <div>
                <span class="value" style="margin-left: 0">{{
                  getNotes()
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <hr />
        <!-- New Section with Grey Text -->
        <!-- <div class="mdl-grid info-section">
          <div class="mdl-cell mdl-cell--12-col info-container">
            <img :src="IconInfo" alt="Info Icon" class="info-icon-mobile" />
            <p class="info-text" v-html="$t('info-tec.info')"></p>
          </div>
        </div> -->
        <!-- <hr /> -->
        <div class="contentTotal">
          <p class="labelTotal">{{ $t("checkCoverage.total") }}</p>
          <p class="textTotal">{{ getTotal() }}€</p>
        </div>

        <div class="contentTotal">
          <p class="labelTotal">{{ $t("checkCoverage.totalMonths") }}</p>
          <p class="textTotal">{{ getTotalMonths() }}€</p>
        </div>
      </div>

      <div v-if="!kit.read">
        <div class="cdlContentButtonSingle" v-if="viewSubmit">
          <div
            id="submitCoverageSummary"
            class="cdlButtonSingle"
            @click="saveToCart(kit, address)"
          >
            {{ $t(getSubmit()) }}
          </div>
        </div>

        <div v-if="getIfView()">
          <p class="deleteOrder" @click="cancelOrder()" v-if="viewSubmit">
            {{ $t("checkCoverage.stepsButtonThree") }}
          </p>
        </div>
      </div>
      <div v-else>
        <div style="text-align: center; margin-bottom: 40px">
          <router-link :to="'/' + $t('nav.ordersUrl')">
            <button class="cdlButton">{{ $t("orders.backToOrders") }}</button>
          </router-link>
        </div>
      </div>

      <MyLoading :viewLoader="viewSubmit" type="" />
      <MyError
        :viewError="viewError"
        :message="$t('checkCoverageSummary.errorGeneric')"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
button.cdlButton {
  width: auto;
  padding: 20px 40px;
}
.info-section {
  display: flex;
  align-items: center;
}

.info-container {
  display: flex;
  align-items: flex-start;
}

.info-icon {
  margin-right: 12px;
  flex-shrink: 0;
  width: 2%;
}
.info-icon-mobile {
  margin-right: 12px;
  flex-shrink: 0;
}

.info-text {
  color: #8895a7;
  line-height: 1.5;
}
</style>

<script setup>
import { computed } from "vue";
import { useNetworkCoverageKitStore } from "@/stores/NetworkCoverageKitStore";
// import IconInfo from "@/assets/img/icon_info.png";

const kit = computed(() => {
  return useNetworkCoverageKitStore().kit;
});

const address = computed(() => {
  return useNetworkCoverageKitStore().address;
});

const title = computed(() => {
  return useNetworkCoverageKitStore().kit.title;
});
</script>

<script>
import MyLoading from "@/components/MyLoading.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/OrderNetworkCoverageSummary.scss";
import MyError from "@/components/MyError.vue";
import SummaryBox from "@/components/OrderNetworkCoverage/SummaryBox.vue";

export default {
  name: "OrderNetworkCoverageSummary",
  props: ["openModal"],
  components: {
    SummaryBox,
    MyHeaderPage,
    MyLoading,
  },
  data() {
    return {
      form: {},
      technical: {
        name: null,
        email: null,
        phone: null,
      },
      administrative: {
        name: null,
        email: null,
        phone: null,
      },
      onSite: {
        name: null,
        email: null,
        phone: null,
      },
      viewModal: false,
      viewSubmit: true,
      viewError: false,
      messageError: "",
      total: 0,
      totalMonths: 0,
    };
  },
  methods: {
    getTitle(type, kitObj) {
      if (type == "title") {
        if (kitObj.read) {
          return "checkCoverageSummary.titleRead";
        }
        if (this.form.cart_id) {
          return "checkCoverageSummary.titleEdit";
        }
        return "checkCoverageSummary.title";
      } else {
        if (kitObj.read) {
          return ""; //'checkCoverageSummary.titleRead'
        }
        if (this.form.cart_id) {
          return "checkCoverageSummary.subtitleEdit";
        }
        return "checkCoverageSummary.subtitle";
      }
    },
    getSubmit() {
      if (this.form.cart_id) {
        return "Aggiorna fornitura";
      }
      return "checkCoverageSummary.submit";
    },
    getIcon(technology) {
      let rls = this.$KitStyle(technology, "icon");
      return rls;
    },
    getClassKit(technology) {
      let rls = this.$KitStyle(technology, "class");
      return rls;
    },
    saveToCart(kit, address) {
      this.viewSubmit = false;

      let kit_id = 0;
      if (kit.id) {
        kit_id = kit.id;
      } else {
        kit_id = JSON.parse(localStorage.getItem("cdlOrderNetworkCoverage"))
          .form.kit_id;
      }

      this.form.kit_id = kit_id;

      this.form.related_products = [];
      if (this.form.relatedServices.length > 0) {
        this.form.relatedServices.forEach((elm) => {
          this.form.related_products.push({
            id: elm.products.id,
            quantity: elm.quantity ?? 1,
            group_name: elm.group_name,
          });
        });
      }

      if (
        this.form.end_customer.vat_id &&
        typeof this.form.end_customer.company_name != "string"
      ) {
        this.form.end_customer.company_name.vat_id =
          this.form.end_customer.vat_id;
      }

      if (
        this.form.end_customer.cf &&
        typeof this.form.end_customer.company_name != "string"
      ) {
        this.form.end_customer.company_name.cf = this.form.end_customer.cf;
      }

      if (typeof this.form.end_customer.company_name == "string") {
        let obj = {
          company_name: this.form.end_customer.company_name,
        };

        if (this.form.end_customer.cf) {
          obj.cf = this.form.end_customer.cf;
        }

        if (this.form.end_customer.vat_id) {
          obj.vat_id = this.form.end_customer.vat_id;
        }
        this.form.end_customer = obj;
      } else {
        if (!this.form.end_customer.cf) {
          delete this.form.end_customer.cf;
          if (typeof this.form.end_customer.company_name != "string") {
            delete this.form.end_customer.company_name.cf;
          }
        }

        if (!this.form.end_customer.vat_id) {
          delete this.form.end_customer.vat_id;
          if (typeof this.form.end_customer.company_name != "string") {
            delete this.form.end_customer.company_name.vat_id;
          }
        }

        this.form.end_customer = this.form.end_customer.company_name;
      }

      this.form.network_access_details.carrier = kit.carrier;

      if (!this.form.network_access_details.delivery_details.building_floor) {
        delete this.form.network_access_details.delivery_details;
      } else if (
        this.form.network_access_details.delivery_details.notes == null
      ) {
        delete this.form.network_access_details.delivery_details.notes;
      }

      if (
        this.form.network_access_details.delivery_details &&
        !this.form.network_access_details.delivery_details.time_slot
      ) {
        delete this.form.network_access_details.delivery_details.time_slot;
      }

      if (!this.form.network_access_details.olo2olo_migration.code) {
        delete this.form.network_access_details.olo2olo_migration;
      } else if (
        this.form.network_access_details.olo2olo_migration.notes == null
      ) {
        delete this.form.network_access_details.olo2olo_migration.notes;
      }

      if (!this.form.network_access_details.radius_account.username) {
        delete this.form.network_access_details.radius_account;
      } else if (
        this.form.network_access_details.radius_account.notes == null
      ) {
        delete this.form.network_access_details.radius_account.notes;
      }

      if (!this.form.notes) {
        delete this.form.notes;
      }

      if (!this.form.client_po) {
        delete this.form.client_po;
      }

      let myKit = JSON.parse(localStorage.getItem("cdlOrderKit"));

      this.form.network_access_details.commercial_profile_id =
        myKit.kit.commercial_profile.id;

      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      const self = this;

      if (this.form.cart_id) {
        this.form.id = this.form.cart_id;
        this.axios
          .put(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "sales/v1/cart-item-001/" +
              this.form.cart_id,
            this.form,
            { headers: he }
          )
          .then(() => {
            localStorage.removeItem("cdlOrderKit");
            localStorage.removeItem("cdlOrderKitAddress");
            localStorage.removeItem("cdlOrderNetworkCoverage");
            localStorage.removeItem("cdlOrderNetworkCoverageConditions");
            this.$UpdateCart();
            return this.$router.push({ name: "CartPage" + this.$i18n.locale });
          })
          .catch(function (err) {
            self.viewSubmit = true;
            self.viewError = true;
            self.messageError = err.response.data.message;
            setTimeout(() => {
              self.viewError = false;
            }, 5000);
          });
      } else {
        this.form.network_access_details.address.province = address.province;
        this.form.network_access_details.address.city = address.city;
        this.form.network_access_details.address.address = address.address;
        this.form.network_access_details.address.number =
          address.streetNumber ?? address.number;
        this.axios
          .post(
            this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              "sales/v1/cart-item-001",
            this.form,
            { headers: he }
          )
          .then(() => {
            localStorage.removeItem("cdlOrderKit");
            localStorage.removeItem("cdlOrderKitAddress");
            localStorage.removeItem("cdlOrderNetworkCoverage");
            localStorage.removeItem("cdlOrderNetworkCoverageConditions");
            this.$UpdateCart();
            return this.$router.push({ name: "CartPage" + this.$i18n.locale });
          })
          .catch(function (err) {
            self.viewSubmit = true;
            self.viewError = true;
            self.messageError = err.response.data.message;
            setTimeout(() => {
              self.viewError = false;
            }, 5000);
          });
      }
    },
    editInfo(goTo) {
      let recoveryValue = JSON.parse(
        localStorage.getItem("cdlOrderNetworkCoverage")
      );
      recoveryValue.step = goTo;
      localStorage.setItem(
        "cdlOrderNetworkCoverage",
        JSON.stringify(recoveryValue)
      );
      return this.$router.push({
        name: "OrderNetworkCoverage" + this.$i18n.locale,
      });
    },
    getNotes() {
      return this.form.notes ?? "-";
    },
    getPriceKit(price) {
      return this.$PriceClean(price ?? 0);
    },
    getRelatedService(type, what, typePrice = null) {
      if (what == "name") {
        let quantity = "";
        if (type.quantity) {
          quantity = "(x" + type.quantity + ")";
        }
        return type.products.title + " " + quantity;
      } else {
        if (typePrice == "nrc") {
          return this.$PriceClean(type.price.nrc ?? 0);
        }
        return this.$PriceClean(type.price.mrc ?? 0);
      }
    },
    areTheyelatedServices() {
      if (!this.form.relatedServices) {
        return false;
      }
      return true;
    },
    getAllRelatedServices() {
      return this.form.relatedServices;
    },
    getEndCustomer() {
      if (this.form.end_customer) {
        if (
          this.form.end_customer.company_name_new &&
          typeof this.form.end_customer.company_name != "string"
        ) {
          this.form.end_customer.company_name.company_name =
            this.form.end_customer.company_name_new;
        }
        if (this.form.end_customer.company_name.company_name) {
          return this.form.end_customer.company_name.company_name;
        }
        if (typeof this.form.end_customer.company_name == "string") {
          return this.form.end_customer.company_name;
        }
        return "-";
      }
    },
    getEndCustomerVat() {
      return this.form.end_customer
        ? this.form.end_customer.vat_id
          ? this.form.end_customer.vat_id
          : "-"
        : "-";
    },
    getEndCustomerCf() {
      return this.form.end_customer
        ? this.form.end_customer.cf
          ? this.form.end_customer.cf
          : "-"
        : "-";
    },
    getContact(type, what) {
      switch (type) {
        case "technical":
          switch (what) {
            case "name":
              return this.technical.name;
            case "phone":
              return this.technical.phone;
            case "email":
              return this.technical.email;
          }
          break;
        case "administrative":
          switch (what) {
            case "name":
              return this.administrative.name;
            case "phone":
              return this.administrative.phone;
            case "email":
              return this.administrative.email;
          }
          break;
        case "onSite":
          switch (what) {
            case "name":
              return this.onSite.name;
            case "phone":
              return this.onSite.phone;
            case "email":
              return this.onSite.email;
          }
          break;
      }
    },
    getUsername() {
      return this.form &&
        this.form.network_access_details &&
        this.form.network_access_details.radius_account &&
        this.form.network_access_details.radius_account.username
        ? this.form.network_access_details.radius_account.username
        : "-";
    },
    getPassword() {
      return this.form &&
        this.form.network_access_details &&
        this.form.network_access_details.radius_account &&
        this.form.network_access_details.radius_account.password
        ? this.form.network_access_details.radius_account.password
        : "-";
    },
    getNoteRadius() {
      return this.form &&
        this.form.network_access_details &&
        this.form.network_access_details.radius_account &&
        this.form.network_access_details.radius_account.notes
        ? this.form.network_access_details.radius_account.notes
        : "-";
    },
    getFloor() {
      return this.form &&
        this.form.network_access_details &&
        this.form.network_access_details.delivery_details &&
        this.form.network_access_details.delivery_details.building_floor
        ? this.form.network_access_details.delivery_details.building_floor
        : "-";
    },
    getHours() {
      return this.form.network_access_details &&
        this.form.network_access_details.delivery_details &&
        this.form.network_access_details.delivery_details.time_slot
        ? this.form.network_access_details.delivery_details.time_slot
        : "-";
    },
    getNoteInfo() {
      return this.form.network_access_details &&
        this.form.network_access_details.delivery_details &&
        this.form.network_access_details.delivery_details.notes
        ? this.form.network_access_details.delivery_details.notes
        : "-";
    },
    getMigration() {
      return this.form.network_access_details &&
        this.form.network_access_details.olo2olo_migration &&
        this.form.network_access_details.olo2olo_migration.code
        ? this.form.network_access_details.olo2olo_migration.code
        : "-";
    },
    getNoteOrDetails() {
      return this.form.network_access_details &&
        this.form.network_access_details.olo2olo_migration &&
        this.form.network_access_details.olo2olo_migration.notes
        ? this.form.network_access_details.olo2olo_migration.notes
        : "-";
    },
    cancelOrder() {
      window.scrollTo(0, 0);
      if (this.form.cart_id) {
        localStorage.removeItem("cdlOrderKit");
        localStorage.removeItem("cdlOrderKitAddress");
        localStorage.removeItem("cdlOrderNetworkCoverage");
        localStorage.removeItem("cdlOrderNetworkCoverageConditions");
        return this.$router.push({ name: "CartPage" + this.$i18n.locale });
      }

      this.$emit("openModal", { type: "deleteOrderWizardNetwork" });
    },
    getViewModal() {
      return this.viewModal;
    },
    getAddress(address) {
      return (
        address.address.toUpperCase() +
        " " +
        address.streetNumber +
        " " +
        address.city.toUpperCase() +
        " (" +
        address.province.toUpperCase() +
        ")"
      );
    },
    getTotal() {
      return this.$PriceClean(this.total);
    },
    getTotalMonths() {
      return this.$PriceClean(this.totalMonths);
    },
    getIfView() {
      return !this.form.cart_id;
    },
    getDownUp(download, upload) {
      let down = download + "Mb";
      if (download >= 1000) {
        down = download / 1000 + "Gb";
      }

      let up = upload + "Mb";
      if (upload >= 1000) {
        up = upload / 1000 + "Gb";
      }

      return down + "/" + up;
    },
    buildPage() {
      if (localStorage.getItem("cdlOrderNetworkCoverage")) {
        let recoveryValue = JSON.parse(
          localStorage.getItem("cdlOrderNetworkCoverage")
        );

        this.form = recoveryValue.form;
        this.form.isCompleted = true;
        this.form.contacts.forEach((elm) => {
          switch (elm.type) {
            case "technical":
              this.technical = elm;
              break;
            case "administrative":
              this.administrative = elm;
              break;
            case "on-site":
              this.onSite = elm;
              break;
          }
        });
        localStorage.setItem(
          "cdlOrderNetworkCoverage",
          JSON.stringify({ form: this.form, step: recoveryValue.step })
        );

        // if (this.form.cart_id || (localStorage.getItem("cdlOrderNetworkCoverageConditions") && localStorage.getItem("cdlOrderNetworkCoverageConditions") == 1)) {
        //   document.getElementsByClassName("buttonRadio")[0].click();
        // }

        const networkCoverageKitStore = useNetworkCoverageKitStore();

        this.total = parseFloat(networkCoverageKitStore.kit.base_price.nrc);
        this.totalMonths = parseFloat(
          networkCoverageKitStore.kit.base_price.mrc
        );

        if (this.form.relatedServices.length > 0) {
          this.form.relatedServices.forEach((el) => {
            this.total +=
              parseFloat(el.products.price.nrc) * parseFloat(el.quantity ?? 1);
            this.totalMonths +=
              parseFloat(el.products.price.mrc) * parseFloat(el.quantity ?? 1);
          });
        }
      }
    },
    getClientPo() {
      return this.form.client_po && this.form.client_po != ""
        ? this.form.client_po
        : "-";
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.buildPage();
  },
  beforeUpdate() {
    this.$IsLoggedIn();
    this.buildPage();
  },
};
</script>
