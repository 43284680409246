<template>
  <div class="">
    <div class="contentForm">
      <h2 class="title">{{ $t("remoteHands.titleStep3") }}</h2>
      <p class="subTitle">{{ $t("remoteHands.subTitleStep3") }}</p>

      <div class="space"></div>

      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("remoteHands.infoOperationRh") }}</span>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.labelDatacenter")
            }}</span>
            <span class="valueConfirm">{{ getDatacenter() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <p>
            <span class="labelConfirm">{{ $t("remoteHands.labelRoom") }}</span>
            <span class="valueConfirm">{{ getRoom() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <p>
            <span class="labelConfirm">{{ $t("remoteHands.labelRack") }}</span>
            <span class="valueConfirm">{{ getRack() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col" v-if="false">
          <p>
            <span class="labelConfirm">{{ $t("remoteHands.activity") }}</span>
            <span class="valueConfirm">{{ getActivity() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col" v-if="false">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.typeOperation")
            }}</span>
            <span class="valueConfirm">{{ getOperation() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.request.timeWindow")
            }}</span>
            <span class="valueConfirm">{{ getTimeWindow() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.request.activityStart")
            }}</span>
            <span class="valueConfirm">{{ getStartActivity() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.summary")
            }}</span>
            <span class="valueConfirm">{{ getSummary() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.description")
            }}</span>
            <span class="valueConfirm">{{ getDescription() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{ $t("remoteHands.order") }}</span>
            <span class="valueConfirm">{{ getPo() }}</span>
          </p>
        </div>
      </div>

      <!--      &lt;!&ndash; Insert Read-only Fields Here &ndash;&gt;-->
      <!--      <hr />-->
      <!--      <div class="mdl-grid padding0">-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Tipo di richiesta</span>-->
      <!--          <span class="valueConfirm">{{ fields.tipo_di_richiesta }}</span>-->
      <!--        </div>-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Livello tecnico</span>-->
      <!--          <span class="valueConfirm">{{ fields.livello_tecnico }}</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="mdl-grid padding0">-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Urgenza</span>-->
      <!--          <span class="valueConfirm">{{ fields.urgenza }}</span>-->
      <!--        </div>-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Finestra temporale</span>-->
      <!--          <span class="valueConfirm">{{ fields.finestra_temporale }}</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="mdl-grid padding0">-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Inizio attività</span>-->
      <!--          <span class="valueConfirm">{{ formatDateTime(fields.inizio_attivita) }}</span>-->
      <!--        </div>-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Purchase Order</span>-->
      <!--          <span class="valueConfirm">{{ fields.purchase_order }}</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="mdl-grid padding0">-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;12-col">-->
      <!--          <span class="labelConfirm">Sommario</span>-->
      <!--          <span class="valueConfirm">{{ fields.sommario }}</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- Existing Form Fields Continued -->
      <hr />
      <div class="mdl-grid" v-if="false">
        <div class="mdl-cell mdl-cell--12-col" style="margin-left: 0">
          <p class="labelTwo" style="margin-bottom: 0">
            {{ $t("shipping.uploadAttach") }}
          </p>
        </div>
        <div
          v-for="(attach, keyattach) in getAttachs()"
          :key="keyattach"
          class="mdl-cell mdl-cell--12-col"
        >
          <a
            href=""
            :download="attach.name"
            @click="downloadFile(attach.file, keyattach)"
            :id="['file-' + keyattach]"
            class="linkA labelConfirm"
            style="margin-left: 0"
            >{{ attach.name }}</a
          ><br />
          <small class="sizeFile">{{ getSize(attach.file.size) }}Mb</small>
        </div>
      </div>
      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{
            $t("checkCoverage.titleReferences")
          }}</span>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{ $t("refTicket.nameRef") }}</span>
            <span class="valueConfirm">{{ getNameRef() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <p>
            <span class="labelConfirm">{{ $t("refTicket.phoneRef") }}</span>
            <span class="valueConfirm">{{ getPhoneRef() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <p>
            <span class="labelConfirm">{{ $t("refTicket.emailRef") }}</span>
            <span class="valueConfirm">{{ getEmailRef() }}</span>
          </p>
        </div>
      </div>

      <hr />

      <div class="mdl-grid padding0" v-if="false">
        <div class="mdl-cell mdl-cell--12-col">
          <div class="contentConditions">
            <p class="centered-content">
              <!-- Add a new class for centering -->
              <input
                type="checkbox"
                @change="check()"
                class="check"
                style="width: 20px; height: 20px"
              />
              &nbsp;
              <span class="textConditions">{{
                $t("remoteHands.conditions")
              }}</span>
            </p>
            <!-- <p
              class="error"
              v-if="this.v$.conditions.$invalid && this.v$.conditions.$dirty"
            >
              {{ $t("checkCoverage.isRequired") }}
            </p> -->
          </div>
        </div>
      </div>

      <div v-if="btnSubmit">
        <div
          class="cdlContentButtonDouble"
          style="justify-content: space-around" 
          v-if="false"
        >
          <div class="cdlButtonSingle green width170" @click="goBack()">
            {{ $t("remoteHands.edit") }}
          </div>
          <div
            class="cdlButtonSingle green width170"
            id="nextStep"
            @click="openTicket()"
          >
            {{ $t("remoteHands.submit") }}
          </div>
        </div>
        <div class="mdl-cell mdl-cell--12-col" style="margin-left: 0">
          <p class="labelTwo" style="margin-bottom: 0">
            {{ $t("shipping.uploadAttach") }}
          </p>
        </div>
        <div class="content-hub" :style="{ opacity: opacityForm }">
          <div :id="formId" v-once></div>
        </div>
        <p class="deleteOrder" @click="cancelOrder()" v-if="!fromAccessDc">
          {{ $t("remoteHands.deleteTicket") }}
        </p>
      </div>
    </div>
    <MyLoading :viewLoader="this.btnSubmit" type="" />
  </div>
</template>

<script>
// import { useVuelidate } from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
import MyLoading from "@/components/MyLoading.vue";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "ConfirmRh",
  components: {
    MyLoading,
  },
  // setup() {
  //   return { v$: useVuelidate() };
  // },
  // validations() {
  //   return {
  //     conditions: { required },
  //   };
  // },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    wizardData: {
      type: Object,
      required: true,
    },
    openModalHb: {
      required: true,
    }
  },
  data() {
    return {
      // Existing data properties
      conditions: null,
      btnSubmit: true,
      objectURL: null,
      fromAccessDc: false,
      formSend: false,

      // Use the fields passed from the previous step
      fields: {
        tipo_di_richiesta: this.wizardData.tipo_di_richiesta || "",
        livello_tecnico: this.wizardData.livello_tecnico || "",
        urgenza: this.wizardData.urgenza || "",
        finestra_temporale: this.wizardData.finestra_temporale || "",
        inizio_attivita: this.wizardData.inizio_attivita || "",
        purchase_order: this.wizardData.purchase_order || "",
        sommario: this.wizardData.sommario || "",
      },
      formId: 'hubspotForm',
      opacityForm: 0
    };
  },
  methods: {
    // Existing methods
    goBack() {
      document.getElementById(this.formId).getElementsByTagName("iframe")[0].remove();
      this.$emit("goBack");
    },
    cancelTicket() {
      window.scrollTo(0, 0);
      let params = { type: "deleteShippingTicket" };
      this.$emit("openModal", params);
    },
    async openTicket() {
      const result = await this.v$.$validate();
      if (result) {
        this.btnSubmit = false;
        this.v$.$reset();
        this.$emit("openTicket");
      }
      return;
    },
    getDatacenter() {
      return this.wizardData.datacenter.name;
    },
    getRoom() {
      return this.wizardData.cage.name;
    },
    getRack() {
      return this.wizardData.rack.name;
    },
    getActivity() {
      return this.wizardData.activity_type.name;
    },
    getOperation() {
      return this.wizardData.intervention_type;
    },
    getTimeWindow() {
      return this.wizardData.timing != "" ? this.wizardData.timing : "-";
    },
    getStartActivity() {
      return this.wizardData.cf_inizio_attivita;
    },
    getSummary() {
      return this.wizardData.cf_sommario;
    },
    getPo() {
      return this.wizardData.purchase_order ?? "-";
    },
    getDescription() {
      return this.wizardData.description;
    },
    getNameRef() {
      return this.wizardData.ref_name + " " + this.wizardData.ref_surname;
    },
    getPhoneRef() {
      return this.wizardData.ref_phone;
    },
    getEmailRef() {
      return this.wizardData.ref_email;
    },
    check() {
      this.conditions = this.conditions == null ? true : null;
    },
    viwBtnSubmit() {
      this.btnSubmit = !this.btnSubmit;
    },
    cancelOrder() {
      this.$emit("close");
    },
    getAttachs() {
      let attachs = [];
      this.wizardData.attachments.forEach((element) => {
        attachs.push({
          name: element.name,
          file: element,
        });
      });
      return attachs;
    },
    downloadFile(fileInput, key) {
      if (this.objectURL) {
        URL.revokeObjectURL(this.objectURL);
      }
      this.objectURL = URL.createObjectURL(fileInput);
      document.getElementById("file-" + key).href = this.objectURL;
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleString();
    },
    hub() {
      this.opacityForm = 0;
      if( document.getElementById(this.formId) ) {
        if( document.getElementById(this.formId).getElementsByTagName("iframe")[0] ) {
          document.getElementById(this.formId).getElementsByTagName("iframe")[0].remove();
        }

        this.loadForm().then(() => {
          setTimeout(() => {
            let iFrame = document.getElementById(this.formId).getElementsByTagName("iframe")[0];

            let theme = '<link type="text/css" rel="stylesheet" href="../../../css/hubspot.css">';
            iFrame.contentWindow.document.head.innerHTML += theme;    

            let form = iFrame.contentWindow.document.body.getElementsByTagName("form")[0];

            let fieldsHidden = form.getElementsByClassName("form-columns-1");
             
            Object.entries(fieldsHidden).forEach((elm, i) => {
              if( 
                  fieldsHidden[i].getElementsByClassName("hs-form-field")[0].classList.value.search("allegat") == -1 && 
                  fieldsHidden[i].getElementsByClassName("hs-form-field")[0].classList.value.search("conditions") == -1
                ) {
                  fieldsHidden[i].getElementsByClassName("hs-form-field")[0].style.display = "none";
              }
            });

            let fieldsHidden2 = form.getElementsByClassName("form-columns-2");
             
            Object.entries(fieldsHidden2).forEach((elm, i) => {
              fieldsHidden2[i].getElementsByClassName("hs-form-field")[0].style.display = "none";
              fieldsHidden2[i].getElementsByClassName("hs-form-field")[1].style.display = "none";
            });

            let intervalForm = setInterval(() => {
              if( form && this.opacityForm == 0 ) {

                clearInterval(intervalForm);

                const nuovoDiv = document.createElement('input');
                nuovoDiv.type = 'button';
                nuovoDiv.classList = 'hs-button primary large';
                nuovoDiv.value = this.$t("remoteHands.edit");
                nuovoDiv.onclick = () => {
                  this.goBack();
                }
                
                form.getElementsByClassName('actions')[0].prepend(nuovoDiv);
                
                let infoAccount = useUserStore().user.account;

                let numero_azienda = form.querySelector('input[name="TICKET.codice_azienda_alyante"]'); 
                numero_azienda.value = infoAccount.id;
                numero_azienda.dispatchEvent(new Event('input', { bubbles: true }));
                numero_azienda.dispatchEvent(new Event('change', { bubbles: true }));

                let nome_azienda = form.querySelector('input[name="TICKET.azienda_provenienza_ticket"]'); 
                nome_azienda.value = infoAccount.business_name;
                nome_azienda.dispatchEvent(new Event('input', { bubbles: true }));
                nome_azienda.dispatchEvent(new Event('change', { bubbles: true }));

                // let datacenter = form.querySelector('input[name="TICKET.cf_datacenter"]'); 
                // datacenter.value = this.wizardData.datacenter.name;
                // datacenter.dispatchEvent(new Event('input', { bubbles: true }));
                // datacenter.dispatchEvent(new Event('change', { bubbles: true }));

                let checkboxes = form.querySelectorAll('input[type="checkbox"]');
                checkboxes.forEach(checkbox => {
                  if (checkbox.value === this.wizardData.datacenter.name) {
                      checkbox.checked = true; // Imposta il checkbox come selezionato
                      checkbox.dispatchEvent(new Event('input', { bubbles: true }));
                      checkbox.dispatchEvent(new Event('change', { bubbles: true }));
                  }
              });

                let sala = form.querySelector('input[name="TICKET.sala"]'); 
                sala.value = this.wizardData.cage.name;
                sala.dispatchEvent(new Event('input', { bubbles: true }));
                sala.dispatchEvent(new Event('change', { bubbles: true }));

                let rack = form.querySelector('input[name="TICKET.rack"]'); 
                rack.value = this.wizardData.rack.name;
                rack.dispatchEvent(new Event('input', { bubbles: true }));
                rack.dispatchEvent(new Event('change', { bubbles: true }));

                let sommario = form.querySelector('textarea[name="TICKET.sommario"]'); 
                sommario.value = this.wizardData.cf_sommario;
                sommario.dispatchEvent(new Event('input', { bubbles: true }));
                sommario.dispatchEvent(new Event('change', { bubbles: true }));

                let descrizione = form.querySelector('textarea[name="TICKET.content"]'); 
                descrizione.value = this.wizardData.description;
                descrizione.dispatchEvent(new Event('input', { bubbles: true }));
                descrizione.dispatchEvent(new Event('change', { bubbles: true }));

                let email = form.querySelector('input[name="email"]'); 
                email.value = this.wizardData.ref_email;
                email.dispatchEvent(new Event('input', { bubbles: true }));
                email.dispatchEvent(new Event('change', { bubbles: true }));

                let cognome = form.querySelector('input[name="TICKET.cognome_referente"]'); 
                cognome.value = this.wizardData.ref_surname;
                cognome.dispatchEvent(new Event('input', { bubbles: true }));
                cognome.dispatchEvent(new Event('change', { bubbles: true }));

                let nome = form.querySelector('input[name="TICKET.nome_referente"]'); 
                nome.value = this.wizardData.ref_name;
                nome.dispatchEvent(new Event('input', { bubbles: true }));
                nome.dispatchEvent(new Event('change', { bubbles: true }));

                let telefono = form.querySelector('input[name="TICKET.telefono_referente"]'); 
                telefono.value = this.wizardData.ref_phone;
                telefono.dispatchEvent(new Event('input', { bubbles: true }));
                telefono.dispatchEvent(new Event('change', { bubbles: true }));

                let oggetto = form.querySelector('input[name="TICKET.subject"]'); 
                oggetto.value = "CDLAN - Remote Hands - " + this.wizardData.datacenter.name;
                oggetto.dispatchEvent(new Event('input', { bubbles: true }));
                oggetto.dispatchEvent(new Event('change', { bubbles: true }));

                let tipo_attivita = form.querySelector('input[name="TICKET.tipo_attivita"]'); 
                tipo_attivita.value = this.wizardData.activity_type.name;
                tipo_attivita.dispatchEvent(new Event('input', { bubbles: true }));
                tipo_attivita.dispatchEvent(new Event('change', { bubbles: true }));

                let livello_tecnico = "b_basic";
                if( this.wizardData.activity_group.search("A -") > -1 ) {
                  livello_tecnico = "a_advanced";
                }

                let livello = form.querySelector('select[name="TICKET.livello_tecnico"]'); 
                livello.value = livello_tecnico;
                livello.dispatchEvent(new Event('input', { bubbles: true }));
                livello.dispatchEvent(new Event('change', { bubbles: true }));

                let timing = "";
                  if( this.wizardData.timing != "" ) {
                    timing = "BT - orario ufficio";
                    if( this.wizardData.timing.search("EBT -") > -1 ) {
                    timing = "EBT - Fuori orario ufficio";
                  }
                }

                let finestra = form.querySelector('select[name="TICKET.finestra_temporale"]'); 
                finestra.value = timing;
                finestra.dispatchEvent(new Event('input', { bubbles: true }));
                finestra.dispatchEvent(new Event('change', { bubbles: true }));

                let po_number = form.querySelector('input[name="TICKET.purchase_order"]'); 
                po_number.value = this.wizardData.purchase_order?? "-";
                po_number.dispatchEvent(new Event('input', { bubbles: true }));
                po_number.dispatchEvent(new Event('change', { bubbles: true }));

                let date = this.wizardData.cf_inizio_attivita.split(" ");

                let giorno = form.querySelector('input[name="TICKET.inizio_giorno_attivita"]'); 
                giorno.value = date[0];
                giorno.dispatchEvent(new Event('input', { bubbles: true }));
                giorno.dispatchEvent(new Event('change', { bubbles: true }));

                let ora = form.querySelector('input[name="TICKET.inizio_ora_attivita"]'); 
                ora.value = date[1];
                ora.dispatchEvent(new Event('input', { bubbles: true }));
                ora.dispatchEvent(new Event('change', { bubbles: true }));

                let type_intervention = "P - Pianificato";
                    if( this.wizardData.intervention_type.search("U -") > -1 ) {
                      type_intervention = "U - Urgente";
                  }

                let intervento = form.querySelector('select[name="TICKET.intervento"]'); 
                intervento.value = type_intervention;
                intervento.dispatchEvent(new Event('input', { bubbles: true }));
                intervento.dispatchEvent(new Event('change', { bubbles: true }));

                this.opacityForm = 1;

              }

            }, 500);

            setTimeout(() => {
              clearInterval(intervalForm);
            }, 2000);

          }, 500);

        });
      }
    },
    openModal() {
      this.$emit("reset");
      this.$emit("openModalHb");
    },
    loadForm() {
      let formId = this.$MyCrypto(window.VUE_APP_HB_REMOTE_HANDS_FORM_EN, 1);
      if( this.$i18n.locale == 'it' ) {
        formId = this.$MyCrypto(window.VUE_APP_HB_REMOTE_HANDS_FORM_IT, 1);
      }
      return new Promise((resolve) => {
        const script = document.createElement("script");
          script.src="https://js.hsforms.net/forms/v2.js";
          document.body.appendChild(script);
          script.addEventListener("load", () => {
            try {
              if (window.hbspt) {
                window.hbspt.forms.create({
                  region: "eu1",
                  portalId: window.VUE_APP_HB_PORTAL_ID,
                  formId: formId,
                  target: "#" + this.formId,
                  onFormSubmitted: this.openModal
                });
                setTimeout(() => {
                  resolve(true)
                }, 800);
              }
            } catch (error) {
              console.error("ERRORE:", error);
            }
          });
        });
    }
  },
  mounted() {
    this.hub();
  },
  activated() {
    window.scrollTo(0, 0);

    this.btnSubmit = true;
    if (document.getElementsByClassName("check")[0]) {
      document.getElementsByClassName("check")[0].checked = false;
    }
    this.conditions = null;
    this.fromAccessDc = this.wizardData.fromAccessDc;
    this.hub();
  },
};
</script>
<style scoped lang="scss">
.contentConditions {
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Make sure it takes the full height */
}

.centered-content {
  display: flex; /* Make the paragraph a flex container */
  align-items: center; /* Center items vertically */
  margin: 0; /* Remove any default margin */
}
</style>
