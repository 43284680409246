<template>
    <div id="NetWorkCoverageFilter">
        <div :class="[!this.IsMobile ? mdlGrid : '']">
            <div :class="[!this.IsMobile ? [mdlCell, mdlCell6Col] : '']">
                <div class="contentInputFilter">
                    <div class="contentIcon"><span class="material-icons">search</span></div>
                    <div class="contentInput"><input type="text" :placeholder="$t('checkCoverage.filter.placeholderSearch')"
                            v-model="searchString" @keyup="searchByString" /></div>
                    <div v-if="this.IsMobile">
                        <span class="mdl-button mdl-js-button openFilter" @click="openFilterMobile()">
                            <i class="material-icons">tune</i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="mdl-cell mdl-cell--3-col" v-if="!this.IsMobile">
                <div class="contentInputFilter" style="margin-bottom: 0;">
                    <div class="multiselectFilter">{{ $t('checkCoverage.filter.technology') }} <strong><span>
                        {{ (multiSelected.technology.string.length > 10) ? multiSelected.technology.string.slice(0, 11) + '&hellip;' : multiSelected.technology.string }}</span></strong></div>
                    <div class="contentIcon cursorPointer" @click="openMultiselect('technology')">
                        <span class="material-icons" v-if="multiSelected.technology.onOff">expand_less</span>
                        <span class="material-icons closeMultiselectFilter"
                            v-if="!multiSelected.technology.onOff">expand_more</span>
                    </div>
                </div>
                <div class="listMultiselect" v-if="multiSelected.technology.onOff">
                    <div><input type="checkbox" class="technologyAll" value="-1"
                            @click="searchByParams('technology', 'Tutto', $event)" />
                        {{ $t('checkCoverage.filter.all') }}</div>
                    <div v-for="tech, key in this.technology" :key="key"><input type="checkbox" class="technology"
                            :value="tech" @click="searchByParams('technology', tech, $event)" /> {{ tech }}</div>
                </div>
            </div>
            <div class="mdl-cell mdl-cell--3-col" v-if="!this.IsMobile">
                <div class="contentInputFilter" style="margin-bottom: 0;">
                    <div class="multiselectFilter">{{ $t('checkCoverage.filter.operator') }} <strong><span>
                        {{ (multiSelected.carrier.string.length > 10) ? multiSelected.carrier.string.slice(0, 11) + '&hellip;' : multiSelected.carrier.string  }}</span></strong></div>
                    <div class="contentIcon cursorPointer" @click="openMultiselect('carrier')">
                        <span class="material-icons" v-if="multiSelected.carrier.onOff">expand_less</span>
                        <span class="material-icons closeMultiselectFilter"
                            v-if="!multiSelected.carrier.onOff">expand_more</span>
                    </div>
                </div>
                <div class="listMultiselect" v-if="multiSelected.carrier.onOff">
                    <div><input type="checkbox" class="carrierAll" value="-1"
                            @click="searchByParams('carrier', 'Tutto', $event)" />
                        {{ $t('checkCoverage.filter.all') }}</div>
                    <div v-for="carr, key in this.carrier" :key="key"><input type="checkbox" class="carrier" :value="carr"
                            @click="searchByParams('carrier', carr, $event)" /> {{ carr }}</div>
                </div>
            </div>
            <div class="mdl-cell mdl-cell--2-col" v-if="!this.IsMobile && false">
                <select>
                    <option>{{ $t('checkCoverage.filter.speed') }}: {{ $t('checkCoverage.filter.all') }}</option>
                </select>
            </div>
        </div>
    </div>
    <div id="filterMobile" v-if="this.IsMobile && this.viewFilterMobile">
        <div class="contentFilterMobile">
            <div class="closeFilter" @click="openFilterMobile()">{{ $t('checkCoverage.filter.remove') }}</div>
            <div class="title">{{ $t('checkCoverage.filter.title') }}</div>
            <hr />
            <div class="contentCheckFilter">
                <h4>{{ $t('checkCoverage.filter.technology') }}</h4>
                <div class="listMultiselect">
                    <div class="contentListFilter">
                        <div class="label">
                            <p>{{ $t('checkCoverage.filter.all') }}</p>
                            <!-- <small>###</small> -->
                        </div>
                        <div class="value">
                            <input type="checkbox" class="technologyAll" value="-1"
                                @click="searchByParams('technology', 'Tutto', $event)" />
                        </div>
                    </div>
                    <div class="contentListFilter" v-for="tech, key in this.technology" :key="key">
                        <div class="label">
                            <p>{{ tech }}</p>
                        </div>
                        <div class="value">
                            <input type="checkbox" class="technology checkmobile" :value="tech"
                                @click="searchByParams('technology', tech, $event)" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="contentCheckFilter">
                <hr />
            </div>
            <div class="contentCheckFilter">
                <h4>{{ $t('checkCoverage.filter.operator') }}</h4>
                <div class="listMultiselect">
                    <div class="contentListFilter">
                        <div class="label">
                            <p>{{ $t('checkCoverage.filter.all') }}</p>
                        </div>
                        <div class="value">
                            <input type="checkbox" class="carrierAll" value="-1"
                                @click="searchByParams('carrier', 'Tutto', $event)" />
                        </div>
                    </div>
                    <div class="contentListFilter" v-for="carr, key in this.carrier" :key="key">
                        <div class="label">
                            <p>{{ carr }}</p>
                        </div>
                        <div class="value">
                            <input type="checkbox" class="carrier checkmobile" :value="carr"
                                @click="searchByParams('carrier', carr, $event)" />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="contentFooter">
                <div class="closeFilter" @click="resetFilter()">{{ $t('checkCoverage.filter.reset') }}</div>
                <div class="cdlButtonSingle" @click="openFilterMobile()">{{ $t('checkCoverage.filter.submit') }}</div>
            </div>
        </div>
    </div>
</template>
  
<script>
import "@/assets/scss/NetWorkCoverageFilter.scss"
import "@/assets/scss/NetWorkCoverageFilterMobile.scss"

export default {
    name: 'MyNetWorkCoverageFilter',
    props: ['technology', 'carrier'],
    data() {
        return {
            searchString: "",
            multiSelected: {
                technology: {
                    onOff: false,
                    string: this.$t('tickets.all'),
                    values: []
                },
                carrier: {
                    onOff: false,
                    string: this.$t('tickets.all'),
                    values: []
                }
            },
            IsMobile: this.$IsMobile(),
            viewFilterMobile: false,
            mdlGrid: "mdl-grid",
            mdlCell: "mdl-cell",
            mdlCell6Col: "mdl-cell--6-col"
        }
    },
    methods: {
        searchByString() {
            this.multiSelected.technology.onOff = false;
            this.multiSelected.technology.string = this.$t('tickets.all');
            this.multiSelected.technology.values = [];

            this.multiSelected.carrier.onOff = false;
            this.multiSelected.carrier.string = this.$t('tickets.all');
            this.multiSelected.carrier.values = [];

            this.$emit('search', {
                search: this.searchString,
            });
        },
        searchByParams(type, params, ev) {

            this.searchString = "";

            let pointer = "";
            switch (type) {
                case 'technology':
                    pointer = this.multiSelected.technology;
                    break;
                case 'carrier':
                    pointer = this.multiSelected.carrier;
                    break;
            }

            (function (x, type, params, ev, labelAll) {
                if (params == "Tutto") {
                    x.values = [];
                    x.string = labelAll;
                    let checkBox = document.getElementsByClassName(type);
                    for (let i = 0; i < checkBox.length; i++) {
                        checkBox[i].checked = false;
                    }
                }
                else {
                    let classAll = type + "All";
                    document.getElementsByClassName(classAll)[0].checked = false;
                    if (ev.target.checked) {
                        x.values.push(params);
                    }
                    else {
                        let index = x.values.indexOf(params);
                        if (index !== -1) {
                            x.values.splice(index, 1);
                        }
                    }
                    x.string = x.values.toString();
                }
            })(pointer, type, params, ev, this.$t('tickets.all'));

            if (this.multiSelected.technology.values.length == 0) {
                this.multiSelected.technology.string = this.$t('tickets.all');
                let checkBox = document.getElementsByClassName('technology');
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].checked = false;
                }
                if (type == 'technology') {
                    document.getElementsByClassName('technologyAll')[0].checked = true;
                }
            }

            if (this.multiSelected.carrier.values.length == 0) {
                this.multiSelected.carrier.string = this.$t('tickets.all');
                let checkBox = document.getElementsByClassName('carrier');
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].checked = false;
                }
                if (type == 'carrier') {
                    document.getElementsByClassName('carrierAll')[0].checked = true;
                }
            }

            this.$emit('searchMultiselect', {
                technology: this.multiSelected.technology.values,
                carrier: this.multiSelected.carrier.values,
                total: parseInt(this.multiSelected.technology.values.length) + parseInt(this.multiSelected.carrier.values.length)
            });

        },
        openMultiselect(type) {
            let ctrl = false;
            let selected = [];
            let pointer = "";
            switch (type) {
                case 'technology':
                    pointer = this.multiSelected.technology;
                    break;
                case 'carrier':
                    pointer = this.multiSelected.carrier;
                    break;
            }

            (function (x) {
                x.onOff = !x.onOff;
                ctrl = x.onOff;
                selected = x.values;
            })(pointer);

            if (ctrl && selected.length > 0) {
                let checkBox = document.getElementsByClassName(type);
                setTimeout(() => {
                    for (let i = 0; i < checkBox.length; i++) {
                        checkBox[i].checked = false;
                        selected.forEach(elm => {
                            if (checkBox[i].value == elm) {
                                checkBox[i].checked = true;
                            }
                        });
                    }
                }, 100);
            }
            else if (ctrl && selected.length == 0) {
                let classAll = type + "All";
                setTimeout(() => {
                    if( document.getElementsByClassName(classAll)[0] ) {
                        document.getElementsByClassName(classAll)[0].checked = true;
                    }
                }, 100);
            }
        },
        openFilterMobile() {
            window.scrollTo(0, 0);

            this.openMultiselect('technology');
            this.openMultiselect('carrier');

            this.viewFilterMobile = !this.viewFilterMobile;

            if (this.viewFilterMobile) {
                document.getElementsByTagName("body")[0].style.overflow = 'hidden';
            }
            else {
                document.getElementsByTagName("body")[0].style.overflow = 'auto';
            }

        },
        resetFilter() {
            this.searchString = "";
            this.multiSelected.technology.string = "Tutto";
            this.multiSelected.technology.values = [];
            this.multiSelected.carrier.string = "Tutto";
            this.multiSelected.carrier.values = [];

            let checkBox = document.getElementsByClassName('checkmobile');
            setTimeout(() => {
                for (let i = 0; i < checkBox.length; i++) {
                    checkBox[i].checked = false;
                }
            }, 100);

            document.getElementsByClassName('technologyAll')[0].checked = true;
            document.getElementsByClassName('carrierAll')[0].checked = true;

        },
        closeAllFilter() {
            if (this.multiSelected.technology.onOff) {
                this.multiSelected.technology.onOff = false;
            }
            if (this.multiSelected.carrier.onOff) {
                this.multiSelected.carrier.onOff = false;
            }
        },
        getLangFilter() {
            if( this.multiSelected.technology.string == "Tutto" || this.multiSelected.technology.string == "All" ) {
                this.multiSelected.technology.string = this.$t('tickets.all');
            }
            if( this.multiSelected.carrier.string == "Tutto" || this.multiSelected.carrier.string == "All" ) {
                this.multiSelected.carrier.string = this.$t('tickets.all');
            }
        }
    },
    updated() {
        this.getLangFilter();
    },
    mounted() {

    }
}
</script>