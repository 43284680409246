<template>
  <div id="OrderCrossConnectSummary">
    <div class="contentSummary">
      <div class="padding60">
        <div class="mdl-grid" style="justify-content: space-between">
          <div class="mdl-cell--8-col">
            <MyHeaderPage
              :title="
                readOnly
                  ? $t('breadcrumbs.summaryOrderCrossConnect')
                  : $t('checkCoverageSummary.titleEdit')
              "
              :date="getDateOrder(orderItem.submission_date)"
              :calendar="false"
            />
          </div>
          <!-- Download PDF Button -->
          <div class="mdl-cell--4-col" style="text-align: right">
            <button
              class="cdlButton"
              @click="
                download(orderItem.category + '_' + orderItem.order_item_ref)
              "
            >
              Download PDF
            </button>
          </div>
        </div>
        <SummaryBox>
          <template #itemDescription>
            {{
              orderItem.related_products &&
              orderItem.related_products[0].group_name
            }}
          </template>
          <template #address>
            {{
              orderItem?.category_specific_fields?.cross_connect_details
                ?.datacenter_address
            }}
          </template>
          <template #nrc>{{ orderItem?.total_price?.nrc }}</template>
          <template #mrc>{{ orderItem?.total_price?.mrc }}</template>
          <template #min_duration>{{
            orderItem.starter_subscription_months
          }}</template>
          <template #renewal>{{
            orderItem.regular_subscription_months
          }}</template>
          <template #release_time>{{ orderItem.activation_days }}</template>
          <template #billing_period>
            <span class="detailThree" v-html='orderItem?.billing_period ? $t("billingPeriod." + orderItem.billing_period ) : $t("billingPeriod.3" )'></span>
          </template>
        </SummaryBox>
        {{ orderItem }}
        <!-- MAIN LINE TITLE -->
        <div class="mdl-grid section">
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2>
              {{ $t("crossConnects.step2Heading") }}
            </h2>
          </div>
        </div>
        <!-- / MAIN LINE TITLE -->

        <!-- READONLY SECTION -> LINK TO REDUNDANT LINE -->
        <div
          v-if="
            orderItem?.category_specific_fields?.cross_connect_details.master_id
          "
        >
          <div class="mdl-cell mdl-cell--10-col">
            <span style="font-weight: bold"
              >{{ $t("crossConnects.isMainLineReadOnly") }}
              <a
                style="
                  font-weight: bold;
                  color: #002e5f;
                  cursor: pointer;
                  text-decoration: underline;
                "
                @click="
                  this.$router.push({
                    name: 'CrossConnectOrderSummary' + this.$i18n.locale,
                    params: {
                      item_id:
                        orderItem?.category_specific_fields
                          ?.cross_connect_details?.master_id,
                    },
                  })
                "
                target="_blank"
                >{{ $t("crossConnects.here") }}</a
              ></span
            >
          </div>
        </div>
        <!-- / READONLY SECTION -> LINK TO REDUNDANT LINE -->

        <!-- MAIN LINE A-B DEVICE -->
        <div
          id="aend-bend-main"
          class="mdl-grid aend-bend-container html2pdf__page-break"
          v-if="
            orderItem &&
            orderItem?.category_specific_fields?.cross_connect_details &&
            !orderItem?.category_specific_fields?.cross_connect_details
              ?.master_id
          "
          style="align-content: flex-start"
        >
          <AbDevice
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'A - END'"
            :room="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .room
            "
            :rack="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .rack_name
            "
            :device-port="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .device_port
            "
            :patchPanel="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .patch_panel_to_mmr
            "
          ></AbDevice>
          <AbDevice
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'B - END'"
            :room="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .room
            "
            :rack="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .rack_name
            "
            :device-port="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .device_port
            "
            :patchPanel="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .patch_panel_to_mmr
            "
            :attachment="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .loa
            "
            :attachmentName="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .loa_name
            "
            :isle="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .islet
            "
            :building="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .building_name
            "
            :wing="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .wing_name
            "
            :is-loa-provided="
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .rack_id &&
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .loa &&
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .building
            "
          ></AbDevice>
        </div>
        <!-- / MAIN LINE A-B DEVICE -->

        <!-- RIDONDANZA -->
        <!-- TITLE  -->
        <div>
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2>
              {{ $t("crossConnects.ridondanza") }}
            </h2>
          </div>
        </div>
        <!-- / TITLE -->

        <!-- LINK TO MAIN LINE PAGE -->
        <div
          v-if="
            orderItem?.category_specific_fields?.cross_connect_details
              ?.is_redundant &&
            !orderItem?.category_specific_fields?.cross_connect_details
              ?.master_id
          "
          class="mdl-grid info-container"
        >
          <div class="mdl-cell mdl-cell--10-col">
            <span style="font-weight: bold"
              >{{ $t("crossConnects.Click") }}
              <a
                style="
                  font-weight: bold;
                  color: #002e5f;
                  cursor: pointer;
                  text-decoration: underline;
                "
                @click="
                  this.$router.push({
                    name: 'CrossConnectOrderSummary' + this.$i18n.locale,
                    params: {
                      item_id:
                        orderItem?.category_specific_fields
                          ?.cross_connect_details?.redundancy_ids[0],
                    },
                  })
                "
                target="_blank"
                >{{ $t("crossConnects.here") }}</a
              >
              {{ $t("crossConnects.isRedundancyMainLineReadOnly") }}</span
            >
          </div>
        </div>
        <!-- / LINK TO MAIN LINE PAGE -->

        <!-- REDUNDANCY NOT ENABLED -->
        <div
          class="mdl-grid"
          v-if="
            !orderItem?.category_specific_fields?.cross_connect_details
              ?.is_redundant
          "
        >
          <div
            class="mdl-cell mdl-cell--10-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
          >
            <span style="font-weight: bold">{{
              $t("crossConnects.redundancyNotEnabled")
            }}</span>
          </div>
        </div>
        <!-- / REDUNDANCY NOT ENABLED -->

        <div
          id="aend-bend-redundancy"
          class="mdl-grid aend-bend-container html2pdf__page-break"
          v-if="
            orderItem?.category_specific_fields?.cross_connect_details
              ?.master_id
          "
          style="align-content: flex-start"
        >
          <AbDevice
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'A - END'"
            :room="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .room
            "
            :rack="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .rack_name
            "
            :device-port="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .device_and_port
            "
            :patchPanel="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .patch_panel_to_mmr
            "
            :attachment="''"
          ></AbDevice>
          <AbDevice
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'B - END'"
            :room="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .room
            "
            :rack="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .rack_name
            "
            :device-port="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .device_and_port
            "
            :patchPanel="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .patch_panel_to_mmr
            "
            :attachment="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .loa
            "
            :attachmentName="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .loa_name
            "
            :isle="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .islet
            "
            :building="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .building_name
            "
            :wing="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .wing_name
            "
            :is-loa-provided="
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .rack_id &&
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .loa &&
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .building
            "
          ></AbDevice>
        </div>

        <div class="mdl-grid" id="additional-info-section">
          <div
            class="'mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2>
              {{ $t("crossConnects.additionalInfo") }}
            </h2>
          </div>
        </div>
        <div>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">{{
                $t("crossConnects.orderDetails")
              }}</span>
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <div class="info-item">
                <span class="title"
                  >{{ $t("crossConnects.purchaseOrder") }}
                  <span class="value">
                    {{ orderItem?.client_po }}
                  </span></span
                >
              </div>
            </div>
            <div class="mdl-cell mdl-cell--8-col">
              <div class="info-item">
                <span class="title"
                  >Note
                  <span class="value" style="font-family: OpenSansSemiBold">
                    {{ orderItem?.notes }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <br />
          <div
            class="mdl-grid"
            v-if="
              orderItem?.category_specific_fields?.cross_connect_details
                ?.contact
            "
          >
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">
                {{ $t("crossConnects.crossConnectReferral") }}</span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("crossConnects.fullName") }}
                <span class="value" style="margin-left: 0"
                  >{{
                    orderItem?.category_specific_fields.cross_connect_details
                      .contact?.first_name
                  }}
                  {{
                    orderItem?.category_specific_fields.cross_connect_details
                      .contact?.last_name
                  }}</span
                ></span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("refTicket.phoneRef") }}
                <span class="value" style="margin-left: 0">{{
                  orderItem?.category_specific_fields.cross_connect_details
                    .contact?.phone
                }}</span></span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("refTicket.emailRef") }}
                <span class="value" style="margin-left: 0">{{
                  orderItem?.category_specific_fields.cross_connect_details
                    .contact.email
                }}</span></span
              >
            </div>
          </div>
        </div>
        <div class="mdl-grid">
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2>{{ $t("orders.additionalRefs") }}</h2>
          </div>
        </div>
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title"
            >{{ $t("orders.orderRef") }}
              <span class="value" style="margin-left: 0">{{
                  orderItem?.order_ref
                }}</span></span
            >
          </div>
        </div>        <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--4-col">
            <span class="title"
            >{{ $t("orders.bundleCode") }}
              <span class="value" style="margin-left: 0">{{
                 orderItem?.order_item_ref
                }}</span></span
            >
        </div>
      </div>

        <div>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">
                {{ $t("crossConnects.step2Heading") }}</span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("orders.orderRowRef") }}
                <span class="value" style="margin-left: 0"
                  >{{
                    orderItem?.category_specific_fields?.cross_connect_details
                      ?.master_order_item_ref
                      ? redundantData?.related_products[0].order_item_r_p_s_ref
                      : orderItem?.related_products?.length>0 ? orderItem?.related_products[0].order_item_r_p_s_ref : "NA"
                  }}
                </span></span
              >
            </div>
          </div>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">
                {{ $t("crossConnects.step3Heading") }}</span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("orders.orderRowRef") }}
                <span class="value" style="margin-left: 0">{{
                  orderItem?.category_specific_fields?.cross_connect_details
                    ?.redundancy_order_item_ref
                    ? orderItem?.category_specific_fields?.cross_connect_details
                        ?.redundancy_order_item_ref
                    : orderItem?.category_specific_fields?.cross_connect_details
                        ?.master_order_item_ref
                    ? orderItem?.order_item_ref
                    : "N/A"
                }}</span></span
              >
            </div>
          </div>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("orders.requestDate") }}
                <span class="value" style="margin-left: 0">{{
                  formatDateToDDMMYYYY(orderItem.submission_date)
                }}</span></span
              >
            </div>
          </div>
        </div>

        <div>
          <div style="text-align: center; margin-top: 60px">
            <router-link :to="'/' + $t('nav.ordersUrl')">
              <button class="cdlButton">
                {{ $t("orders.backToOrders") }}
              </button>
            </router-link>
          </div>
          <MyLoading :viewLoader="this.viewLoader" type="full" />
          <MyError
            :viewError="viewError"
            :message="$t('checkCoverageSummary.errorGeneric')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AbDevice from "@/components/CrossConnect/ABDevice.vue";
import MyLoading from "@/components/MyLoading.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyError from "@/components/MyError.vue";
import SummaryBox from "@/components/CrossConnect/SummaryBox.vue";

import "@/assets/scss/OrderNetworkCoverageSummary.scss";
import moment from "moment/moment";

export default {
  components: {
    MyHeaderPage,
    MyLoading,
    MyError,
    AbDevice,
    SummaryBox,
  },
  data() {
    return {
      dc: {},
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      pdfDownloadUrl: "",
      orderItem: {},
      redundantData: null,
      masterData: null,
      viewLoader: false,
      viewError: false,
      readOnly: true,
    };
  },
  methods: {
    getDateOrder(dateOrder) {
      let nameDay = moment(
        new Date(dateOrder),
        "DD-MM-YYYY",
        this.$i18n.locale,
        true
      ).format("dddd");
      let date = moment(
        new Date(dateOrder),
        "DD-MM-YYYY, hh:mm:ss",
        this.$i18n.locale,
        true
      ).format("LL");
      return nameDay.charAt(0).toUpperCase() + nameDay.slice(1) + " " + date;
    },
    formatDateToDDMMYYYY(dateOrder) {
      return moment(new Date(dateOrder)).format("DD/MM/YYYY");
    },
    getItem(params) {
      console.log(params);
      this.viewLoader = false;
      const self = this;

      this.axios
        .get(
          `${this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1)}sales/v1/order/${
            params.order_id
          }/${params.item_id}`,
          { headers: this.he }
        )
        .then((response) => {
          this.viewLoader = true;
          this.orderItem = response.data;
        })
        .then(() => {
          if (
            this.orderItem?.category_specific_fields.cross_connect_details
              .redundancy_ids &&
            this.orderItem?.category_specific_fields.cross_connect_details
              .redundancy_ids.length
          ) {
            this.getRedundantData(
              this.orderItem?.category_specific_fields.cross_connect_details
                .redundancy_ids[0]
            );
          }
        })
        .then(() => {
          if (
            this.orderItem?.category_specific_fields.cross_connect_details
              .master_id
          ) {
            this.getMasterData(
              this.orderItem?.category_specific_fields.cross_connect_details
                .master_id
            );
          }
        })
        .catch(function (err) {
          console.log(err);
          self.viewLoader = true;
          document.getElementsByTagName("body")[0].classList.remove("overflow");
          return;
        });
    },
    getRedundantData(redundantMasterId) {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            `sales/v1/order/${this.$route.params.order_id}/${redundantMasterId}`,
          { headers: this.he }
        )
        .then((response) => {
          this.viewLoader = true;
          this.redundantData = response.data;
          console.log(this.redundantData);
        })
        .catch(function (err) {
          console.log(err);
          self.viewLoader = true;
          return;
        });
    },
    getMasterData(masterId) {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            `sales/v1/order/${this.$route.params.order_id}/${masterId}`,
          { headers: this.he }
        )
        .then((response) => {
          this.viewLoader = true;
          this.masterData = response.data;
          console.log(this.masterData);
        })
        .catch(function (err) {
          console.log(err);
          self.viewLoader = true;
          return;
        });
    },
    getRelatedService(type, what, typePrice = null) {
      if (what == "name") {
        let quantity = "";
        if (type.quantity) {
          quantity = "(x" + type.quantity + ")";
        }
        return type.products.title + " " + quantity;
      } else {
        if (typePrice == "nrc") {
          return this.$PriceClean(type[0].price.nrc ?? 0);
        }
        return this.$PriceClean(type[0].price.mrc ?? 0);
      }
    },
    goToCart() {
      return this.$router.push({ name: "CartPage" + this.$i18n.locale });
    },
    download(filename) {
      this.viewLoader = false;
      this.axios
        .get(
          `${this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1)}sales/v1/pdf/${
            this.$route.params.order_id
          }/${this.$route.params.item_id}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.$keycloak.idToken,
            },
            responseType: "blob",
          }
        )
        .then((res) => res.data.arrayBuffer())
        .then((response) => {
          this.viewLoader = true;
          const file = new Blob([response], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = filename + ".pdf";
          link.click();
        })
        .catch((err) => {
          this.viewLoader = true;
          console.error(err);
        });
    },
  },
  created() {
    this.$watch(
      () => this.$route.params.item_id,
      () => {
        this.getItem(this.$route.params);
      }
    );
  },
  mounted() {
    this.getItem(this.$route.params);
    this.pdfDownloadUrl =
      this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
      `sales/v1/pdf/${this.$route.params.order_id}/${this.$route.params.item_id}`;
  },
};
</script>
