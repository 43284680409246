<template>
  <div id="MyModal" v-if="viewModal">
    <div
      class="contentModal"
      :class="{
        open: viewModal,
        success:
          props.typeModal == 'confirmOrder' ||
          props.typeModal == 'confirmRemoteHand' ||
          props.typeModal == 'confirmIncident' ||
          props.typeModal == 'confirmShippingDeliveries' ||
          props.typeModal == 'confirmShippingPickUpDeliveries',
      }"
    >
      <span
        class="material-icons closeOrder"
        id="closeModalX"
        @click="closeModal()"
        v-if="
          isTypeModalForButton ||
          props.typeModal == 'confirmRemoteHand' ||
          props.typeModal == 'confirmIncident' ||
          props.typeModal == 'deleteOrderWizardNetwork' ||
          props.typeModal == 'deleteRemoteHandTicket'
        "
        >close</span
      >
      <h3>{{ $t(typeModal + ".title") }}</h3>
      <p class="sub">{{ $t(props.typeModal + ".sub", getValue) }}</p>
      <!--      <p class="text">{{ $t(props.typeModal + ".subStrong") }}</p>-->
      <div class="contentButton widthButtonModal">
        <ModalButton
          v-for="key in filteredModalKeys"
          :key="key"
          :btnClass="modalConfig[key].btnClass"
          :clickHandler="modalConfig[key].clickHandler"
          :labelKey="`${props.typeModal}.${modalConfig[key].labelKey}`"
          :condition="modalConfig[key].condition"
        />
        <br /><br />
        <div v-for="(item, index) in filteredModalParagraphsArray" :key="index">
          <ModalParagraph
            v-if="item.type === 'ModalParagraph'"
            :para-class="item.paraClass"
            :click-handler="item.clickHandler"
            :label-key="`${props.typeModal}.${item.labelKey}`"
            :condition="item.condition"
          />
          <div
            v-if="item.type === 'SpecialDiv'"
            v-html="item.specialContent"
          ></div>
          <div
            style="text-align: center"
            v-if="
              item.type === 'SpecialDiv' && props.typeModal == 'confirmOrder'
            "
          >
            <p>{{ $t("actions.or") }}</p>
            <a
              @click="
                emit('close');
                router.push({ name: 'HomePage' });
              "
              class="secondaryLink"
            >
              {{ $t("nav.homePage") }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import {
  deleteRequest,
  performActionAndClose,
  setLocalStorageItems,
} from "@/services/ModalHelpers";
import ModalButton from "@/components/ModalButton.vue";
import ModalParagraph from "@/components/ModalParagraph.vue";
import { generateModalConfig } from "@/configs/ModalConfig";
import { MyCrypto } from "@/services/MyCrypto";
import { UpdateCart } from "@/services/UpdateCart";

import "@/assets/scss/MyModal.scss";
import { useUserStore } from "@/stores/UserStore";

const locale = useI18n().locale;
const router = useRouter();
const props = defineProps(["viewModal", "valueModal", "typeModal", "tokenKc"]);
const emit = defineEmits(["close"]);
// const updateCart = inject('$UpdateCart');
// const keycloak = inject('$keycloak')

const isTypeModalForButton = computed(() => {
  const types = [
    "removeItemCart",
    // 'deleteOrderWizardNetwork',
    "removeAllItems",
    "deleteOrderWizardNetworkTmp",
    "deleteShippingTicket",
    "deleteShippingPickUpTicket",
    // 'deleteRemoteHandTicket'
  ];

  return types.includes(props.typeModal);
});

const closeModal = () => {
  emit("close");
};
const userStore = useUserStore();

const tokenKc = computed(() => userStore.token);

const headers = {
  Accept: "application/json",
  Authorization: "Bearer " + tokenKc.value,
};

const removeLocalStorageItems = (keys) => {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

const deleteItemCart = async () => {
  const cartId =
    typeof props.valueModal === "object"
      ? props.valueModal.cart_id
      : props.valueModal;
  const url =
    MyCrypto(window.VUE_APP_MISTRA_URL, 1) + `sales/v1/cart-item/${cartId}`;
  if (await deleteRequest(url, headers)) {
    UpdateCart?.(); // Using optional chaining to call updateCart if it exists
    closeModal();
    router.push({ name: `CartPage${locale.value}` });
  }
};

const deleteAllItemsCart = async () => {
  const url = MyCrypto(window.VUE_APP_MISTRA_URL, 1) + `sales/v1/cart`;
  if (await deleteRequest(url, headers)) {
    UpdateCart?.(); // Using optional chaining to call updateCart if it exists
    closeModal();
  }
};

const deleteOrderWizardNetwork = () => {
  removeLocalStorageItems([
    "cdlOrderKit",
    "cdlOrderKitAddress",
    "cdlOrderNetworkCoverage",
    "cdlOrderNetworkCoverageConditions",
  ]);
  performActionAndClose(() => {
    const routeName = `NetworkCoverage${locale.value}`;
    // Check if the routeName is what you expect it to be
    router.push({ name: routeName });
  }, emit);
};

const deleteOrderWizardNetworkTmp = () => {
  setLocalStorageItems([
    ["cdlOrderKitBcK", "cdlOrderKit"],
    ["cdlOrderKitAddressBcK", "cdlOrderKitAddress"],
    ["cdlOrderNetworkCoverageBcK", "cdlOrderNetworkCoverage"],
  ]);
  removeLocalStorageItems([
    "cdlOrderKit",
    "cdlOrderKitAddress",
    "cdlOrderNetworkCoverage",
    "cdlOrderNetworkCoverageConditions",
  ]);
  closeModal();
  performActionAndClose(() => {
    const routeName = `CartPage${locale.value}`;
    // Check if the routeName is what you expect it to be
    router.push({ name: routeName });
  }, emit);
};

const searchNewKit = () => {
  localStorage.setItem(
    "cdlOrderNewKit",
    JSON.stringify(props.valueModal.address)
  );
  deleteItemCart();
  performActionAndClose(() => {
    router.push({ name: "NetworkCoverage" });
  }, emit);
};

const deleteTicket = () => {
  performActionAndClose(() => {
    router.push({ name: "ShippingType" });
  }, emit);
};

const deleteTicketRh = () => {
  performActionAndClose(() => {
    router.push({ name: `RemoteHandsList${locale.value}` });
  }, emit);
};

const newTicketRh = () => {
  performActionAndClose(() => {
    const routeName = `RemoteHands`;
    // Check if the routeName is what you expect it to be
    router.push({ name: routeName });
  }, emit);
};

const ticketListShipping = () => {
  performActionAndClose(() => {
    // Check if the routeName is what you expect it to be
    router.push({ name: `ShippingList${locale.value}` });
  }, emit);
};

const newTicketIncident = () => {
  performActionAndClose(() => {
    router.go(-1)
  }, emit);
};

const goToOrdersPage = () => {
  performActionAndClose(() => {
    // Check if the routeName is what you expect it to be
    router.push({ name: `Orders${locale.value}` });
  }, emit);
};

// New modalConfig
const modalConfig = generateModalConfig(
  props,
  closeModal,
  deleteOrderWizardNetwork,
  searchNewKit,
  ticketListShipping,
  deleteTicketRh,
  isTypeModalForButton,
  goToOrdersPage,
  newTicketIncident
);
const filteredModalKeys = computed(() => {
  // Convert object keys to an array
  const keys = Object.keys(modalConfig);
  // Filter keys based on your conditions
  return keys.filter((key) => modalConfig[key].condition.value);
});
const modalParagraphsArray = ref([
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: deleteItemCart, // You'll bind this to your actual function later
    condition: 'props.typeModal === "removeItemCart"',
    labelKey: "deleteOrder",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: deleteOrderWizardNetwork,
    condition: 'props.typeModal === "deleteOrderWizardNetwork"',
    labelKey: "deleteOrder",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: deleteAllItemsCart,
    condition: 'props.typeModal === "removeAllItems"',
    labelKey: "deleteOrder",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: deleteOrderWizardNetworkTmp,
    condition: 'props.typeModal === "deleteOrderWizardNetworkTmp"',
    labelKey: "deleteOrder",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: goToOrdersPage,
    condition: "false",
    labelKey: "deleteOrder",
  },
  {
    type: "SpecialDiv",
    condition: 'props.typeModal === "confirmOrder"',
    specialContent: "",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: closeModal,
    condition: 'props.typeModal === "KitNotFound"',
    labelKey: "deleteOrder",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: deleteTicket,
    condition:
      'props.typeModal === "deleteShippingTicket" || props.typeModal === "deleteShippingPickUpTicket"',
    labelKey: "deleteTicket",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: deleteTicket,
    condition:
      'props.typeModal === "confirmShippingDeliveries" || props.typeModal === "confirmShippingPickUpDeliveries"',
    labelKey: "newTicket",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: deleteTicketRh,
    condition: 'props.typeModal === "deleteRemoteHandTicket"',
    labelKey: "deleteTicket",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: newTicketRh,
    condition: 'props.typeModal === "confirmRemoteHand"',
    labelKey: "newTicket",
  },
  {
    type: "ModalParagraph",
    paraClass: "deleteOrder",
    clickHandler: newTicketIncident,
    condition: 'props.typeModal === "confirmIncident"',
    labelKey: "newTicket",
  },
]);

const filteredModalParagraphsArray = computed(() => {
  return modalParagraphsArray.value.filter((item) => {
    // Replace with actual condition evaluation
    return eval(item.condition);
  });
});

const getValue = computed(() => {
  return props.valueModal.destination
    ? { destination: props.valueModal.destination }
    : {};
});

// Other lifecycle methods can be used directly within <script setup>
if (props.viewModal) {
  document.getElementsByTagName("body")[0].classList.add("overflow");
} else {
  document.getElementsByTagName("body")[0].classList.remove("overflow");
}
</script>
