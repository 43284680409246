<template>
  <div id="HomePage">
    <div class="mdl-grid contentHomePage">
      <MyBanner
        class="mdl-cell--12-col"
        :text="
          $t('banner.homePage', {
            name: user.first_name,
          })
        "
      />    
      
      <div class="content-hb">
        <h3>{{ $t('homePage.infoHubSpot.title') }}</h3>
        <p v-html="$t('homePage.infoHubSpot.text')"></p>
      </div>

      <div
        class="mdl-cell mdl-cell--9-col mdl-cell--12-col-tablet mdl-cell--12-col-phone first"
      >
        <MyHeaderPage
          :title="$t('homePage.title')"
          :subtitle="$t('homePage.subTitle')"
          :calendar="true"
        />
        <div class="mdl-grid" style="justify-content: space-between">
          <div
            id="dsorders"
            class="singleCard cardOne mdl-cell--8-col mdl-cell--12-col-phone"
          >
            <div class="gridContainer">
              <div class="cardContainer">
                <h4>{{ $t("homePage.cardOne") }}</h4>
                <p class="sub">{{ $t("homePage.subOne") }}</p>
                <div class="orders" v-if="orders.length > 0">
                  <div v-for="(order, key) in getOrders()" :key="key">
                    <div class="mdl-cell--12-col dateContainer">
                      <span v-if="key > 0">
                        <br /><br />
                      </span>
                      <span class="dateOrder">
                        {{ getDateHome(order.submission_order) }}
                      </span>
                    </div>
                    <div class="orderItem">
                      <div class="orderRow mdl-grid" :class="{ last: key == order.length - 1 }">
                        <div class="mdl-cell--5-col rowTitle">
                          <div>
                            <img
                              v-if="order.category === kit.XCONNECT_CATEGORY"
                              src="@/assets/img/icon_kit_one.png"
                              alt="technology"
                              width="30"
                              height="30"
                            />
                            <img
                              v-else
                              :src="getKitStyle(technology)"
                              alt="technology"
                              width="30"
                            />
                          </div>
                          <div>
                            <span class="title"> {{ order.kit_title }}</span>
                          </div>
                        </div>
                        <div class="mdl-cell--4-col">
                          <span
                            class="address"
                            v-show="order.category === kit.ACCESS_CATEGORY"
                          >{{ order.category_specific_fields?.address?.address }} {{ order.category_specific_fields?.address?.number }} {{ order.category_specific_fields?.address?.city }} {{ order.category_specific_fields?.address?.province }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else style="text-align: center">
                  <br />
                  <i>{{ $t("homePage.noSolutions") }}</i>
                </div>
              </div>
            </div>

            <div class="contentButton" v-if="false">
              <div
                class="cdlButton"
                id="buttonNewSolutionHomePage"
                @click="newOrder()"
                style="width: 87%"
                v-if="ctrlPerms(['verifica_copertura'])"
              >
                {{ $t("cart.newSupply") }}
              </div>
            </div>
          </div>
          <div class="mdl-cell--4-col mdl-cell--12-col-phone">
            <div class="mdl-grid mobileStyle" style="padding: 0">
              <div class="mdl-cell--12-col singleCard">
                <div class="mdl-grid contentCrm">
                  <div class="mdl-cell--12-col contentCtaCrm text">
                    {{ $t("homePage.textCrm") }}
                  </div>
                  <div class="contentButton">
                    <div
                      class="cdlButton"
                      id="buttonNewSolutionHomePage"
                      @click="ctrlTicketCrm()"
                      style="width: 87%"
                    >
                      {{ $t("homePage.ctaCrm") }}
                    </div>
                  </div>
                  <!-- <div style="width: 100%; text-align: center">
                    <p>
                      {{ $t("homePage.ctaCrmNoLink") }} {{ crmEmail }}
                      <span
                        class="material-icons copyCrmContact"
                        v-tooltip:top.tooltip="$t('homePage.ctaCrmNoLinkCopy')"
                        @click="copyAddress()"
                        >content_copy</span
                      >
                    </p>
                    <p class="label textGreen" v-if="successCopy">
                      {{ $t("homePage.ctaCrmLinkCopy") }}
                    </p>
                    <input
                      v-on:focus="$event.target.select()"
                      ref="myinput"
                      readonly
                      :value="crmEmail"
                      style="opacity: 0"
                    />
                  </div> -->
                  <!-- <div class="mdl-cell--12-col contentButton" v-if="false">
                    <a
                      :href="
                        'mailto:crm@cdlan.it?subject=' +
                        $t('homePage.ctaCrmSubject')
                      "
                    >
                      <div class="cdlButton">{{ $t("homePage.ctaCrm") }}</div>
                    </a>
                    <div class="cdlButton" @click="ctrlTicketCrm()">{{ $t("homePage.ctaCrm") }}</div>
                  </div> -->
                </div>
              </div>
              <div class="mdl-cell--12-col"><br /></div>
              <div class="mdl-cell--12-col singleCard gradientBg">
                <div class="cardUser gradientBgContent">
                  <h4>{{ $t("homePage.cardTwo") }}</h4>
                  <div class="userInfo">
                    <div
                      class="avatarContainer"
                      :style="{
                        backgroundImage: getImageAvatar(
                          user.preferences ? user.preferences.avatar_link : null
                        ),
                        cursor: 'auto',
                      }"
                    ></div>
                    <div class="infoContainer">
                      <p id="userName" class="userName">
                        {{ user.first_name ?? null }}
                        {{ user.last_name ?? null }}
                      </p>
                    </div>
                  </div>
                  <div class="userInfo">
                    <ul>
                      <li class="label">{{ $t("homePage.labelOne") }}</li>
                      <li class="value">{{ user.phone ?? null }}</li>
                    </ul>
                  </div>
                  <div class="userInfo">
                    <ul>
                      <li class="label">{{ $t("homePage.labelTwoMail") }}</li>
                      <li id="userEmail" class="value">
                        {{ user.email ?? null }}
                      </li>
                    </ul>
                  </div>
                  <div class="userInfo" v-if="user.secondary_email">
                    <ul>
                      <li class="label">{{ $t("homePage.labelTwo") }}</li>
                      <li class="value valueSecondaryPhone">
                        {{ user.secondary_email }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fullCard" v-if="false">
          <h2>{{ $t("homePage.cardThree") }}</h2>
          <p class="sub">{{ $t("homePage.subThree") }}</p>
          <br /><br />
        </div>
      </div>
      <div class="mdl-cell mdl-cell--3-col second">
        <div class="magazine">
          <div class="magazineContainer">
            <h4>
              {{ $t("homePage.cardMagazine") }}
            </h4>
            <p class="sub">{{ $t("homePage.subMagazine") }}</p>
          </div>
          <div v-if="magazines.length === 0" class="noContent">
            <p>
              {{ $t("homePage.noMagazine") }}
            </p>
          </div>
          <div v-else class="magazineContainer">
            <div
              class="contentMagazine"
              v-for="(magazine, indexMagazine) in magazines"
              :key="indexMagazine"
            >
              <div class="contentCategoryMagazine">
                <div class="categoryMagazine">{{ magazine.category }}</div>
                <div class="backCatMagazine"></div>
              </div>
              <div class="contentImg">
                <img
                  :src="magazine.pic"
                  alt="CDLAN SpA Magazine"
                  width="295"
                  height="160"
                />
              </div>
              <div class="textContainer">
                <div class="contentTwoItems">
                  <div>
                    <img
                      src="@/assets/img/calendar.png"
                      alt="CDLAN SpA Magazine"
                      width="21"
                      height="21"
                    />
                  </div>
                  <div>
                    <span>{{ getDateHome(magazine.date) }}</span>
                  </div>
                </div>
                <div class="magazineTitle">
                  <h3>{{ magazine.title }}</h3>
                </div>
                <div class="magazineExcerpt">
                  <p
                    v-html="
                      magazine.abstract.length > 200
                        ? magazine.abstract.slice(0, 200) + '&hellip;'
                        : magazine.abstract
                    "
                  ></p>
                </div>
              </div>
              <!-- /TEXT CONTAINER -->
              <div class="footerMagazine">
                <div class="contentTwoItems">
                  <div>
                    <img
                      src="@/assets/img/user.png"
                      alt="CDLAN SpA Magazine"
                      width="21"
                      height="21"
                    />
                  </div>
                  <div>{{ magazine.author }}</div>
                </div>
                <div class="ctaMagazine">
                  <a :href="magazine.link" target="_blank">{{
                    $t("homePage.readAll")
                  }}</a>
                </div>
              </div>
            </div>
            <div class="goToMagazine">
              <a href="https://magazine.cdlan.it/" target="_blank">
                <div class="cdlButton">
                  {{ $t("homePage.gotoMagazine") }}
                </div>
              </a>
            </div>
          </div>
        </div>
        <router-link to="/fonts" v-if="isDebug">
          <div class="btnFonts">FONTS</div>
        </router-link>
        <p style="float: right"><br /><br />1.1.2</p>
      </div>
    </div>
  </div>
  <ModalCrmRequest
    :viewModal="modalCrmRequestSuccess"
    @cancel="ctrlTicketCrm"
    :title="'homePage.requestCrm.title'"
    :sub="'homePage.requestCrm.sub'"
    :text="'homePage.requestCrm.text'"
    :btn="'homePage.requestCrm.btn'"
    :link="'homePage.requestCrm.link'"
  />

  <MyError :viewError="messageSuccess" :message="$t('homePage.sendSuccess')" />
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

const user = computed(() => {
  return useUserStore().user;
});
</script>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/HomePage.scss";
import moment from "moment";
import kit from "@/const/kit.js";
import ModalCrmRequest from "@/components/ModalCrmRequest.vue";
import MyError from "@/components/MyError.vue";

export default {
  name: "HomePage",
  components: {
    MyBanner,
    MyHeaderPage,
    ModalCrmRequest,
    MyError,
  },
  data() {
    return {
      backgroundImage: `${require("@/assets/img/webp/pic_profile.webp")}`,
      orders: [],
      magazines: [],
      isDebug: window.VUE_APP_DEBUG
        ? this.$MyCrypto(window.VUE_APP_DEBUG, 1) === "true"
          ? true
          : false
        : false,
      modalCrmRequestSuccess: false,
      messageSuccess: false,
      crmEmail: "crm@cdlan.it",
      successCopy: false,
      labelMagazineLeft: '0px'
    };
  },
  methods: {
    newOrder() {
      localStorage.removeItem("cdlOrderKit");
      localStorage.removeItem("cdlOrderKitAddress");
      localStorage.removeItem("cdlOrderNetworkCoverage");
      localStorage.removeItem("cdlOrderNetworkCoverageConditions");
      return this.$router.push({ name: "NetworkCoverage" + this.$i18n.locale });
    },
    editUser() {
      return this.$router.push({ name: "Users" + this.$i18n.locale });
    },
    getImageAvatar(image) {
      return image ? "url(" + image + ")" : "url(" + this.backgroundImage + ")";
    },
    getAllOrders() {
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      const self = this;

      this.axios
        .get(this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "sales/v1/order", {
          headers: he,
        })
        .then((response) => {
          this.orders = response.data;
        })
        .catch(function (err) {
          if (err.response.data.message == "invalid token") {
            return self.$router.push({ name: "MyLogin" });
          }
        });
    },
    getOrders() {
      return this.orders;
    },
    getDateHome(dateOrder) {
      return moment(dateOrder).format("DD/MM/YYYY", this.$i18n.locale, true);
    },
    getTimeOrder(dateOrder) {
      let time = moment(
        new Date(dateOrder),
        "HH:mm:ss",
        this.$i18n.locale,
        true
      ).format("HH:mm");
      return time;
    },
    getMagazines() {
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      const self = this;
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "front/v1/site-rss?limit=1",
          { headers: he }
        )
        .then((response) => {
          this.magazines = response.data;
        })
        .catch(function (err) {
          if (err.response.data.message == "invalid token") {
            return self.$router.push({ name: "MyLogin" });
          }
        });
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
    getKitStyle(technology) {
      return this.$KitStyle(technology, "icon");
    },
    ctrlTicketCrm(sent = false) {
      window.scrollTo(0, 0);
      this.modalCrmRequestSuccess = !this.modalCrmRequestSuccess;
      if (this.modalCrmRequestSuccess) {
        document.getElementsByTagName("body")[0].classList.add("overflow");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("overflow");
      }
      if (sent) {
        this.messageSuccess = true;
        setTimeout(() => {
          this.messageSuccess = false;
        }, 3000);
      }
    },
    copyAddress() {
      this.$refs.myinput.focus();
      this.successCopy = document.execCommand("copy");
      setTimeout(() => {
        this.successCopy = false;
      }, 6000);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getAllOrders();
    this.getMagazines();
    // Se l'active del menù è su più voci
    let listActiveMenu = document.getElementsByClassName("cdlMenu");
    let count = 0;
    Object.entries(listActiveMenu).forEach((elm) => {
      if (
        elm[1].classList.contains("router-link-active") ||
        elm[1].classList.contains("active")
      ) {
        if (count > 0) {
          elm[1].classList.remove("router-link-active");
          elm[1].classList.remove("active");
        }
        count++;
      }
    });
  },
};
</script>
