<template>
  <div class="mdl-cell mdl-cell--2-col">
    <img src="@/assets/img/icon_pin.png" alt="location" width="45" />
  </div>
  <div class="mdl-cell mdl-cell--10-col">
    <div>
      <span class="label"
        >{{ $t("remoteHands.detailLabelLocation") }}
        <span class="text">{{ shippingData?.cf_datacenter }}</span>
      </span>
    </div>
  </div>
  <div class="mdl-cell mdl-cell--12-col">
    <hr />
  </div>
  <div class="mdl-cell mdl-cell--2-col">
    <img src="@/assets/img/icon_info.png" alt="info" width="45" />
  </div>
  <div class="mdl-cell mdl-cell--10-col">
    <div>
      <span class="label"
        >{{ $t("ticketsList.Shipping.cf_data_arrivo_pacco") }}
        <span class="text">{{ shippingData?.cf_data_arrivo_pacco ? getDate(shippingData.cf_data_arrivo_pacco) : "-" }}</span>
      </span>
    </div>
  </div>
  <div class="mdl-cell mdl-cell--12-col">
    <hr />
  </div>
  <div class="mdl-cell mdl-cell--2-col">
    <img src="@/assets/img/icon_carrier.png" alt="carrier" width="45" />
  </div>
  <div class="mdl-cell mdl-cell--10-col">
    <div>
      <span class="label"
        >{{ $t("shipping.carrier") }}
        <span class="text">{{ shippingData?.cf_trasportatore }}</span>
      </span>
    </div>
    <div>
      <span class="label"
        >{{ $t("shipping.shippingCode") }}
        <span class="text">{{ shippingData?.cf_codice_spedizione }}</span></span
      >
    </div>
  </div>
  <div class="mdl-cell mdl-cell--12-col">
    <hr />
  </div>
  <div class="mdl-cell mdl-cell--2-col">
    <img src="@/assets/img/icon_package.png" alt="package" width="45" />
  </div>
  <div class="mdl-cell mdl-cell--10-col">
    <div>
      <span class="label"
        >{{ $t("shipping.packages") }}
        <span class="text">{{ shippingData?.cf_numero_colli }}</span>
      </span>
    </div>
    <div>
      <span class="label"
        >{{ $t("shipping.pallets") }}
        <span class="text">{{ shippingData?.cf_numero_bancali }}</span>
      </span>
    </div>
    <div>
      <span class="label"
        >{{ $t("shipping.weight") }}
        <span class="text">{{ shippingData?.cf_peso }}</span>
      </span>
    </div>
    <div>
      <span class="label"
         >{{ $t('shipping.volume') }}
        <span class="text">{{ shippingData?.cf_volume?? "-" }}</span>
      </span>
    </div>
  </div>
  <div class="mdl-cell mdl-cell--12-col">
    <hr />
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    shippingData: Object,
  },
  methods: {
    getDate(myTime) {
      return moment(myTime).format("DD/MM/YYYY", this.$i18n.locale, true);
    },
  }
};
</script>
